import copyToClipboard from '../../../helpers/copyToClipboard';
import Glide from '@glidejs/glide';

const giftedCompetingList: HTMLDivElement = document.querySelector('.gifted-list');

if (giftedCompetingList) {
    const giftedCompetingCarousels: NodeListOf<Element> = document.querySelectorAll('.gifted-list__carousel-inner');
    const direction: string = document.querySelector('html').getAttribute('dir');

    if (giftedCompetingCarousels.length > 0) {
        giftedCompetingCarousels.forEach((item: HTMLElement) => {
            const glideSettings = {
                type: 'slider',
                perView: 1,
                gap: 0,
                rewind: true,
                bound: true,
                direction: direction || 'ltr',
            };

            const glide = new Glide(item, glideSettings);

            glide.mount();

            glide.on('move.after', () => {
                const bullets: NodeListOf<HTMLDivElement> = item.querySelectorAll('.glide__bullet');
                const currentIndex = glide.index;

                bullets.forEach((bullet, index) => {
                    bullet.classList.remove('glide__bullet--active');

                    bullet.addEventListener('click', (e) => {
                        glide.go(`=${index}`);
                    });
                });

                bullets[currentIndex].classList.add('glide__bullet--active');
            });
        });
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}
