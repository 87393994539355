import copyToClipboard from '../../../helpers/copyToClipboard';

document.addEventListener('DOMContentLoaded', () => {
    const videoDetails = document.querySelector('.video-gallery-details') as HTMLDivElement;
    const videoPlayer = document.querySelectorAll('.video-gallery-details__video-container');

    if(videoDetails) {
        const youtubeVideoCards: NodeListOf<HTMLDivElement> = videoDetails.querySelectorAll('.video-inline-card--youtube');

        youtubeVideoCards.forEach((card: Element) => {
            const playerElement = card.querySelector("#player") as HTMLIFrameElement;

                if (playerElement) {
                    const player = new window['YT'].Player(playerElement, {
                        events: {
                            'onReady': (event) => {
                                event.target.mute();

                                card.addEventListener("mouseenter", () => {
                                    event.target.playVideo();
                                });

                                card.addEventListener("mouseleave", () => {
                                    event.target.pauseVideo();
                                    event.target.seekTo(0);
                                });
                            }
                        }
                    });
                }
        });
    }


    if (videoPlayer) {
        videoPlayer.forEach((card: Element) => {
            const video = card.querySelector('.video-gallery-details__video-player') as HTMLVideoElement;
            const playButton = card.querySelector('.video-gallery-details__video-play') as HTMLImageElement;

            card.addEventListener('click', async () => {
                if (video.paused) {
                    await video.play();
                    playButton.style.opacity = '0';
                    video.controls = true;
                } else {
                    video.pause();
                    playButton.style.opacity = '1';
                    video.controls = false;
                }
            });
        });
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);                 
            });   
        })
    }
});
