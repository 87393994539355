import Glide from '@glidejs/glide';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import copyToClipboard from '../../../helpers/copyToClipboard';
import addToCalendar from '../../../helpers/addToCalendar';

const programDetails = document.querySelector('.program-details') as HTMLDivElement;

if (programDetails) {
    const eventTabs: NodeListOf<HTMLButtonElement> = programDetails.querySelectorAll('.program-details__tab');
    const sections: NodeListOf<HTMLElement> = programDetails.querySelectorAll('.program-details__section');
    const programDetailsCarousels: NodeListOf<HTMLDivElement> = programDetails.querySelectorAll(
        '.program-details__carousel-inner',
    );
    const direction: string = document.querySelector('html').getAttribute('dir');

    let activeTab: number = 0;

    const resetTabs = () => {
        eventTabs.forEach((tab) => tab.classList.remove('program-details__tab--active'));
        sections.forEach((tab) => tab.classList.remove('program-details__section--active'));
    };

    eventTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();

            eventTabs[activeTab].classList.add('program-details__tab--active');
            sections[activeTab].classList.add('program-details__section--active');
        });
    });

    sections.forEach((section) => {
        const accordion = section.firstElementChild as HTMLDetailsElement;
        if (accordion != null) {
            if (accordion.classList.contains('program-details__accordion')) {
                accordion.setAttribute('open', 'true');
            }
        }

        const accordions = section.querySelectorAll('.program-details__accordion') as NodeListOf<HTMLDetailsElement>;
        accordions.forEach((childAccordion) => {
            childAccordion.addEventListener('toggle', () => {
                if (childAccordion.open) {
                    accordions.forEach((otherAccordion) => {
                        if (otherAccordion !== childAccordion) {
                            otherAccordion.removeAttribute('open');
                        }
                    });
                }
            });
        });
    });

    if (programDetailsCarousels.length > 0) {
        programDetailsCarousels.forEach((item: HTMLElement) => {
            const carouselType: string = item.getAttribute('data-carousel');

            let glideSettings;

            if (carouselType === 'events-carousel') {
                glideSettings = {
                    type: 'slider',
                    perView: 2,
                    gap: 24,
                    rewind: true,
                    bound: true,
                    direction: direction || 'ltr',
                    breakpoints: {
                        480: {
                            perView: 1,
                        },
                    },
                };
            } else if (carouselType === 'programs-carousel') {
                glideSettings = {
                    type: 'slider',
                    perView: 4,
                    gap: 24,
                    rewind: true,
                    bound: true,
                    direction: direction || 'ltr',
                    breakpoints: {
                        1200: {
                            perView: 3,
                        },
                        992: {
                            perView: 2,
                        },
                        480: {
                            perView: 1,
                        },
                    },
                };
            }

            const glide = new Glide(item, glideSettings);

            glide.mount();
        });
    }

    const favButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.favouriteButton');
    if (favButtons && favButtons.length > 0) {
        favButtons.forEach((button) => {
            const dataIdAttr: string | null = button.getAttribute('data-id');
            const type: string | null = button.getAttribute('data-type');
            const favouritedIds: number[] = JSON.parse(button.getAttribute('data-ids'));

            if (dataIdAttr !== null && favouritedIds != null) {
                const dataId: number = parseInt(dataIdAttr);

                if (IsFavourited(dataId, favouritedIds)) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }

                button.addEventListener('click', (e) => {
                    console.log('small button clicked - program details');
                    saveOrRemoveFavourite(type, dataId, button);
                });
            }
        });
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    const calButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.calButton');
    if (calButtons && calButtons.length > 0) {
        calButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                addToCalendar(button);
            });
        });
    }
}
