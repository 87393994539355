const peopleComponent = document.querySelector(".people__wrapper") as HTMLDivElement;

if (peopleComponent) {
  const peopleCards: NodeListOf<HTMLDivElement> = peopleComponent.querySelectorAll(".people__image-container");

  peopleCards.forEach(card => {
    card.addEventListener("click", () => {
      card.classList.toggle("people__image-container--active");
    })
  })
}