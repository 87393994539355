import { FetchInfoGalleryPayload, FetchInfoGalleryResponse, InfoCard } from './infographicsGallery.types';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import copyToClipboard from '../../../helpers/copyToClipboard';
import insertPageView from '../../../helpers/InsertPageView';

const infoGallery: HTMLDivElement = document.querySelector('.info-gallery');

if (infoGallery) {
    const infoGrid = infoGallery.querySelector('.info-gallery__grid') as HTMLDivElement;
    const infoFilters = infoGallery.querySelector('.info-gallery__filters') as HTMLDivElement;
    const infoGallerySearchInput = infoGallery.querySelector('.info-gallery__search-input') as HTMLInputElement;
    const nextPageButton = infoGallery.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = infoGallery.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = infoGallery.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = infoGallery.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = infoGallery.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = infoGallery.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = infoGallery.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let infoCards: InfoCard[] = [];
    let keyword: string = '';
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchInfoGallery = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchInfoGalleryPayload = {
            keyword,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchInfoGalleryResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/infoGallery',
                    payload
                );
            } else {
                response = await axios.post<FetchInfoGalleryResponse>('/api/infoGallery', payload);
            }
            infoCards = response.data.items;
            if (infoCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                infoCards,
                fetchInfoGallery
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        infoGrid.innerHTML = '';

        infoCards.forEach((card: InfoCard) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'image-card flex flex--column';

            cardElement.innerHTML = `
                <div class='image-card__tooltip'>${card.title}</div>
                <div class="image-card__image-container flex flex--align-start">
                    <img src='${card.imageURL}' alt='this is an alt' class='image-card__image' />
                    <div class='modal modal--youtube' data-page-id='${card.id}'>
                        <button tabindex="0" class="modal__open">
                            <img tabindex="0" src='/assets/img/img/icons/youtube-play-button.png' alt='play button' class='modal__icon modal__icon--youtube' />
                            <img tabindex="0" src='/assets/img/img/icons/play-button.svg' alt='play button' class='modal__icon modal__icon--purple' />
                            <img tabindex="0" src='/assets/img/img/play-button-blue.png' alt='play button' class='modal__icon modal__icon--blue' />
                            <img tabindex="0" src='/assets/img/img/play-cricle.svg' alt='play button' class='modal__icon modal__icon--table' />
                        </button>
                        <dialog class='modal__dialog modal__dialog-infograph'>
                            <div class='modal__inner'>
                                <div class='modal__top flex flex--justify-between flex--align-center'>
                                    <div class='flex flex--justify-between flex--align-center'>
                                        <button class="button  button--round-small  modal__close  flex flex--justify-center flex--align-center">
                                            <img src="/assets/img/img/icons/close.svg" alt="share icon" class="button__image">
                                        </button>
                                        <div class='modal__title' id='modal-title'>${card.title}</div>
                                    </div>
                                    <div class='modal__right flex flex--wrap  flex--align-center'>
                                        <img src='/assets/img/img/icons/eye--yellow.svg' alt='decorative' class='modal__eye' />
                                        <div class='modal__count'>${card.views}</div>
                                        <div>${card.viewsText !== null ? card.viewsText : ''}</div>
                                        <div class="modal__buttons flex flex--align-center">
                                            <a class="button  button--round-large flex flex--justify-center flex--align-center" href="${
                                                card.imageURL
                                            }" download>
                                                <img src="/assets/img/img/download-icon.svg" alt="download icon" class="button__image">
                                            </a>
                                            <button class="button image-card__button shareButton button--round-large shareButton modal__share  flex flex--justify-center flex--align-center">
                                                <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal__bottom flex flex--column">
                                    <div class="modal__image-container">
                                        <img src='${card.imageURL}' alt="${card.title}" class="modal__image">
                                    </div>
                                    <div class="modal__images flex flex--column flex--align-start">
                                        <span class="modal__images-text">More photos</span>
                                        <div class="modal__images flex flex--align-center otherimages">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
                <div class='image-card__border flex flex--justify-between'>
                    <a class="button button--round-small image-card__download flex flex--justify-center flex--align-center" href="${
                        card.imageURL
                    }" download>
                        <img src="/assets/img/img/icons/download--blue-bg.svg" alt="download icon" class="button__image">
                    </a>
                    <div class='image-card__icons flex flex--align-center flex--justify-center'>
                        <div class="flex">
                            <span class='image-card__count'>${card.views}</span>
                            <img src='/assets/img/img/icons/eye.svg' alt='views icon' class='image-card__eye' />
                        </div>
                        <button class="button  button--round-small image-card__button shareButton image-card__icon  flex flex--justify-center flex--align-center">
                            <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                        </button>
                    </div>
                </div>
            `;

            // loop through and add child images
            const otherimages = cardElement.querySelector('.otherimages') as HTMLDivElement;

            card.otherImageURL?.forEach((otherImageLink) => {
                const childImageElement = document.createElement('div');
                childImageElement.className = 'modal__image-small-container';
                childImageElement.innerHTML = `<img src="${otherImageLink} alt="this is alt" class="modal__image-small">`;
                otherimages.appendChild(childImageElement);
            });

            infoGrid.appendChild(cardElement);
        });

        addCardListeners();
        enableModals();
    };

    const enableModals = () => {
        const modals: NodeListOf<HTMLDivElement> = document.querySelectorAll('.modal');
        const html = document.querySelector('html') as HTMLHtmlElement;

        if (modals.length > 0) {
            modals?.forEach((modalContainer, index) => {
                const modalDialog = modalContainer.querySelector<HTMLDialogElement>('dialog');
                if (!modalDialog) return;

                const modalId = `modal-${index}`;
                const openButtonId = `open-button-${index}`;

                modalDialog.id = modalId;
                const openButton = modalContainer.querySelector<HTMLImageElement>('.modal__open');
                if (openButton) {
                    openButton.id = openButtonId;
                }

                const closeButton = modalDialog.querySelector<HTMLButtonElement>('.modal__close');

                const pageId = parseInt(modalContainer.getAttribute('data-page-id'));
                document.getElementById(openButtonId)?.addEventListener('click', (event) => {
                    modalDialog.showModal();
                    html.style.overflow = 'hidden';
                    if (pageId > 0) {
                        insertPageView(pageId);
                    }
                });

                closeButton?.addEventListener('click', (event) => {
                    modalDialog.close();
                    html.style.overflow = '';
                });

                modalDialog.addEventListener('click', (event) => {
                    if (event.target === modalDialog) {
                        modalDialog.close();
                        html.style.overflow = '';
                    }
                });

                window.addEventListener('keydown', (e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                        html.style.overflow = '';
                    }
                });
            });
        }
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        infoFilters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        infoFilters.classList.remove('disabled');
    };

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchInfoGallery();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchInfoGallery();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchInfoGallery();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchInfoGallery();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchInfoGallery();
    });

    let timeout = null;
    infoGallerySearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = infoGallerySearchInput.value.trim();
            pageNumber = 1;
            fetchInfoGallery();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = latest
        // 2 = oldest
        // 3 = mostViewed

        radio.addEventListener('change', () => {
            const checkedRadio = infoGallery.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchInfoGallery();
        });
    });

    fetchInfoGallery();

    const addCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = infoGrid.querySelectorAll('.image-card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.image-card__button');

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });
        }
    };
}
