import Glide from '@glidejs/glide';

const latestNewsComponent: HTMLDivElement = document.querySelector(".latest-news");

if (latestNewsComponent) {
  const latestNewsCarousels: NodeListOf<Element> = document.querySelectorAll(".latest-news__carousel-inner");
  const direction: string = document.querySelector("html").getAttribute("dir");

  if (latestNewsCarousels.length > 0) {
    const latestNewsCarouselsArray = Array.from(latestNewsCarousels) || []

    latestNewsCarouselsArray.forEach((item: HTMLElement) => {
      const glideSettings = {
        type: 'slider',
        perView: 3,
        gap: 24,
        rewind: true,
        bound: true,
        direction: direction || "ltr",
        breakpoints: {
          1000: {
            perView: 2,
            gap: 16
          }
        }
      };

      const glide = new Glide(item, glideSettings);

      glide.mount()
    })
  }
}