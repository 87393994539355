import copyToClipboard from '../../../helpers/copyToClipboard';

const curriculums: HTMLDivElement = document.querySelector('.curriculums');

if (curriculums) {
    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');

    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}