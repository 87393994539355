import { FetchSuccessStoriesListPayload, FetchSuccessStoriesListResponse, StoryCard } from './successStoriesList.types';
import Glide from '@glidejs/glide';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';

const successStoriesList: HTMLDivElement = document.querySelector('.stories-list');

if (successStoriesList) {
    const storiesGrid = successStoriesList.querySelector('.stories-list__grid') as HTMLDivElement;
    const storiesFilters = successStoriesList.querySelector('.stories-list__search') as HTMLDivElement;
    const storiesListSearchInput = successStoriesList.querySelector('.stories-list__search-input') as HTMLInputElement;
    const dropdownFilters: NodeListOf<HTMLDivElement> = successStoriesList.querySelectorAll('.dropdown-filter');
    const nextPageButton = successStoriesList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = successStoriesList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = successStoriesList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = successStoriesList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = successStoriesList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = successStoriesList.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = successStoriesList.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    const successStoriesCarousel: HTMLElement = document.querySelector('.stories-list__carousel-inner');
    const direction: string = document.querySelector('html').getAttribute('dir');

    if (successStoriesCarousel) {
        const generateBullets = (carousel) => {
            const cards = carousel.querySelectorAll('.stories-list__carousel-card');
            const bulletWrapper = carousel.querySelectorAll('.glide__bullets');
            const imageBulletWrapper = carousel.querySelectorAll('.glide__bullets--images');

            if (cards.length > 1) {
                bulletWrapper.forEach((wrapper) => {
                    cards.forEach(() => {
                        const button = document.createElement('button');
                        button.className = 'glide__bullet';
                        wrapper.appendChild(button);
                    });
                });
            } else {
                const controls = successStoriesCarousel.querySelector('.stories-list__controls');
                controls.classList.add('hidden');
            }

            imageBulletWrapper.forEach((wrapper) => {
                cards.forEach((card, index) => {
                    const button = document.createElement('button');
                    button.className = 'glide__bullet glide__bullet--image';
                    const backgroundImage = card.style.backgroundImage;
                    const img = document.createElement('img');
                    img.src = backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
                    button.appendChild(img);

                    button.addEventListener('click', () => {
                        glide.go(`=${index}`);
                    });

                    wrapper.appendChild(button);
                });
            });
        };

        const glideSettings = {
            type: 'slider',
            perView: 1,
            rewind: true,
            bound: true,
            direction: direction || 'ltr',
        };

        const glide = new Glide(successStoriesCarousel, glideSettings);

        glide.mount();

        generateBullets(successStoriesCarousel);

        glide.on('move.after', () => {
            const carouselCards = successStoriesCarousel.querySelectorAll('.stories-list__carousel-card');
            carouselCards.forEach((card) => {
                const bullets = card.querySelectorAll('.glide__bullet');
                const imageBullets = card.querySelectorAll('.glide__bullet--image');
                const currentIndex = glide.index;
                const imageContainer = card.querySelector('.glide__bullets--images');

                if (direction === 'rtl') {
                    imageContainer.scrollLeft = imageContainer.scrollWidth;
                    imageContainer.scrollLeft -= 103 * currentIndex;
                } else {
                    imageContainer.scrollLeft = 0;
                    imageContainer.scrollLeft += 103 * currentIndex;
                }

                bullets.forEach((bullet, index) => {
                    bullet.classList.toggle('glide__bullet--active', index === currentIndex);
                });

                imageBullets.forEach((bullet, index) => {
                    bullet.classList.toggle('glide__bullet--image--active', index === currentIndex);
                });
            });
        });
    }

    let storiesCards: StoryCard[] = [];
    let keyword: string = '';
    let fields: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchStories = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchSuccessStoriesListPayload = {
            keyword,
            fields,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchSuccessStoriesListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/successStories',
                    payload,
                );
            } else {
                response = await axios.post<FetchSuccessStoriesListResponse>('/api/successStories', payload);
            }
            storiesCards = response.data.items;
            if (storiesCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                storiesCards,
                fetchStories,
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        storiesGrid.innerHTML = '';

        storiesCards.forEach((card: StoryCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'fade-in';

            cardElement.innerHTML = `
            <div class='stories-list__card flex'>
                <img src='${card.media}' alt='image' class='stories-list__card-image' />
                <div class='stories-list__card-right flex flex--column'>
                    <div class='stories-list__card-title'>${card.title}</div>
                    <div class='stories-list__card-details'>${card.content}</div>
                    <div class='stories-list__card-views-container flex flex--align-center flex--justify-end'>
                        <div class='stories-list__card-views'>${card.viewsCount}</div>
                        <img src='/assets/img/img/icons/eye.svg' alt='views-icon' class='stories-list__card-eye' />
                    </div>
                </div>
            </div>
        `;

            storiesGrid.appendChild(cardElement);
        });
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        storiesFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        storiesFilters.classList.remove('disabled');
    };

    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchStories();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchStories();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchStories();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchStories();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchStories();
    });

    let timeout = null;
    storiesListSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = storiesListSearchInput.value.trim();
            pageNumber = 1;
            fetchStories();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = Latest
        // 2 = Oldest

        radio.addEventListener('change', () => {
            const checkedRadio = successStoriesList.querySelector(
                'input[name="sort-filter"]:checked',
            ) as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchStories();
        });
    });

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const fieldsOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    fieldsOptions.push(element.textContent);
                                });
                                fields = fieldsOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchStories();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });

    fetchStories();

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    const showToastModal = (button: HTMLButtonElement) => {
        const toast = document.createElement('div');
        toast.className = 'toast';
        toast.textContent = currentCulture === 'ar' ? 'تم نسخ الرابط!' : 'Link copied to clipboard!';

        const parentDiv = button.closest('div').parentElement.parentElement;
        parentDiv.appendChild(toast);

        const toastWidth = 150; // Same as the min-width in the CSS
        const right = window.scrollX - toastWidth / 2;

        // Set the position
        toast.style.top = '-5px';
        if (currentCulture === 'ar') {
            toast.style.left = `${right}px`;
        } else {
            toast.style.right = `${right}px`;
        }

        // Trigger the show class to animate the toast
        setTimeout(() => {
            toast.classList.add('show');
        }, 100);

        // Remove the toast after 3 seconds
        setTimeout(() => {
            toast.classList.remove('show');
            setTimeout(() => {
                button.closest('div').parentElement.parentElement.removeChild(toast);
            }, 500); // Match this duration to the CSS transition duration for the toast fade out
        }, 3000);
    };

    // Modal
    document.addEventListener('DOMContentLoaded', () => {
        const openButtons = document.querySelectorAll<HTMLButtonElement>('.stories-list__modal-open');
        const closeButtons = document.querySelectorAll<HTMLButtonElement>('.stories-list__modal-close');
        const modal = document.querySelector('.stories-list__modal') as HTMLDialogElement;
        const modalCount = document.querySelector('.stories-list__modal-count') as HTMLDivElement;
        const modalTitle = document.querySelector('.stories-list__modal-title') as HTMLDivElement;
        const modalVideo = document.querySelector('.stories-list__modal-video') as HTMLIFrameElement;

        openButtons.forEach((button) => {
            button.addEventListener('click', () => {
                const cardId = button.getAttribute('data-card-id');
                const card = document.querySelector(`.stories-list__carousel-card[data-card-id='${cardId}']`);
                if (!card) return;

                const cardTitle = card.querySelector('.stories-list__carousel-card-title')?.textContent || '';
                const embeddedVideo = card.querySelector('.stories-list__carousel-card-video')?.textContent || '';
                const embeddedVideoCount = card.querySelector('.stories-list__carousel-card-count')?.textContent || '';

                modalCount.textContent = embeddedVideoCount;
                modalTitle.textContent = cardTitle;
                modalVideo.src = embeddedVideo;
                modal.showModal();

                const shareButtonModal: HTMLButtonElement = document.querySelector('.shareButtonModal');
                if (shareButtonModal) {
                    shareButtonModal.addEventListener('click', (e) => {
                        navigator.clipboard.writeText(window.location.href);
                        showToastModal(shareButtonModal);
                    });
                }
            });
        });

        closeButtons.forEach((button) => {
            button.addEventListener('click', () => {
                modal.close();
                modalVideo.src = '';
            });
        });

        if (modal) {
            modal.addEventListener('click', (event) => {
                if (event.target === modal) {
                    modal.close();
                    modalVideo.src = '';
                }
            });

            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    modal.close();
                    modalVideo.src = '';
                }
            });
        }
    });
}
