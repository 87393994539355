import makeUrls from './calendarURLs';

export default function addToCalendar(button: HTMLButtonElement): void {
    try {
        //const body = document.getElementsByTagName('body')[0];
        console.log('addToCalendar');

        const name = button.getAttribute('data-cal-name')!;
        const details = button.getAttribute('data-cal-description')!;
        const location = button.getAttribute('data-cal-location')!;
        const startsAt = button.getAttribute('data-cal-start')!;
        const endsAt = button.getAttribute('data-cal-end')!;

        const event = {
            name: name,
            details: details,
            location: location,
            startsAt: startsAt,
            endsAt: endsAt,
        };

        const calUrls = makeUrls(event);

        let calendarOptionsContainer = document.querySelector('.calendar-options') as HTMLDivElement;
        if (!calendarOptionsContainer) {
            calendarOptionsContainer = document.createElement('div');
            calendarOptionsContainer.className = 'calendar-options';
            document.body.appendChild(calendarOptionsContainer);
        }

        calendarOptionsContainer.innerHTML = '';

        const createCalendarLink = (service: string, url: string, iconUrl: string) => {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';

            const icon = document.createElement('img');
            icon.src = iconUrl;
            icon.alt = `${service} icon`;

            link.appendChild(icon);
            link.appendChild(document.createTextNode(`${service}`));
            calendarOptionsContainer.appendChild(link);
        };

        createCalendarLink(
            'Apple',
            calUrls.ics,
            'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg'
        );
        createCalendarLink(
            'Google',
            calUrls.google,
            'https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg'
        );
        createCalendarLink(
            'iCal File',
            calUrls.ics,
            'https://upload.wikimedia.org/wikipedia/commons/f/fa/Line-style-icons-calendar-black.svg'
        );
        createCalendarLink(
            'Outlook.com',
            calUrls.outlook,
            'https://upload.wikimedia.org/wikipedia/commons/9/90/Outlook.com_icon_%282012-2019%29.svg'
        );
        createCalendarLink(
            'Yahoo',
            calUrls.yahoo,
            'https://upload.wikimedia.org/wikipedia/commons/8/83/Yahoo%21_icon_%282009-2013%29.svg'
        );

        const buttonRect = button.getBoundingClientRect();
        const top = window.scrollY + buttonRect.top + buttonRect.height + 10;
        const left = window.scrollX + buttonRect.left + buttonRect.width / 2 - 150;

        calendarOptionsContainer.style.top = `${top}px`;
        calendarOptionsContainer.style.left = `${left}px`;

        calendarOptionsContainer.classList.add('show');

        // Event listener to close the popup when clicking outside
        const closePopup = (event: MouseEvent) => {
            if (
                !calendarOptionsContainer.contains(event.target as Node) &&
                !button.contains(event.target as Node) &&
                event.target !== button
            ) {
                console.log('closing popup');
                calendarOptionsContainer.classList.remove('show');
                document.removeEventListener('click', closePopup);
            }
        };

        document.addEventListener('click', closePopup);
    } catch (err) {
        console.error('Failed to add to calendar: ', err);
    }
}
