const jobCards: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(".job-card");

if (jobCards.length > 0) {
  const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".job-card__button");

  jobCards.forEach(card => {
    card.addEventListener("click", e => {
      const target = e.target as HTMLElement;

      if (target.classList.contains("button") || target.classList.contains("button__image")) {
        e.preventDefault()
      }
    });
  });

  cardButtons.forEach(button => {
    button.addEventListener("click", e => {
      console.log("small button clicked")
    })
  })
}