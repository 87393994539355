import copyToClipboard from '../../../helpers/copyToClipboard';

const methodology: HTMLDivElement = document.querySelector('.methodology');

if (methodology) {
  const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");

  if (shareButtons && shareButtons.length > 0) {
    shareButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        copyToClipboard(button);
      });
    })
  }
}