import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import copyToClipboard from '../../../helpers/copyToClipboard';
import addToCalendar from '../../../helpers/addToCalendar';

const enrichKnowledge = document.querySelector('.enrich-knowledge') as HTMLDivElement;

if (enrichKnowledge) {
    const enrichTabs: NodeListOf<HTMLButtonElement> = enrichKnowledge.querySelectorAll('.enrich-knowledge__tab');
    const sections: NodeListOf<HTMLElement> = enrichKnowledge.querySelectorAll('.enrich-knowledge__section');

    let activeTab: number = 0;

    const resetTabs = () => {
        enrichTabs.forEach((tab) => tab.classList.remove('enrich-knowledge__tab--active'));
        sections.forEach((tab) => tab.classList.remove('enrich-knowledge__section--active'));
    };

    enrichTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();

            enrichTabs[activeTab].classList.add('enrich-knowledge__tab--active');
            sections[activeTab].classList.add('enrich-knowledge__section--active');
        });
    });

    sections.forEach((section) => {
        const accordion = section.firstElementChild as HTMLDetailsElement;
        if (accordion != null) {
            if (accordion.classList.contains('enrich-knowledge__accordion')) {
                accordion.setAttribute('open', 'true');
            }
        }

        const accordions = section.querySelectorAll('.enrich-knowledge__accordion') as NodeListOf<HTMLDetailsElement>;
        accordions.forEach((childAccordion) => {
            childAccordion.addEventListener('toggle', () => {
                if (childAccordion.open) {
                    accordions.forEach((otherAccordion) => {
                        if (otherAccordion !== childAccordion) {
                            otherAccordion.removeAttribute('open');
                        }
                    });
                }
            });
        });
    });

    const favButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.favouriteButton');
    if (favButtons && favButtons.length > 0) {
        favButtons.forEach((button) => {
            const dataIdAttr: string | null = button.getAttribute('data-id');
            const type: string | null = button.getAttribute('data-type');
            const favouritedIds: number[] = JSON.parse(button.getAttribute('data-ids'));

            if (dataIdAttr !== null && favouritedIds != null) {
                const dataId: number = parseInt(dataIdAttr);

                if (IsFavourited(dataId, favouritedIds)) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }

                button.addEventListener('click', (e) => {
                    console.log('small button clicked - program details');
                    saveOrRemoveFavourite(type, dataId, button);
                });
            }
        });
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    const calButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.calButton');
    if (calButtons && calButtons.length > 0) {
        calButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                addToCalendar(button);
            });
        });
    }
}
