const dateRange: HTMLDivElement = document.querySelector('.date-range');

if (dateRange) {
    const dateRangeFilterTrigger: HTMLDivElement = dateRange.querySelector('.date-range__icon-container');
    const dateRangeInner: HTMLDivElement = dateRange.querySelector('.date-range__inner');
    const dateRangeIcon: HTMLDivElement = dateRange.querySelector('.date-range__icon-container');
    const dateRangeInputs: NodeListOf<HTMLInputElement> = dateRange.querySelectorAll('.date-range__input');

    const handleDateClick = (input: HTMLInputElement) => {
        try {
            (input as HTMLInputElement & { showPicker: () => void }).showPicker();
        } catch (error) {
            console.error((error as Error).message);
        }
    };

    if (dateRangeInner && dateRangeIcon) {
        const handleTrigger = () => {
            dateRangeInner.classList.toggle('date-range__inner--active');
            dateRangeIcon.classList.toggle('date-range__icon-container--active');
        };

        const closeDateRange = () => {
            dateRangeInner.classList.remove('date-range__inner--active');
            dateRangeIcon.classList.remove('date-range__icon-container--active');
        };

        dateRangeInputs.forEach((input) => {
            input.addEventListener('click', () => {
                handleDateClick(input);
            });

            input.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    handleDateClick(input);
                }
            });

            input.addEventListener('input', () => {
                input.classList.add('date-range__input--active');
                input.nextElementSibling.classList.add('date-range__input-overlay--hidden');
            });
        });

        dateRangeFilterTrigger.addEventListener('click', () => {
            handleTrigger();
        });

        dateRangeFilterTrigger.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleTrigger();
            }
        });

        document.addEventListener('click', (event: MouseEvent) => {
            if (event.target instanceof Element) {
                if (dateRange && !dateRange.contains(event.target)) {
                    closeDateRange();
                }
            }
        });

        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeDateRange();
            }
        });
    }
}
