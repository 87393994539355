const hero: HTMLDivElement = document.querySelector(".hero");

if (hero) {
  const slides: NodeListOf<HTMLImageElement> = hero.querySelectorAll(".hero__slide");
  const contents: NodeListOf<HTMLDivElement> = hero.querySelectorAll(".hero__content");
  const activeSlide = hero.querySelector(".hero__slide--active") as HTMLElement;
  const heroLeft = hero.querySelector(".hero__left") as HTMLElement;
  const heroDots: NodeListOf<HTMLSpanElement> = hero.querySelectorAll(".hero__dot");
  let isTransitioning: boolean = false;
  const animationDuration: number = 1000;
  let activeIndex: number = 0;
  const animationTimer: number = +heroLeft.getAttribute("data-timer") || 5000;

  // Swipe/Drag variables
  const minSwipeDistance: number = 30;
  let touchStartX: number = 0;
  let touchEndX: number = 0;
  let isMouseDown: boolean = false;

  const mountSlider = () => {
    const slides = Array.from(document.querySelectorAll(".hero__slide")) as HTMLImageElement[];
    const orders = Array(slides.length)
      .fill(null)
      .map((_, index) => `hero__slide-${index + 1}`);
    const slideCount = slides.length;

    slides.forEach((slide, index) => slide.classList.add(orders[index]));

    let activeIndex: number = 0;

    handleDots()

    const updateOrder = (activeIndex: number) => {
      slides.forEach((slide, index) => {
        const newOrderIndex = (index - activeIndex + slideCount) % slideCount;
        slide.className = orders[newOrderIndex] + " hero__slide";

        if (activeIndex === index) {
          slide.classList.add("hero__slide--active")
        }
      });
    };

    const next = (): number => {
      activeIndex++;
      if (activeIndex === slideCount) activeIndex = 0;

      updateOrder(activeIndex);
      return activeIndex;
    };

    const prev = (): number => {
      activeIndex--;
      if (activeIndex === -1) activeIndex = slideCount - 1;

      updateOrder(activeIndex);
      return activeIndex;
    };

    return { next, prev, updateOrder };
  };

  const handleDots = () => {
    heroDots.forEach((dot, i: number) => {
      dot.classList.remove("hero__dot--active")

      if (i === activeIndex) {
        dot.classList.add("hero__dot--active")
      }
    })
  }

  const handleTouchStart = (event: TouchEvent) => {
    touchStartX = event.changedTouches[0].screenX;
  }

  const handleTouchMove = (event: TouchEvent) => {
    touchEndX = event.changedTouches[0].screenX;
  }

  const handleTouchEnd = () => {
    handleSwipeGesture();
  }

  const handleSwipeGesture = () => {
    const distance = touchEndX - touchStartX;

    if (isTransitioning) {
      return
    }

    if (Math.abs(distance) >= minSwipeDistance) {
      if (distance > 0) {
        activeIndex = next();
        handleDots()
        handleSlides()
      } else {
        activeIndex = prev();
        handleDots()
        handleSlides()
      }
    }

    setTimeout(() => {
      isTransitioning = false;
    }, animationDuration);
  }

  const handleMouseDown = (event: MouseEvent) => {
    touchStartX = event.screenX;
    isMouseDown = true;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (isMouseDown) {
      touchEndX = event.screenX;
    }
  };

  const handleMouseUp = () => {
    if (isMouseDown) {
      handleSwipeGesture();
      isMouseDown = false;
    }
  };

  const handleSlides = () => {
    if (isTransitioning) {
      return
    } else {
      isTransitioning = true;

      updateOrder(activeIndex)

      activeSlide.textContent = (activeIndex + 1).toString();

      contents.forEach(item => item.classList.add("hidden"))
      contents[activeIndex].classList.remove("hidden")

      handleDots()

      setTimeout(() => {
        isTransitioning = false;
      }, animationDuration);
    }
  }

  let interval: NodeJS.Timeout;

  const startInterval = () => {
    interval = setInterval(() => {
      activeIndex = next();
      handleSlides();
    }, animationTimer);
  };

  const resetInterval = () => {
    clearInterval(interval);
    startInterval();
  };

  const handleContentHeight = () => {
    const allContents: NodeListOf<HTMLDivElement> = hero.querySelectorAll(".hero__content");

    let tallestHeight = 0;

    allContents.forEach(content => {
      content.classList.remove("hidden")
      const contentHeight = content.offsetHeight;

      if (contentHeight > tallestHeight) {
        tallestHeight = contentHeight;
      }
    });

    allContents.forEach(content => {
      content.style.height = `${tallestHeight}px`;
      content.classList.add("hidden")
    });

    allContents[activeIndex].classList.remove("hidden");
  };

  if (window.innerWidth < 768) {
    handleContentHeight()
  }

  const { next, prev, updateOrder } = mountSlider();

  heroDots.forEach((dot, index) => {
    const handleEvent = () => {
      activeIndex = index;
      handleSlides();
      updateOrder(activeIndex);
      handleDots();
    };

    dot.addEventListener("click", () => {
      resetInterval();
      handleEvent()
    });
    dot.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        resetInterval();
        handleEvent();
      }
    });
  });

  heroLeft.addEventListener('touchstart', handleTouchStart, false);
  heroLeft.addEventListener('touchmove', handleTouchMove, false);
  heroLeft.addEventListener('touchend', () => {
    handleTouchEnd()
    resetInterval();
  }, false);

  heroLeft.addEventListener('mousedown', (e) => {
    handleMouseDown(e)
    resetInterval();
  });

  heroLeft.addEventListener('mousemove', (e) => {
    handleMouseMove(e)
    resetInterval();
  });

  heroLeft.addEventListener('mouseup', () => {
    handleMouseUp()
    resetInterval();
  });

  setTimeout(() => {
    slides.forEach(slide => {
      slide.classList.remove("no-transition")
    })
  }, 100);

  startInterval();

  contents[activeIndex].classList.remove("hidden")
}