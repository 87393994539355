import {
    FetchArticleCardListPayload,
    FetchArticleCardistResponse,
    ArticleCard,
    ArticleCategory,
} from './articlelistfilters.types';
import formatDateString from '../../../helpers/formatDate';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';

const list: HTMLDivElement = document.querySelector('.article-list-filters__wrapper');
if (list) {
    const grid = list.querySelector('.article-list-filters__grid') as HTMLDivElement;

    const filters = list.querySelector('.article-list-filters__filter-content') as HTMLDivElement;
    const resultsnumber = list.querySelector('.article-list-filters__number') as HTMLSpanElement;
    const filterButton = list.querySelector('.article-list-filters__button') as HTMLButtonElement;
    const filterCount = list.querySelector('.article-list-filters__button-count-content') as HTMLDivElement;

    const listSearchInput = list.querySelector('.article-list__search-input') as HTMLInputElement;
    const articleCategoriesDiv = list.querySelector('.article-list-filters__categories-list') as HTMLDivElement;
    const nextPageButton = list.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = list.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = list.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = list.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = list.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = list.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = list.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const pageId = Number(list.getAttribute('data-id'));

    const checkboxFiltersViews: NodeListOf<HTMLInputElement> = list.querySelectorAll(
        '.article-list-filters__view-checkbox'
    );

    let articleCards: ArticleCard[] = [];
    let articleCardsAll: ArticleCard[] = [];
    let articleCategories: ArticleCategory[] = [];
    let keyword: string = '';
    let categories: string[] = [];
    let views: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchArticleCategories = async () => {
        try {
            const payload: FetchArticleCardListPayload = {
                pageId,
                keyword,
                categories,
                views,
                sortBy,
                pageNumber,
                pageSize,
                currentCulture,
            };

            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchArticleCardistResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/articles/getcategories',
                    payload
                );
            } else {
                response = await axios.post<FetchArticleCardistResponse>('/api/articles/getcategories', payload);
            }

            articleCategories = response.data;
        } catch (error) {
            console.error(error);
        } finally {
            updateCategories();
            updateNumberCards();
        }
    };

    const updateCategories = () => {
        articleCategoriesDiv.innerHTML = '';

        articleCategories.forEach((card: ArticleCategory) => {
            const cardElement = document.createElement('details');

            cardElement.setAttribute('open', '');
            cardElement.className = 'article-list-filters__details';

            cardElement.innerHTML = `
                <summary class="article-list-filters__summary">
                    <div class="article-list-filters__category-title flex flex--align-center">
                        <img src="/assets/img/img/icons/four-squares-filled.svg" alt="${card.title}"
                            class="article-list-filters__category-icon">
                        ${card.title}
                    </div>
                </summary>
                <div class="article-list-filters__sub-filters flex flex--column">
                </div>
            `;

            const subCats = cardElement.querySelector('.article-list-filters__sub-filters') as HTMLDivElement;

            card.subCategories?.forEach((subcard: ArticleCategory) => {
                const subCardElement = document.createElement('summary');

                subCardElement.innerHTML = `
                    <div class="article-list-filters__sub-filters-text flex flex--align-center subcat-card" data-id="${subcard.id}">
                        <input type="checkbox" id="${card.title}-${subcard.title}" value="${subcard.id}"
                            class="article-list-filters__sub-filters-text-checkbox article-list-filters__category-checkbox">
                        <label for="${card.title}-${subcard.title}"
                            class="article-list-filters__sub-filters-text-name flex flex--align-center">
                            <span>
                            ${subcard.title}
                            </span>
                            <span class="article-list-filters__sub-filters-text-number">${subcard.count}</span>
                        </label>
                    </div>
                `;

                subCats.appendChild(subCardElement);
            });

            articleCategoriesDiv.appendChild(cardElement);
        });

        const checkboxFiltersCategories: NodeListOf<HTMLInputElement> = list.querySelectorAll(
            '.article-list-filters__category-checkbox'
        );
        if (checkboxFiltersCategories.length > 0) {
            checkboxFiltersCategories.forEach((checkbox) => {
                checkbox.addEventListener('change', () => {
                    if (categories.includes(checkbox.value)) {
                        categories = categories.filter((cat) => cat !== checkbox.value);
                    } else {
                        categories.push(checkbox.value);
                    }
                    fetchArticles();
                    fetchAllArticles();
                });
            });
        }
    };

    const fetchArticles = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchArticleCardListPayload = {
            pageId,
            keyword,
            categories,
            views,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        const fCount = categories.length + views.length;
        filterCount.innerHTML = fCount.toString();

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchArticleCardistResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/articles',
                    payload
                );
            } else {
                response = await axios.post<FetchArticleCardistResponse>('/api/articles', payload);
            }
            articleCards = response.data.items;
            if (articleCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;

            resultsnumber.innerHTML = response.data.totalCount;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                articleCards,
                fetchArticles
            );
            isLoading = false;
            updateCards();
            updateNumberCards();
            enableInputs();
        }
    };

    const fetchAllArticles = async (page = null) => {
        const payload: FetchArticleCardListPayload = {
            pageId,
            keyword: '',
            categories: [],
            views: [],
            sortBy: 1,
            pageNumber: 1,
            pageSize: -1,
            currentCulture,
        };

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchArticleCardistResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/articles',
                    payload
                );
            } else {
                response = await axios.post<FetchArticleCardistResponse>('/api/articles', payload);
            }
            articleCardsAll = response.data.items;
        } catch (error) {
            console.error(error);
        }
    };

    const updateCards = () => {
        grid.innerHTML = '';

        articleCards.forEach((card: ArticleCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'fade-in';

            cardElement.innerHTML = `
            <div class="article-card flex flex--column">
                    <div class="article-card__top">
                        <div class="article-card__top-content flex flex--column">
                            <img class="article-card__top-content-image" src="${card.image}" alt="${card.title}" />
                            <h3 class="article-card__top-content-title">${card.title}</h3>
                            <span class="article-card__top-content-description">${card.text}</span>
                        </div>
                    </div>
                    <div class="article-card__bottom">
                        <div class="article-card__bottom__content flex flex--row flex--align-center flex--justify-between">
                            <div class="article-card__bottom__content__author flex flex--row flex--align-center">
                                <img class="article-card__bottom__content__author-icon" src="/assets/img/img/icons/user-tick-article-card.svg"/>
                                <p class="article-card__bottom__content__author-content">${card.author}</p>
                            </div>
                            <div class="article-card__bottom__content__article-type flex flex--row flex--align-center">
                                <img class="article-card__bottom__content__article-type-icon" src="/assets/img/img/icons/sort-article-card.svg" />
                                <p class="article-card__bottom__content__article-type-content">${card.category}</p>
                            </div>
                            <div class="article-card__bottom__content__views flex flex--row flex--align-center">
                                <img class="article-card__bottom__content__views-icon" src="/assets/img/img/icons/eye--article-card.svg" />
                                <p class="article-card__bottom__content__views-content">${card.views}</p>
                            </div>
                        </div>
                    </div>
            </div>
        `;

            grid.appendChild(cardElement);
        });
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        filters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        filters.classList.remove('disabled');
    };

    if (checkboxFiltersViews.length > 0) {
        checkboxFiltersViews.forEach((checkbox) => {
            checkbox.addEventListener('change', () => {
                if (views.includes(checkbox.value)) {
                    views = views.filter((cat) => cat !== checkbox.value);
                } else {
                    views.push(checkbox.value);
                }
                fetchArticles();
            });
        });
    }

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchArticles();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchArticles();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchArticles();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchArticles();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchArticles();
    });

    let timeout = null;
    listSearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = listSearchInput.value.trim();
            pageNumber = 1;
            fetchArticles();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = A-Z
        // 2 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = list.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchArticles();
        });
    });

    filterButton?.addEventListener('click', () => {
        const filterColumn = list.querySelector('.article-list-filters__accordions') as HTMLDivElement;
        if (filterColumn?.classList.contains('hidden')) {
            filterColumn?.classList.remove('hidden');
        } else {
            filterColumn?.classList.add('hidden');
        }
    });

    const initializeList = async () => {
        await fetchAllArticles();
        await fetchArticleCategories();
        await fetchArticles();
    };

    // Call the initialize function
    initializeList();

    // Function to update the counts
    const updateNumberCards = async () => {
        // Get all filter divs
        const filterDivs = document.querySelectorAll('.views-card');

        filterDivs.forEach((filterDiv) => {
            // Extract data-min and data-max values
            const minViews = parseInt(filterDiv.getAttribute('data-min'), 10);
            const maxViews = parseInt(filterDiv.getAttribute('data-max'), 10);

            // Count the articles within the view range
            const count = articleCardsAll.filter(
                (article) => article.views >= minViews && article.views <= maxViews
            ).length;

            // Update the span with the count
            const countSpan = filterDiv.querySelector('.article-list-filters__sub-filters-text-number');
            countSpan.textContent = count.toString();
        });
    };

    updateNumberCards();
    // const updateNumberCats = () => {
    //     // Get all filter divs
    //     const filterDivs = document.querySelectorAll('.subcat-card');

    //     filterDivs.forEach(filterDiv => {
    //         // Extract data-min and data-max values
    //         const subcardid = filterDiv.getAttribute('data-id');

    //         // Count the articles within the view range
    //         console.log(articleCards);
    //         const count = articleCards.filter(article => article.subCategoryID == subcardid).length;

    //         // Update the span with the count
    //         const countSpan = filterDiv.querySelector('.article-list-filters__sub-filters-text-number');
    //         countSpan.textContent = count.toString();
    //     });
    // }
}
