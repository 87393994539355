import saveOrRemoveFavourite from '../../../helpers/favourites';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { IsFavourited } from '../../../helpers/isFavourite';

const favButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.favouriteButton');

const serviceDetails: HTMLDivElement = document.querySelector('.service-details');

if (serviceDetails) {
    const tabs: NodeListOf<HTMLButtonElement> = serviceDetails.querySelectorAll('.service-details__tab');
    const tabContents: NodeListOf<HTMLDivElement> = serviceDetails.querySelectorAll('.service-details__tab-content');
    const feesButton = serviceDetails.querySelector('.service-details__tooltip') as HTMLButtonElement;
    const feeWaiverButton = serviceDetails.querySelector('.service-details__waiver-tooltip') as HTMLButtonElement;

    let activeTabIndex: number = 0;
    tabContents[activeTabIndex].classList.remove('hidden');
    tabs[activeTabIndex].classList.add('service-details__tab--active');

    const resetTabs = () => {
        tabs.forEach((tab) => tab.classList.remove('service-details__tab--active'));
        tabContents.forEach((item) => item.classList.add('hidden'));
    };

    tabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            resetTabs();
            tab.classList.add('service-details__tab--active');
            activeTabIndex = index;
            tabContents[activeTabIndex].classList.remove('hidden');
        });
    });

    if (favButtons && favButtons.length > 0) {
        favButtons.forEach((button) => {
            const dataIdAttr: string | null = button.getAttribute('data-id');
            const type: string | null = button.getAttribute('data-type');
            const favouritedIds: number[] = JSON.parse(button.getAttribute('data-ids'));
            if (dataIdAttr !== null && favouritedIds != null) {
                const dataId: number = parseInt(dataIdAttr);

                if (IsFavourited(dataId, favouritedIds)) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }

                button.addEventListener('click', (e) => {
                    console.log('small button clicked - service details');
                    saveOrRemoveFavourite(type, dataId, button);
                });
            }
        });
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    const openFeesTooltip = () => {
        feesButton.nextElementSibling.classList.add('service-details__tooltip-inner--active');
    };
    const closeFeesTooltip = () => {
        feesButton.nextElementSibling.classList.remove('service-details__tooltip-inner--active');
    };

    const openWaiverTooltip = () => {
        var tooltipInnerDiv = feeWaiverButton.nextElementSibling as HTMLDivElement;
        tooltipInnerDiv.classList.add('service-details__waiver-tooltip-inner--active');
        const buttonRect = feeWaiverButton.getBoundingClientRect();
        const windowScrollY = window.scrollY === 0 ? -80 : window.scrollY;
        const buttonRectHeight = window.innerWidth < 1200 ? 0 : buttonRect.height;
        const buttonRectTopOrBottom = window.innerWidth < 1200 ? buttonRect.top : buttonRect.bottom;
        const top = buttonRectTopOrBottom + buttonRectHeight + windowScrollY - tooltipInnerDiv.offsetHeight / 2 + 10;
        tooltipInnerDiv.style.top = `${top}px`;
    };

    const closeWaiverTooltip = () => {
        feeWaiverButton.nextElementSibling.classList.remove('service-details__waiver-tooltip-inner--active');
    };

    // if fees tooltip
    window.addEventListener('click', (event: MouseEvent) => {
        let clickedElement = event.target as Element;

        const feesTargetElement = serviceDetails.querySelector('.service-details__tooltip-inner');
        if (clickedElement.classList.contains('service-details__tooltip')) {
            if (clickedElement.nextElementSibling.classList.contains('service-details__tooltip-inner--active')) {
                closeFeesTooltip();
            } else {
                openFeesTooltip();
            }
            return;
        }

        if (feesTargetElement) {
            while (clickedElement) {
                if (clickedElement === feesTargetElement) {
                    feesButton.nextElementSibling.classList.add('service-details__tooltip-inner--active');
                    return;
                }
                clickedElement = clickedElement.parentElement;
            }
            feesButton.nextElementSibling.classList.remove('service-details__tooltip-inner--active');
        }

        return;
    });

    // if waiver tooltip
    window.addEventListener('click', (event: MouseEvent) => {
        let clickedElement = event.target as Element;
 
        const waiverTargetElement = serviceDetails.querySelector('.service-details__waiver-tooltip-inner');
        if (clickedElement.classList.contains('service-details__waiver-tooltip')) {
            if (clickedElement.nextElementSibling.classList.contains('service-details__waiver-tooltip-inner--active')) {
                closeWaiverTooltip();
            } else {
                openWaiverTooltip();
            }
            return;
        }

        if (waiverTargetElement) {
            while (clickedElement) {
                if (clickedElement === waiverTargetElement) {
                    feeWaiverButton.nextElementSibling.classList.add('service-details__waiver-tooltip-inner--active');
                    return;
                }
                clickedElement = clickedElement.parentElement;
            }

            feeWaiverButton.nextElementSibling.classList.remove('service-details__waiver-tooltip-inner--active');
        }

        return;
    });

    window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeFeesTooltip();
            closeWaiverTooltip();
        }
    });
}
