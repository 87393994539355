import {
    TenderCard,
    FetchTendersListPayload,
    FetchTendersListResponse,
    FetchTenderCardSettingsResponse,
} from './tenderList.types';
import formatDateString from '../../../helpers/formatDate';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';

const tenderList = document.querySelector('.tender-list') as HTMLDivElement;

if (tenderList) {
    const tendersItems = tenderList.querySelector('.tender-list__items') as HTMLDivElement;
    const tendersFilters = tenderList.querySelector('.tender-list__filters') as HTMLDivElement;
    const tenderListSearchInput = tenderList.querySelector('.tender-list__search-input') as HTMLInputElement;
    const nextPageButton = tenderList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = tenderList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = tenderList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = tenderList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = tenderList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = tenderList.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = tenderList.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let tenderCards: TenderCard[] = [];
    let keyword: string = '';
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    let tenderCardSettings: FetchTenderCardSettingsResponse = {
        availableText: '',
        expiredText: '',
        offeringDateText: '',
        submissionDeadlineText: '',
        lastDateToReceiveQuestionsText: '',
        responseDateText: '',
        downloadButtonText: '',
        noTendersText: '',
    };

    const fetchEventCardSettings = async () => {
        try {
            const response = await axios.get<FetchTenderCardSettingsResponse>(
                `/api/tenders/cardsettings?currentCulture=${currentCulture}`
            );

            tenderCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTenders = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchTendersListPayload = {
            keyword,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchTendersListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/tenders',
                    payload
                );
            } else {
                response = await axios.post<FetchTendersListResponse>('/api/tenders', payload);
            }
            tenderCards = response.data.items;
            if (tenderCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                tenderCards,
                fetchTenders
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        tendersItems.innerHTML = '';

        if (tenderCards.length === 0) {
            tendersItems.innerHTML = `<div class="flex" style="align-self: center;">${tenderCardSettings.noTendersText}</div>`;
        } else {
            tenderCards.forEach((card: TenderCard) => {
                const cardElement = document.createElement('div');
                cardElement.className = 'tender-list__item flex flex--column flex--align-startn';

                cardElement.innerHTML = `
            <span
                class="tender-list__item-tag ${!card.isAvailable ? 'tender-list__item-tag--red' : ''}">${
                    card.isAvailable ? tenderCardSettings.availableText : tenderCardSettings.expiredText
                }</span>
            <div class="tender-list__top flex">
              <div class="tender-list__image-container flex flex--align-center flex--justify-center">
                <img src="/assets/img/img/pdf-image.svg" alt="pdf image" class="tender-list__image">
              </div>
              <div class="tender-list__content flex flex--column">
                <p class="tender-list__title">${card.title}</p>
                <p class="tender-list__text">${card.description}</p>
              </div>
            </div>
            <div class="tender-list__middle">
              <div class="tender-list__info flex flex--align-center">
                <img src="/assets/img/img/calendar-icon-yellow.svg" alt="calendar icon yellow"
                  class="tender-list__info-icon">
                <span class="tender-list__info-text">${tenderCardSettings.offeringDateText}</span>
                <span class="tender-list__info-text">${formatDateString(card.offeringDate)}</span>
              </div>
              <div class="tender-list__info flex flex--align-center">
                <img src="/assets/img/img/calendar-icon-red.svg" alt="calendar icon red" class="tender-list__info-icon">
                <span class="tender-list__info-text">${tenderCardSettings.submissionDeadlineText}</span>
                <span class="tender-list__info-text">${formatDateString(card.submissionDeadline)}</span>
              </div>
              <div class="tender-list__info flex flex--align-center">
                <img src="/assets/img/img/calendar-icon-grey.svg" alt="calendar icon grey"
                  class="tender-list__info-icon">
                <span class="tender-list__info-text">${tenderCardSettings.lastDateToReceiveQuestionsText}</span>
                <span class="tender-list__info-text">${formatDateString(card.lastDateToReceiveQuestions)}</span>
              </div>
              <div class="tender-list__info flex flex--align-center">
                <img src="/assets/img/img/calendar-icon-grey.svg" alt="calendar icon grey"
                  class="tender-list__info-icon">
                <span class="tender-list__info-text">${tenderCardSettings.responseDateText}</span>
                <span class="tender-list__info-text">${formatDateString(card.responseDate)}</span>
              </div>
            </div>
            <div class="tender-list__bottom flex flex--wrap flex--align-center" data-file="${card.pdfLink}">
              <button
                  class="button button--secondary-long tender-list__item-button flex flex--justify-center flex--align-center">
                  <span class="button__label">${tenderCardSettings.downloadButtonText}</span>
                  <img src="/assets/img/img/arrow-top-left-dark.svg" class="button__image">
              </button>
              <div class="tender-list__buttons flex flex--align-center">
                <a href="${
                    card.pdfLink
                }" draggable="false" download class="button button--round-tiny tender-list__button tender-list__button-download flex flex--justify-center flex--align-center">
                  <img src="/assets/img/img/download-icon-dark.svg" alt="download icon" class="button__image">
                </a>
                <button
                  class="button button--round-tiny shareButton tender-list__button tender-list__button-share flex flex--justify-center flex--align-center">
                  <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                </button>
              </div>
            </div>`;

                tendersItems.appendChild(cardElement);
            });
        }
        const fileButtons = tenderList.querySelectorAll('.tender-list__item-button') as NodeListOf<HTMLButtonElement>;
        fileButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                const fileUrl = button.parentElement.getAttribute('data-file') as string;

                if (fileUrl) {
                    window.open(fileUrl, '_blank');
                }
            });
        });

        const shareButtons: NodeListOf<HTMLButtonElement> = tenderList.querySelectorAll('.shareButton');
        if (shareButtons && shareButtons.length > 0) {
            shareButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    copyToClipboard(button);
                });
            });
        }
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        tendersFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        tendersFilters.classList.remove('disabled');
    };

    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchTenders();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchTenders();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchTenders();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchTenders();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchTenders();
    });

    let timeout = null;
    tenderListSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = tenderListSearchInput.value.trim();
            pageNumber = 1;
            fetchTenders();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = Latest
        // 2 = Oldest
        // 3 = Available
        // 3 = Expired

        radio.addEventListener('change', () => {
            const checkedRadio = tenderList.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchTenders();
        });
    });

    fetchEventCardSettings();
    fetchTenders();
}

const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
if (shareButtons && shareButtons.length > 0) {
    shareButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
            copyToClipboard(button);
        });
    });
}
