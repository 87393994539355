import axios from 'axios';
import { InfoRatingDto } from './serviceDetailsSurvey.types';

const infoComponent = document.querySelector('.details-survey') as HTMLDivElement;

if (infoComponent) {
    const pageId = parseInt(infoComponent.getAttribute('data-pageid'));
    const hasRating = parseInt(infoComponent.getAttribute('data-hasrating'));
    const positiveButton = infoComponent.querySelector('.positive') as HTMLButtonElement;
    const negativeButton = infoComponent.querySelector('.negative') as HTMLButtonElement;
    const ratingSpan = infoComponent.querySelector('.rating') as HTMLSpanElement;
    const currentCulture: string = document.documentElement.getAttribute('culture');

    const SubmitPositiveAPICall = () => {
        CreateRatingCommand(pageId, true);
        positiveButton.classList.remove('button--secondary');
        positiveButton.classList.add('button--primary');
        const img = positiveButton.querySelector('.button__image') as HTMLImageElement;
        img.src = '/assets/img/img/emoji-happy.svg';

        removeEventListeners();
    };

    const SubmitNegativeAPICall = () => {
        CreateRatingCommand(pageId, false);
        negativeButton.classList.remove('button--secondary');
        negativeButton.classList.add('button--primary');
        const img = negativeButton.querySelector('.button__image') as HTMLImageElement;
        img.src = '/assets/img/img/emoji-sad.svg';

        removeEventListeners();
    };

    const disableButtons = () => {
        positiveButton.classList.add('button--disabled');
        negativeButton.classList.add('button--disabled');
    }

    if (hasRating !== 1) {
        positiveButton.addEventListener('click', SubmitPositiveAPICall);
        negativeButton.addEventListener('click', SubmitNegativeAPICall);
    } else {
        disableButtons();
    }

    const CreateRatingCommand = async (pageId: number, rating: boolean) => {
        try {
            const response = await axios.post<InfoRatingDto>(`/api/info?pageid=${pageId}&rating=${rating}`);

            const string =
                response.data.positiveRatingsCount.toString() +
                ' ' +
                (currentCulture == 'ar' ? 'من أصل' : 'out of') +
                ' ' +
                response.data.totalRatingsCount.toString();

            ratingSpan.innerHTML = string;
        } catch (error) {
            console.error(error);
        }
    };

    const removeEventListeners = () => {
        positiveButton.removeEventListener('click', SubmitPositiveAPICall);
        negativeButton.removeEventListener('click', SubmitNegativeAPICall);
        disableButtons();
    };
}
