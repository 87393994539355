const formatDateString = (dateString: string) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthsInArabic = ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];
  const direction: string = document.querySelector("html").getAttribute("dir");

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const monthIndex = date.getUTCMonth();
  const day = date.getUTCDate();

  let month: string = "";

  if (direction === "rtl") {
    month = monthsInArabic[monthIndex];
  } else {
    month = months[monthIndex];
  }

  return `${day} ${month} ${year}`;
}

export default formatDateString;