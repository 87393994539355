import {
    FetchServiceResultsStudentsListItemsPayload,
    FetchServiceResultsStudentsListItemsResponse,
    FetchServiceResultsStudentsTableColumnsNamesResponse,
    ResultItem,
    StudentItem,
} from './serviceResults.types';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { updatePaginationButtons } from '../pagination/pagination';

const serviceResults = document.querySelector('.service-results') as HTMLDivElement;

if (serviceResults) {
    const eventTabs: NodeListOf<HTMLButtonElement> = serviceResults.querySelectorAll('.service-results__tab');
    const sections: NodeListOf<HTMLElement> = serviceResults.querySelectorAll('.service-results__section');
    const eventTabsContainer = serviceResults.querySelector('.service-results__tabs') as HTMLDivElement;
    const tableImagesContainer: NodeListOf<HTMLDivElement> = serviceResults.querySelectorAll(
        '.service-results__table-images'
    );

    let activeTab: number = 0;

    if (eventTabs.length > 1) {
        const resetTabs = () => {
            eventTabs.forEach((tab) => tab.classList.remove('service-results__tab--active'));
            sections.forEach((tab) => tab.classList.remove('service-results__section--active'));
        };

        eventTabs.forEach((tab, index: number) => {
            tab.addEventListener('click', () => {
                activeTab = index;
                resetTabs();

                eventTabs[activeTab].classList.add('service-results__tab--active');
                sections[activeTab].classList.add('service-results__section--active');
            });
        });
    } else {
        eventTabsContainer.classList.add('hidden');
    }

    tableImagesContainer.forEach((item) => {
        if (item.children.length > 1) {
            item.nextElementSibling.classList.add('service-results__table-content-title-alt');
        }
    });

    const studentsTableBody = serviceResults.querySelector('.service-results__table-body') as HTMLDivElement;
    if (studentsTableBody) {
        const studentsMobileTableBody = serviceResults.querySelector(
            '.service-results__table-mobile-body'
        ) as HTMLDivElement;
        const nextPageButton = serviceResults.querySelector('.pagination__page-next') as HTMLButtonElement;
        const prevPageButton = serviceResults.querySelector('.pagination__page-prev') as HTMLButtonElement;
        const paginationDropdown = serviceResults.querySelector('.pagination__dropdown') as HTMLSelectElement;
        const paginationLast = serviceResults.querySelector('.pagination__page-last') as HTMLSelectElement;
        const paginationFirst = serviceResults.querySelector('.pagination__page-first') as HTMLSelectElement;
        const pagination = serviceResults.querySelector('.pagination') as HTMLDivElement;
        const radios: NodeListOf<HTMLInputElement> = serviceResults.querySelectorAll('.sort-filter__input');
        const serviceResultsFilters = serviceResults.querySelector('.service-results__filters') as HTMLDivElement;
        const serviceResultsSearchInput = serviceResults.querySelector(
            '.service-results__search-input'
        ) as HTMLInputElement;
        const dropdownFilters: NodeListOf<HTMLDivElement> = serviceResults.querySelectorAll('.dropdown-filter');
        const currentCulture: string = document.documentElement.getAttribute('culture');
        const parentNodeId = serviceResults.getAttribute('data-id');

        let serviceResultsStudentsItems: ResultItem[] = [];
        let keyword = '';
        let educationDepartments = [];
        let positions = [];
        let years = [];
        let sortBy = 1;
        let pageNumber = 1;
        let pageSize = +paginationDropdown.value;
        let totalPages = 0;
        let totalCount: number = 0;
        let hasNextPage = false;
        let hasPreviousPage = false;
        let buttonsToShow: number = 3;
        let isLoading = false;
        let clickedNumber = null;

        let serviceResultsStudentsTableColumnsNames: FetchServiceResultsStudentsTableColumnsNamesResponse[] = [];

        const fetchServiceResultsStudentsTableColumnsNames = async () => {
            try {
                const response = await axios.get<FetchServiceResultsStudentsTableColumnsNamesResponse[]>(
                    `/api/services/results/columnsNames/${parentNodeId}?currentCulture=${currentCulture}`
                );

                serviceResultsStudentsTableColumnsNames = response.data;
            } catch (error) {
                console.error(error);
            }
        };

        const fetchServiceResultsStudentsList = async (page = null) => {
            clickedNumber = page;
            pagination.classList.add('hidden');

            if (clickedNumber) {
                pageNumber = page;
            }

            const payload: FetchServiceResultsStudentsListItemsPayload = {
                parentNodeId,
                keyword,
                educationDepartments,
                positions,
                years,
                sortBy,
                pageNumber,
                pageSize,
                currentCulture,
            };

            isLoading = true;
            disableInputs();

            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<FetchServiceResultsStudentsListItemsResponse>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/results',
                        payload
                    );
                } else {
                    response = await axios.post<FetchServiceResultsStudentsListItemsResponse>(
                        '/api/services/results',
                        payload
                    );
                }
                serviceResultsStudentsItems = response.data.items;
                if (serviceResultsStudentsItems.length > 0) {
                    pagination.classList.remove('hidden');
                }
                totalPages = response.data.totalPages;
                totalCount = response.data.totalCount;
                hasNextPage = response.data.hasNextPage;
                hasPreviousPage = response.data.hasPreviousPage;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                updatePaginationButtons(
                    totalPages,
                    hasPreviousPage,
                    hasNextPage,
                    pageNumber,
                    pageSize,
                    totalCount,
                    buttonsToShow,
                    serviceResultsStudentsItems,
                    fetchServiceResultsStudentsList
                );
                isLoading = false;
                updateItems();
                enableInputs();
            }
        };

        const disableInputs = () => {
            pagination.classList.add('disabled');
            serviceResultsFilters.classList.add('disabled');
        };

        const enableInputs = () => {
            pagination.classList.remove('disabled');
            serviceResultsFilters.classList.remove('disabled');
        };

        const updateItems = () => {
            studentsTableBody.innerHTML = '';
            studentsMobileTableBody.innerHTML = '';

            serviceResultsStudentsItems.forEach((item: ResultItem) => {
                const rowElement = document.createElement('tr');
                rowElement.className = 'gifted-sublist__table-row';

                rowElement.innerHTML = `
                    ${
                        serviceResultsStudentsTableColumnsNames.some(
                            (item) => item.key.toLowerCase() === 'student/team name',
                        )
                            ? `<td class="service-results__table-data">
                                    <div class="service-results__table-content flex flex--align-center">
                                        <div class="service-results__table-images"> </div>
                                        <span class="service-results__table-content-title">${item.students[0].name}</span>
                                    </div>
                                </td>`
                            : ''
                    }
    
                    ${
                        serviceResultsStudentsTableColumnsNames.some(
                            (item) => item.key.toLowerCase() === 'project title'
                        )
                            ? `<td class="service-results__table-data">
                                    <div
                                        class="service-results__table-content flex flex--align-center">
                                        <span class="service-results__table-content-title">${item.projectTitle}</span>
                                    </div>
                                </td>`
                            : ''
                    }
    
                    ${
                        serviceResultsStudentsTableColumnsNames.some(
                            (item) => item.key.toLowerCase() === 'education department',
                        )
                            ? `<td class="service-results__table-data">
                                    <div
                                        class="service-results__table-content flex flex--align-center">
                                        <span class="service-results__table-content-title">${item.educationDepartment}</span>
                                    </div>
                                </td>`
                            : ''
                    }
    
                    ${
                        serviceResultsStudentsTableColumnsNames.some((item) => item.key.toLowerCase() === 'school')
                            ? `<td class="service-results__table-data">
                                    <div
                                        class="service-results__table-content flex flex--align-center">
                                        <span class="service-results__table-content-title">${item.school}</span>
                                    </div>
                                </td>`
                            : ''
                    }
                    ${
                        serviceResultsStudentsTableColumnsNames.some(
                            (item) => item.key.toLowerCase() === 'search entity'
                        )
                            ? `<td class="service-results__table-data">
                                    <div
                                        class="service-results__table-content flex flex--align-center">
                                        <span class="service-results__table-content-title">${
                                            item.searchEntity !== null ? item.searchEntity : '-'
                                        }</span>
                                    </div>
                                </td>`
                            : ''
                    }
                    
                    ${
                        serviceResultsStudentsTableColumnsNames.some((item) => item.key.toLowerCase() === 'prize')
                            ? `<td class="service-results__table-data">
                                    <div
                                        class="service-results__table-content flex flex--align-center service-results__table-content-icon">
                                        <img src="/assets/img/img/icons/medal-star.svg" alt="icon" class="gifted-sublist__table-icon">
                                        <span class="service-results__table-content-title">${item.position}</span>
                                    </div>
                                </td>`
                            : ''
                    }`;

                var images = rowElement.querySelector('.service-results__table-images') as HTMLDivElement;

                if (images) {
                    item.students
                        .filter((std) => std.image !== null && std.image !== undefined && std.image !== '')
                        .forEach((student) => {
                            const imageItem = document.createElement('img');
                            imageItem.alt = 'student image';
                            imageItem.src = student.image;
                            imageItem.classList.add('service-results__table-image');
                            images.appendChild(imageItem);
                        });
                }

                addMobileRow(item);

                studentsTableBody.appendChild(rowElement);
            });
        };

        const addMobileRow = (item: ResultItem) => {
            const studentName = serviceResultsStudentsTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'student/team name'
            );
            const projectTitle = serviceResultsStudentsTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'project title'
            );
            const educationDepartment = serviceResultsStudentsTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'education department'
            );
            const school = serviceResultsStudentsTableColumnsNames.find((item) => item.key.toLowerCase() === 'school');
            const searchEntity = serviceResultsStudentsTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'search entity'
            );
            const prize = serviceResultsStudentsTableColumnsNames.find((item) => item.key.toLowerCase() === 'prize');

            studentsMobileTableBody.insertAdjacentHTML(
                'beforeend',
                `
            ${
                studentName !== undefined
                    ? `<tr class="gifted-sublist__table-row">
                            <th class="gifted-sublist__table-header">${studentName.value}</th>
                            <td class="service-results__table-data">
                                <div class="service-results__table-content flex flex--align-center">
                                    <div class="service-results__table-images"> </div>
                                    <span class="service-results__table-content-title">${item.students[0].name}</span>
                                </div>
                            </td>
                        </tr>`
                    : ''
            }
    
            ${
                projectTitle !== undefined
                    ? `<tr class="gifted-sublist__table-row">
                            <th class="gifted-sublist__table-header">${projectTitle.value}</th>
                            <td class="service-results__table-data">
                                <div class="service-results__table-content flex flex--align-center">
                                    <span class="service-results__table-content-title">${item.projectTitle}</span>
                                </div>
                            </td>
                        </tr>`
                    : ''
            }
    
            ${
                educationDepartment !== undefined
                    ? `<tr class="gifted-sublist__table-row">
                            <th class="gifted-sublist__table-header">${educationDepartment.value}</th>
                            <td class="service-results__table-data">
                                <div class="service-results__table-content flex flex--align-center">
                                    <span class="service-results__table-content-title">${item.educationDepartment}</span>
                                </div>
                            </td>
                        </tr>`
                    : ''
            }
    
            ${
                prize !== undefined
                    ? `<tr class="gifted-sublist__table-row">
                            <th class="gifted-sublist__table-header">${prize.value}</th>
                            <td class="service-results__table-data">
                                <div class="service-results__table-content flex flex--align-center service-results__table-content-icon">
                                    <img src="/assets/img/img/icons/medal-star.svg" alt="alt" class="gifted-sublist__table-icon" />
                                    <span class="service-results__table-content-title">${item.position}</span>
                                </div>
                            </td>
                        </tr>`
                    : ''
            }
    
            ${
                school !== undefined
                    ? `<tr class="gifted-sublist__table-row">
                            <th class="gifted-sublist__table-header">${school.value}</th>
                            <td class="service-results__table-data">
                                <div class="service-results__table-content flex flex--align-center">
                                    <span class="service-results__table-content-title">${item.school}</span>
                                </div>
                            </td>
                        </tr>`
                    : ''
            }
    
            ${
                searchEntity !== undefined
                    ? `<tr class="gifted-sublist__table-row">
                            <th class="gifted-sublist__table-header">${searchEntity.value}</th>
                            <td class="service-results__table-data">
                                <div class="service-results__table-content flex flex--align-center">
                                    <span class="service-results__table-content-title">${
                                        item.searchEntity !== null ? item.searchEntity : '-'
                                    }</span>
                                </div>
                            </td>
                        </tr>`
                    : ''
            }`
            );
        };

        paginationDropdown.addEventListener('change', () => {
            pageSize = +paginationDropdown.value;
            pageNumber = 1;
            fetchServiceResultsStudentsList();
        });

        nextPageButton.addEventListener('click', () => {
            pageNumber++;

            fetchServiceResultsStudentsList();
        });

        prevPageButton.addEventListener('click', () => {
            pageNumber--;

            fetchServiceResultsStudentsList();
        });

        paginationFirst.addEventListener('click', () => {
            pageNumber = 1;
            fetchServiceResultsStudentsList();
        });

        paginationLast.addEventListener('click', () => {
            pageNumber = totalPages;
            fetchServiceResultsStudentsList();
        });

        let timeout = null;
        serviceResultsSearchInput.addEventListener('input', (e) => {
            clearTimeout(timeout);

            timeout = setTimeout(function () {
                keyword = serviceResultsSearchInput.value.trim();
                pageNumber = 1;
                fetchServiceResultsStudentsList();
            }, 1000);
        });

        radios.forEach((radio) => {
            // 1 = Lowest
            // 2 = Highest

            radio.addEventListener('change', () => {
                const checkedRadio = serviceResults.querySelector(
                    'input[name="sort-filter"]:checked'
                ) as HTMLInputElement;
                const checkedRadioValue = checkedRadio.value;
                sortBy = +checkedRadioValue;
                fetchServiceResultsStudentsList();
            });
        });

        // Observe changes in dropdown filters
        dropdownFilters.forEach((dropdown, index) => {
            const optionsContainer = dropdown.querySelector(
                '.dropdown-filter__button-container'
            ) as HTMLDivElement | null;

            if (optionsContainer) {
                const config: MutationObserverInit = { childList: true };

                const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            switch (index) {
                                case 0:
                                    const educationDepartmentsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        educationDepartmentsOptions.push(element.textContent);
                                    });
                                    educationDepartments = educationDepartmentsOptions;
                                    break;
                                case 1:
                                    const positionsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        positionsOptions.push(element.textContent);
                                    });
                                    positions = positionsOptions;
                                    break;
                                case 2:
                                    const yearsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        yearsOptions.push(element.textContent);
                                    });
                                    years = yearsOptions;
                                    break;
                                default:
                                    break;
                            }

                            pageNumber = 1;
                            fetchServiceResultsStudentsList();
                        }
                    }
                };

                const observer: MutationObserver = new MutationObserver(callback);

                observer.observe(optionsContainer, config);
            }
        });

        const initializeList = async () => {
            await fetchServiceResultsStudentsTableColumnsNames();
            await fetchServiceResultsStudentsList();
        };

        // Call the initialize function
        initializeList();
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}
