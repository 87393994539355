import { updatePaginationButtons } from '../pagination/pagination';
import { ProgramItem, FetchProgramsListingPayload, FetchProgramsListingResponse } from './programsList.types';
import axios from 'axios';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import copyToClipboard from '../../../helpers/copyToClipboard';

const programsList: HTMLDivElement = document.querySelector('.programs-list');

if (programsList) {
    const Grid = programsList.querySelector('.programs-list__images') as HTMLDivElement;
    const nextPageButton = programsList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = programsList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = programsList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = programsList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = programsList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = programsList.querySelector('.pagination') as HTMLDivElement;
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let programsCards: ProgramItem[] = [];
    let pageNumber = 1;
    let pageSize = +paginationDropdown.value;
    let totalPages = 0;
    let totalCount: number = 0;
    let hasNextPage = false;
    let hasPreviousPage = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchPrograms = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchProgramsListingPayload = {
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchProgramsListingResponse>(
                    'https://mawhiba-development.azurewebsites.net/api/programs',
                    payload
                );
            } else {
                response = await axios.post<FetchProgramsListingResponse>(`/api/programs`, payload);
            }

            programsCards = response.data.items;

            if (programsCards.length > 0) {
                pagination.classList.remove('hidden');
            }

            totalCount = response.data.totalCount;
            totalPages = response.data.totalPages;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                programsCards,
                fetchPrograms
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        Grid.innerHTML = '';

        programsCards.forEach((card: ProgramItem) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'programs-list__item sticky flex flex--justify-between';
            cardElement.style.backgroundImage = `url(${card.backgroundImage})`;

            cardElement.innerHTML = `            
            <div class="programs-list__item-content flex flex--column">
                <p class="programs-list__title">${card.title}</p>
                <p class="programs-list__text">${card.text}</p>
            </div>
                
            <div class="programs-list__item-right flex flex--column flex--align-end flex--justify-between">
                <div class="programs-list__buttons flex flex--align-center">
                    <button
                        class="button button--round-tiny shareButton programs-list__button programs-list__button-share flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/share-filled-icon.svg" alt="share icon" class="button__image">
                    </button>
                </div>
            </div>
            `;

            if (card.links.length > 0) {
                const list = document.createElement('ul');
                list.className = 'programs-list__list fade-in flex flex--column flex--align-start';

                card.links.forEach((link) => {
                    const listItem = document.createElement('li');
                    listItem.className = 'programs-list__list-item';
                    listItem.innerHTML = `
                        <a href="${link.linkURL}" class="programs-list__list-link flex flex--align-center flex--justify-between">
                            <span class="programs-list__list-link-text">
                                ${link.title}
                            </span>
                            <img src="/assets/img/img/arrow-left.svg" alt="arrow icon" class="programs-list__list-link-icon">
                        </a>
                    `;
                    list.appendChild(listItem);
                });

                const rightContent = cardElement.querySelector('.programs-list__item-right') as HTMLDivElement;
                rightContent.appendChild(list);

                const moreButton = document.createElement('button');
                moreButton.className =
                    'button button--transparent programs-list__item-button flex flex--justify-center flex--align-center';
                moreButton.innerHTML = `
                    <span class="button__label">${card.linkText}</span>
                    <img src="/assets/img/img/arrow-top-left.svg" alt="arrow icon" class="button__image">
                `;

                rightContent.appendChild(moreButton);
            }

            Grid.appendChild(cardElement);
        });

        // hidden card to lock the last card
        const cardElement = document.createElement('div');
        cardElement.className = 'programs-list__item sticky';
        cardElement.style.opacity = '0';
        cardElement.style.zIndex = '-1';
        Grid.appendChild(cardElement);

        addCardListeners();
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchPrograms();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;
        fetchPrograms();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;
        fetchPrograms();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchPrograms();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchPrograms();
    });

    fetchPrograms();

    const addCardListeners = () => {
        const listCards: NodeListOf<HTMLDivElement> = programsList.querySelectorAll('.programs-list__item');

        if (listCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = programsList.querySelectorAll('.programs-list__button');
            const moreButtons: NodeListOf<HTMLButtonElement> =
                programsList.querySelectorAll('.programs-list__item-button');
            const list: NodeListOf<HTMLUListElement> = programsList.querySelectorAll('.programs-list__list');

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });

            listCards.forEach((card, index) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (
                        target.classList.contains('programs-list__item-button') ||
                        target.parentElement.classList.contains('programs-list__item-button') ||
                        target.parentElement.classList.contains('programs-list__item-button')
                    ) {
                        list[index].classList.toggle('programs-list__list--active');
                        moreButtons[index].classList.add('hidden');
                    } else {
                        closeAllLists();
                    }
                });
            });

            let top: number = 180;
            const headerHeight: number = 65;
            if (window.innerWidth < 1200) {
                top = 20;
            }
            const cardsCount: number = listCards.length;

            if (cardsCount) {
                listCards.forEach((card, i) => {
                    const incValue: number = i * headerHeight;

                    if (i === 0) {
                        card.style.top = `${top}px`;
                    } else {
                        card.style.top = `${top + incValue}px`;
                    }
                });
            }

            const closeAllLists = () => {
                const list: NodeListOf<HTMLUListElement> = programsList.querySelectorAll('.programs-list__list');
                const moreButtons: NodeListOf<HTMLButtonElement> =
                    programsList.querySelectorAll('.programs-list__item-button');

                list.forEach((item, i) => {
                    item.classList.remove('programs-list__list--active');
                    moreButtons[i].classList.remove('hidden');
                });
            };

            window.addEventListener('scroll', () => {
                closeAllLists();
            });
        }
    };
}
