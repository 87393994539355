import copyToClipboard from '../../../helpers/copyToClipboard';
const jobDetails: HTMLDivElement = document.querySelector('.job-details');

if (jobDetails) {
    const tabs: NodeListOf<HTMLButtonElement> = jobDetails.querySelectorAll('.job-details__tab');
    const tabContents: NodeListOf<HTMLDivElement> = jobDetails.querySelectorAll('.job-details__tab-content');
    const starsFill: HTMLDivElement = jobDetails.querySelector('.job-details__stars-fill');
    const direction: string = document.querySelector('html').getAttribute('dir');

    let activeTabIndex: number = 0;
    tabContents[activeTabIndex].classList.remove('hidden');
    tabs[activeTabIndex].classList.add('job-details__tab--active');

    const resetTabs = () => {
        tabs.forEach((tab) => tab.classList.remove('job-details__tab--active'));
        tabContents.forEach((item) => item.classList.add('hidden'));
    };

    tabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            resetTabs();
            tab.classList.add('job-details__tab--active');
            activeTabIndex = index;
            tabContents[activeTabIndex].classList.remove('hidden');
        });
    });
}

const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");
if (shareButtons && shareButtons.length > 0) {
    shareButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        copyToClipboard(button);                 
      });   
    })
}