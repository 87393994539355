import axios from 'axios';
import { StarRatingDto } from './starRatings.types';

export const initStarRatings = () => {
    const starRatingComponents: NodeListOf<HTMLDivElement> = document.querySelectorAll('.reviews');

    if (starRatingComponents.length > 0) {
        starRatingComponents.forEach(item => {
            const starRating = item.querySelector('.star-rating') as HTMLDivElement;
            const pageId = parseInt(starRating.getAttribute('data-pageid'));
            const isUserRated = parseInt(starRating.getAttribute('data-user-rated'));
            const avgRating = parseInt(starRating.getAttribute('data-avg-rating'));
            const ratingValue = item.querySelector('.rating-value') as HTMLDivElement;
            const totalReviews = item.parentNode.querySelector('.service-details__row-title-bold') as HTMLSpanElement;

            // const ratingSpans = starRating.querySelectorAll('.star-rating span.rating') as NodeListOf<HTMLSpanElement>;

            // ratingSpans.forEach((rating) => {
            //     rating.addEventListener('hover', () => {
            //         const value: number = Number(rating.getAttribute('data-value'));

            //         starRating.className = 'star-rating ' + 'rating_' + value.toString().replace('.', '');

            //         highlight_star(value);
            //     });

            //     rating.addEventListener('click', () => {
            //         const value: number = Number(rating.getAttribute('data-value'));

            //         ratingValue.innerHTML = value.toString();

            //         highlight_star(value);
            //     });
            // });

            // const starSpans = starRating.querySelectorAll('.star-rating span.star') as NodeListOf<HTMLSpanElement>;

            // const highlight_star = (rating: number): void => {
            //     starSpans.forEach((star) => {
            //         const low: number = Number(star.getAttribute('data-low'));
            //         const high: number = Number(star.getAttribute('data-high'));
            //         star.classList.remove('active-high', 'active-low');
            //         if (rating >= high) star.classList.add('active-high');
            //         else if (rating === low) star.classList.add('active-low');
            //     });
            // };

            const stars: NodeListOf<HTMLSpanElement> = item.querySelectorAll('.star');

            const SubmitAPICall = (rating: number) => {
                CreateRatingCommand(pageId, rating);
                disableStars();
            };

            const handleMouseOver = (event) => {
                const value = event.target.getAttribute('data-value');
                highlightStars(value);
            };

            const handleMouseOut = () => {
                unhighlightStars();
            };

            const handleClick = (event) => {
                const rating = Number(event.target.getAttribute('data-value'));
                SubmitAPICall(rating);
            };

            const highlightStars = (value) => {
                stars.forEach((star) => {
                    star.classList.toggle('hovered', star.getAttribute('data-value') <= value);
                });
            };

            const unhighlightStars = () => {
                stars.forEach((star) => {
                    star.classList.remove('hovered');
                });
            };

            const selectStars = (value) => {
                stars.forEach((star) => {
                    star.classList.toggle('selected', star.getAttribute('data-value') <= value);
                    star.classList.remove('hovered');
                });
            };

            const updateScienceStar = (rating: number) => {
                const trigger = item.previousElementSibling as HTMLDivElement;
                const unhoveredRatingValue = trigger.querySelector('.star-rating-text') as HTMLDivElement;

                if (trigger && trigger.classList.contains('star-rating-trigger')) {
                    if (unhoveredRatingValue) {
                        unhoveredRatingValue.innerHTML = rating.toString();
                    }

                    trigger.parentElement.blur();
                    trigger.firstElementChild.remove();
                    trigger.insertAdjacentHTML(
                        'afterbegin',
                        `
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="#e2a947" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" />
                        </svg>
                        `,
                    );
                }
            }

            const CreateRatingCommand = async (pageId: number, rating: number) => {
                try {
                    const response = await axios.post<StarRatingDto>(`/api/rating?pageid=${pageId}&rating=${rating}`);
                    selectStars(response.data.rating);
                    ratingValue.innerHTML = response.data.rating.toString();

                    if (totalReviews) {
                        totalReviews.innerHTML = response.data.totalRatings.toString();
                    }

                    updateScienceStar(response.data.rating);
                } catch (error) {
                    console.error(error);
                }
            };

            const disableStars = () => {
                starRating.classList.add('button--disabled');
            };

            if (isUserRated === 0) {
                stars.forEach((star) => {
                    star.addEventListener('mouseover', handleMouseOver);
                    star.addEventListener('mouseout', handleMouseOut);
                    star.addEventListener('click', handleClick);
                });
            } else {
                selectStars(avgRating);
                ratingValue.innerHTML = avgRating.toString();
                disableStars();
            }
        })
    }
}

initStarRatings()