const cards: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(".events-card");

if (cards && cards.length > 0) {
  const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".events-card__button");

  cards.forEach(card => {
    card.addEventListener("click", e => {
      const target = e.target as HTMLElement;

      if (target.classList.contains("button") || target.classList.contains("button__image")) {
        e.preventDefault()
      }
    });
  });

  cardButtons.forEach(button => {
    button.addEventListener("click", e => {
      //console.log("small button clicked")
    })
  })
}
