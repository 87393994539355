import copyToClipboard from '../../../helpers/copyToClipboard';
const contactus : HTMLDivElement = document.querySelector('.contact-us');

if (contactus) {
    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);                 
            });   
        })
    }
}

