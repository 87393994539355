const filters: NodeListOf<HTMLDivElement> = document.querySelectorAll(".dropdown-filter");
const direction: string = document.querySelector("html").getAttribute("dir");

filters.forEach(item => {
  // get select that has the options available
  const selects: NodeListOf<HTMLSelectElement> = item.querySelectorAll(".multi-select");
  selects.forEach(select => {
    init(select);
  });

  document.addEventListener('click', (e) => {
    // get select that has the options available
    const selects: NodeListOf<HTMLSelectElement> = document.querySelectorAll(".multi-select");
    const buttonContainer = document.querySelectorAll(".dropdown-filter__button-container");
    const triggers = document.querySelectorAll(".dropdown-filter__trigger");

    for (let i = 0; i < selects.length; i++) {
      if (e) {
        var isClickInside = selects[i].parentElement.parentElement.contains(e.target as Node);

        if (!isClickInside) {
          const wrapper: HTMLDivElement = selects[i].parentElement.parentElement as HTMLDivElement;
          const dropdown = wrapper.querySelector(".dropdown-filter__dropdown-icon");
          const autocomplete_list = wrapper.querySelector(".dropdown-filter__autocomplete-list");
          //the click was outside the specifiedElement, do something
          dropdown.classList.remove("active");
          const multiSelects = document.querySelectorAll(".dropdown-filter__multi-select-container")
          multiSelects[i].classList.remove("dropdown-filter__multi-select-container--active")
          buttonContainer[i].classList.remove("dropdown-filter__button-container--active")
          triggers[i].classList.remove("dropdown-filter__trigger--active")
          autocomplete_list.innerHTML = "";
          addPlaceholder(wrapper);
        }
      }
    }
  });

  // Show dropdown on trigger click
  const trigger = item.querySelector(".dropdown-filter__trigger")
  if(trigger) {
    trigger.addEventListener("click", handleTrigger);
    trigger.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleTrigger(e);
      }
    });
  }
  

  // FUNCTIONS
  function handleTrigger(e) {
    const wrapper = item.querySelector(".dropdown-filter__multi-select-container");
    const buttonContainer = item.querySelector(".dropdown-filter__button-container") as HTMLDivElement;

    if (wrapper.classList.contains("dropdown-filter__multi-select-container--active")) {
      wrapper.classList.remove("dropdown-filter__multi-select-container--active");
      buttonContainer.classList.remove("dropdown-filter__button-container--active");
      trigger.classList.remove("dropdown-filter__trigger--active");
    } else {
      wrapper.classList.add("dropdown-filter__multi-select-container--active");
      buttonContainer.classList.add("dropdown-filter__button-container--active");
      trigger.classList.add("dropdown-filter__trigger--active");
    }

    const dropdown = wrapper.querySelector(".dropdown-filter__dropdown-icon");
    const event = new Event('click');
    dropdown.dispatchEvent(event);
  }

  function init(element: HTMLSelectElement) {
    const filter = item;
    // Create div that wraps all the elements inside (select, elements selected, search div) to put select inside
    const wrapper = document.createElement("div");
    wrapper.addEventListener("click", clickOnWrapper);
    wrapper.classList.add("dropdown-filter__multi-select-container");

    // Create elements of search
    const search_div = document.createElement("div");
    search_div.classList.add("dropdown-filter__search-container");

    const buttonContainer = document.createElement("div");
    buttonContainer.classList.add("dropdown-filter__button-container")

    const input = document.createElement("input");
    input.classList.add("selected-input");
    input.setAttribute("autocomplete", "off");
    input.setAttribute("tabindex", "0");
    input.addEventListener("keyup", inputChange);
    input.addEventListener("keydown", deletePressed);
    input.addEventListener("click", openOptions);

    const dropdown_icon = document.createElement("a");
    dropdown_icon.classList.add("dropdown-filter__dropdown-icon");

    dropdown_icon.addEventListener("click", clickDropdown);
    const autocomplete_list = document.createElement("ul");
    autocomplete_list.classList.add("dropdown-filter__autocomplete-list")

    search_div.appendChild(input);
    search_div.appendChild(autocomplete_list);
    search_div.appendChild(dropdown_icon);

    filter.appendChild(buttonContainer)

    // set the wrapper as child (instead of the element)
    element.parentNode.replaceChild(wrapper, element);
    // set element as child of wrapper
    wrapper.appendChild(element);
    wrapper.appendChild(search_div);

    createInitialTokens(element);
    addPlaceholder(wrapper);
  }

  function removePlaceholder(wrapper: HTMLDivElement) {
    const input_search = wrapper.querySelector(".selected-input");
    input_search.removeAttribute("placeholder");
  }

  function addPlaceholder(wrapper: HTMLDivElement) {
    const input_search = wrapper.querySelector(".selected-input");
    const tokens = wrapper.querySelectorAll(".dropdown-filter__selected-wrapper");
    if (!tokens.length && !(document.activeElement === input_search))
      input_search.setAttribute("placeholder", "---------");
  }

  // Function that create the initial set of tokens with the options selected by the users
  function createInitialTokens(select: HTMLSelectElement) {
    let { options_selected } = getOptions(select);
    const wrapper: HTMLDivElement = select.parentNode as HTMLDivElement;
    for (let i = 0; i < options_selected.length; i++) {
      createToken(wrapper, options_selected[i]);
    }
  }

  // Listener of user search
  function inputChange(e) {
    const wrapper = e.target.parentNode.parentNode;
    const select = wrapper.querySelector("select");
    const dropdown = wrapper.querySelector(".dropdown-filter__dropdown-icon");

    const input_val = e.target.value;

    if (input_val) {
      dropdown.classList.add("active");
      populateAutocompleteList(select, input_val.trim());
    } else {
      dropdown.classList.remove("active");
      const event = new Event('click');
      dropdown.dispatchEvent(event);
    }
  }

  // Listen for clicks on the wrapper, if click happens focus on the input
  function clickOnWrapper(e) {
    const wrapper = e.target;
    if (wrapper.tagName == "DIV") {
      const input_search = wrapper.querySelector(".selected-input");
      const dropdown = wrapper.querySelector(".dropdown-filter__dropdown-icon");
      if (!dropdown.classList.contains("active")) {
        const event = new Event('click');
        dropdown.dispatchEvent(event);
      }
      input_search.focus();
      removePlaceholder(wrapper);
    }
  }

  function openOptions(e) {
    const input_search = e.target;
    const wrapper = input_search.parentElement.parentElement;
    const dropdown = wrapper.querySelector(".dropdown-filter__dropdown-icon");
    if (!dropdown.classList.contains("active")) {
      const event = new Event('click');
      dropdown.dispatchEvent(event);
    }
    e.stopPropagation();
  }

  // Function that create a token inside of a wrapper with the given value
  function createToken(wrapper: HTMLDivElement, value: string) {
    const buttonContainer: HTMLDivElement = item.querySelector(".dropdown-filter__button-container");

    // Create token container
    const tokenContainer = document.createElement("div");
    tokenContainer.classList.add("wrapper");

    // Create token wrapper
    const token = document.createElement("div");
    token.classList.add("dropdown-filter__selected-wrapper");
    const token_span = document.createElement("span");
    token_span.classList.add("selected-label");
    token_span.innerText = value;
    const close = document.createElement("a");
    close.classList.add("selected-close");
    close.setAttribute("tabindex", "-1");
    close.setAttribute("data-option", value);
    close.setAttribute("data-hits", "0");
    close.addEventListener("click", removeToken)
    token.appendChild(token_span);
    token.appendChild(close);

    buttonContainer.appendChild(token)
  }

  // Listen for clicks in the dropdown option
  function clickDropdown(e) {
    const dropdown = e.target;
    const wrapper = dropdown.parentNode.parentNode;
    const input_search = wrapper.querySelector(".selected-input");
    const select = wrapper.querySelector("select");
    dropdown.classList.toggle("active");

    if (dropdown.classList.contains("active")) {
      removePlaceholder(wrapper);
      input_search.focus();

      if (!input_search.value) {
        populateAutocompleteList(select, "", true);
      } else {
        populateAutocompleteList(select, input_search.value);
      }
    } else {
      clearAutocompleteList(select);
      addPlaceholder(wrapper);
    }
  }

  // Clears the results of the autocomplete list
  function clearAutocompleteList(select) {
    const wrapper = select.parentNode;
    const autocomplete_list: HTMLUListElement = wrapper.querySelector(".dropdown-filter__autocomplete-list");
    autocomplete_list.innerHTML = "";
  }

  // Populate the autocomplete list following a given query from the user
  function populateAutocompleteList(select, query, dropdown = false) {
    const { autocomplete_options, all_options, options_selected } = getOptions(select);

    let options_to_show: Array<any>;

    if (dropdown)
      options_to_show = autocomplete_options;
    else
      options_to_show = autocomplete(query, autocomplete_options);

    const wrapper = select.parentNode;
    const autocomplete_list = wrapper.querySelector(".dropdown-filter__autocomplete-list");
    autocomplete_list.innerHTML = "";
    const result_size = options_to_show.length;

    if (result_size == 1) {
      const li = document.createElement("li");
      li.innerText = options_to_show[0];
      li.setAttribute('data-value', options_to_show[0]);
      li.addEventListener("click", selectOption);
      autocomplete_list.appendChild(li);
      if (query.length == options_to_show[0].length) {
        const event = new Event('click');
        li.dispatchEvent(event);
      }
    } else if (result_size > 1) {
      for (let i = 0; i < result_size; i++) {
        const li = document.createElement("li");
        li.innerText = options_to_show[i];
        li.setAttribute('data-value', options_to_show[i]);
        li.addEventListener("click", selectOption);
        autocomplete_list.appendChild(li);
      }
    } else {
      const li = document.createElement("li");
      li.classList.add("not-cursor");
      if (direction === "rtl") {
        li.innerText = "لم يتم العثور على خيارات";
      } else {
        li.innerText = "No options found";
      }
      autocomplete_list.appendChild(li);
    }

    handleListItems(options_selected)
  }

  // Listener to autocomplete results when clicked set the selected property in the select option
  function selectOption(e) {
    const filterComponent = item

    // get selected options
    const select: HTMLSelectElement = filterComponent.querySelector(".multi-select");
    const options_selected = Array.from(
      select.querySelectorAll("option:checked")
    ).map((el: HTMLSelectElement) => el.value);

    if (options_selected.includes(e.target.dataset.value)) {
      removeTokenList(e.target.dataset.value)
      return
    }

    const wrapper = e.target.parentNode.parentNode.parentNode;
    const input_search: HTMLInputElement = wrapper.querySelector(".selected-input");
    const option: HTMLOptionElement = wrapper.querySelector(`select option[value="${e.target.dataset.value}"]`);

    option.setAttribute("selected", "");

    createToken(wrapper, e.target.dataset.value);

    if (input_search.value) {
      input_search.value = "";
    }

    input_search.focus();

    const autocomplete_list: HTMLUListElement = wrapper.querySelector(".dropdown-filter__autocomplete-list");

    if (!autocomplete_list.children.length) {
      const li = document.createElement("li");
      li.classList.add("not-cursor");
      li.innerText = "No options found";
      autocomplete_list.appendChild(li);
    }

    const event = new Event('keyup');
    input_search.dispatchEvent(event);
    e.stopPropagation();
  }

  // function that returns a list with the autcomplete list of matches
  function autocomplete(query, options) {
    // No query passed, just return entire list
    if (!query) {
      return options;
    }

    let options_return = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].toLowerCase().includes(query.toLowerCase())) {
        options_return.push(options[i]);
      }
    }

    return options_return;
  }

  // Returns the options that are selected by the user and the ones that are not
  function getOptions(select) {
    // Select all the options available
    const all_options = Array.from(
      select.querySelectorAll("option")
    ).map((el: HTMLSelectElement) => el.value);

    // Get the options that are selected from the user
    const options_selected = Array.from(
      select.querySelectorAll("option:checked")
    ).map((el: HTMLSelectElement) => el.value);

    // Create an autocomplete options array with the options that are not selected by the user
    let autocomplete_options = [];
    all_options.forEach(option => {
      if (!options_selected.includes(option)) {
        autocomplete_options.push(option);
      }
    });

    autocomplete_options.sort();

    autocomplete_options = all_options

    return {
      options_selected,
      autocomplete_options,
      all_options
    };
  }

  // Listener for when the user wants to remove a given token.
  function removeToken(e) {
    // Get the value to remove
    const value_to_remove = e.target.dataset.option;
    const wrapper = item.querySelector(".multi-select");
    const input_search = item.querySelector(".selected-input") as HTMLInputElement;
    const dropdown = item.querySelector(".dropdown-filter__dropdown-icon");
    // Get the options in the select to be unselected
    const option_to_unselect = wrapper.querySelector(`select option[value="${value_to_remove}"]`);
    option_to_unselect.removeAttribute("selected");
    // Remove token attribute
    e.target.parentNode.remove();
    input_search.focus();
    dropdown.classList.remove("active");
    const event = new Event('click');
    dropdown.dispatchEvent(event);
    e.stopPropagation();
  }

  // Listener for when the user wants to remove a given token.
  function removeTokenList(value) {
    // Get the value to remove
    const value_to_remove = value;
    const wrapper = item.querySelector(".multi-select");
    const input_search = item.querySelector(".selected-input") as HTMLInputElement;
    // Get the options in the select to be unselected
    const option_to_unselect = wrapper.querySelector(`select option[value="${value_to_remove}"]`);
    option_to_unselect.removeAttribute("selected");

    const options_selected = [];

    // Remove button
    const selectedElements: NodeListOf<HTMLSpanElement> = item.querySelectorAll(".selected-label");
    selectedElements.forEach(el => {
      if (el.textContent === value) {
        el.parentElement.remove()
      }
    })

    const updatedSelectedElements: NodeListOf<HTMLSpanElement> = item.querySelectorAll(".selected-label");

    updatedSelectedElements.forEach(item => {
      options_selected.push(item.textContent)
    })

    input_search.focus();
    handleListItems(options_selected)
  }

  // Listen for 2 sequence of hits on the delete key, if this happens delete the last token if exist
  function deletePressed(e) {
    const wrapper = e.target.parentNode.parentNode;
    const input_search = e.target;
    const key = e.keyCode || e.charCode;
    const tokens = wrapper.querySelectorAll(".dropdown-filter__selected-wrapper");

    if (tokens.length) {
      const last_token_x = tokens[tokens.length - 1].querySelector("a");
      let hits = +last_token_x.dataset.hits;

      if (key == 8 || key == 46) {
        if (!input_search.value) {
          if (hits > 1) {
            // Trigger delete event
            const event = new Event('click');
            last_token_x.dispatchEvent(event);
          } else {
            last_token_x.dataset.hits = 2;
          }
        }
      } else {
        last_token_x.dataset.hits = 0;
      }
    }
    return true;
  }

  // You can call this function if you want to add new options to the select plugin
  // Target needs to be a unique identifier from the select you want to append new option for example #multi-select-plugin
  // Example of usage addOption("#multi-select-plugin", "tesla", "Tesla")
  function addOption(target, val, text) {
    const select = item.querySelector(target);
    let opt = document.createElement('option');
    opt.value = val;
    opt.innerHTML = text;
    select.appendChild(opt);
  }

  const handleListItems = (arr: Array<any>) => {
    const list: NodeListOf<HTMLDListElement> = item.querySelectorAll(".dropdown-filter__autocomplete-list li");

    list.forEach(item => {
      if (arr.includes(item.textContent)) {
        item.classList.add("item--active")
      } else {
        item.classList.remove("item--active")
      }
    })
  }
})