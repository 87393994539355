import copyToClipboard from '../../../helpers/copyToClipboard';

const details: HTMLDivElement = document.querySelector('.article-detail');

if (details)
{
  const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");

  if (shareButtons && shareButtons.length > 0) {
      shareButtons.forEach(button => {
        button.addEventListener('click', (e) => {
          copyToClipboard(button);                 
        });   
      })
  }
}