const navbar: HTMLHeadElement = document.querySelector('.navbar');

if (navbar) {
    const searchButton = navbar.querySelector('.navbar__button-search') as HTMLButtonElement;
    const linkItems: NodeListOf<HTMLLIElement> = navbar.querySelectorAll('.navbar__links-drop');
    const searchInput = navbar.querySelector('.navbar__button-search-input') as HTMLInputElement;

    const checkScrollPosition = () => {
        if (window.scrollY === 0) {
            navbar.classList.remove('navbar--sticky');
        } else {
            navbar.classList.add('navbar--sticky');
        }
    };

    const openSearchDropdown = () => {
        searchButton.nextElementSibling.classList.add('navbar__button-search-inner--active');
    };

    const closeSearchDropdown = () => {
        searchButton.nextElementSibling.classList.remove('navbar__button-search-inner--active');
    };



    linkItems.forEach((link) => {
        const href: string = link.getAttribute('data-url');

        link.addEventListener('click', () => {
            if (href) {
                window.location.href = href;
            }
        });

        link.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Enter' && href) {
                window.location.href = href;
            }
        });
    });

    window.addEventListener('click', (event: MouseEvent) => {
        const targetElement = navbar.querySelector('.navbar__button-search-inner');
        let clickedElement = event.target as Element;

        if (clickedElement.classList.contains('navbar__button-search')) {
            if (clickedElement.nextElementSibling.classList.contains('navbar__button-search-inner--active')) {
                closeSearchDropdown();
            } else {
                openSearchDropdown();
            }
            return;
        }

        if (!targetElement) return;

        while (clickedElement) {
            if (clickedElement === targetElement) {
                searchButton.nextElementSibling.classList.add('navbar__button-search-inner--active');
                return;
            }
            clickedElement = clickedElement.parentElement;
        }

        searchButton.nextElementSibling.classList.remove('navbar__button-search-inner--active');
    });

    window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeSearchDropdown();
        }
    });

    window.addEventListener('scroll', checkScrollPosition);

    checkScrollPosition();
}
