import formatDateString from '../../../helpers/formatDate';
import convertTime from '../../../helpers/formatTime';
import { TooltipItem, TimelineItem } from './serviceTimeline.types';
import axios from 'axios';

const serviceTimeline = document.querySelector('.service-timeline') as HTMLDivElement;
const direction: string = document.querySelector('html').getAttribute('dir');

if (serviceTimeline) {
    const serviceTimelineGrid = serviceTimeline.querySelector('.service-timeline__inner') as HTMLDivElement;
    const parentNodeId = serviceTimeline.getAttribute('data-id');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let timelineItems: TimelineItem[] = [];

    const fetchServiceTimeline = async () => {
        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.get<TimelineItem[]>(
                    `https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/timeline?nodeId=${parentNodeId}&currentCulture=${currentCulture}`
                );
            } else {
                response = await axios.get<TimelineItem[]>(
                    `/api/services/timeline?nodeId=${parentNodeId}&currentCulture=${currentCulture}`
                );
            }
            timelineItems = response.data;
        } catch (error) {
            console.error(error);
        } finally {
            updateItems();

            initializeDates();
        }
    };

    const updateItems = () => {
        serviceTimelineGrid.innerHTML = '';

        timelineItems.forEach((item: TimelineItem) => {
            const itemElement = document.createElement('div');

            itemElement.className = 'service-timeline__item';

            itemElement.innerHTML = `
            <div class="service-timeline__content flex">
              <div class="service-timeline__dates flex flex--align-center">
                <div
                  class="service-timeline__date service-timeline__date-from flex flex--column flex--justify-center flex--align-center"
                  data-dateFrom="${item.startDate}">
                  <span class="service-timeline__date-month service-timeline__date-month-from"></span>
                  <span class="service-timeline__date-day service-timeline__date-day-from"></span>
                  <span class="service-timeline__date-year service-timeline__date-year-from"></span>
                </div>
                ${item.endDate !== null
                    ? `<div
                            class="service-timeline__date service-timeline__date-to flex flex--column flex--justify-center flex--align-center"
                            data-dateTo="${item.endDate}">
                            <span class="service-timeline__date-month service-timeline__date-month-to"></span>
                            <span class="service-timeline__date-day service-timeline__date-day-to"></span>
                            <span class="service-timeline__date-year service-timeline__date-year-to"></span>
                          </div>`
                    : ''
                }
              </div>
              <div class="service-timeline__info flex flex--column">
                <div class="service-timeline__item-title flex flex--align-start flex--justify-between">
                  ${item.title}
                  ${item.tooltips !== null && item.tooltips.length !== 0
                    ? `<button class="service-timeline__item-button">
                              <img src="/assets/img/img/icons/info-circle.svg" alt="info circle icon"
                                class="service-timeline__item-icon">
                              <ul class="service-timeline__item-tooltip ">
                                <div class="service-timeline__item-tooltip-inner flex flex--column">
                                  
                                </div>
                              </ul>
                            </button>`
                    : ''
                }
                </div>
                <div class="service-timeline__item-text">
                  ${item.description}
                </div>
              </div>
            </div>`;

            if (item.tooltips !== null && item.tooltips.length !== 0) {
                const tooltipList = itemElement.querySelector(
                    '.service-timeline__item-tooltip-inner'
                ) as HTMLDivElement;

                item.tooltips.forEach((tootlip: TooltipItem) => {
                    const tooltipElement = document.createElement('li');

                    tooltipElement.className = 'service-timeline__item-tooltip-row flex flex--align-center';

                    tooltipElement.innerHTML = `
                      <div class="service-timeline__item-tooltip-text">${tootlip.educationLevel}</div>
                      <div class="service-timeline__item-tooltip-date flex flex--align-center"
                        data-tooltipDate="${tootlip.date}">
                        <img src="/assets/img/img/icons/calendar-thick.svg" alt="calendar icon"
                          class="service-timeline__item-tooltip-icon">
                        <span class="service-timeline__item-tooltip-content">
                        </span>
                      </div>
                      <div class="service-timeline__item-tooltip-time flex flex--align-center"
                        data-tooltipTime="${tootlip.date}">
                        <img src="/assets/img/img/icons/clock-thick.svg" alt="clock icon"
                          class="service-timeline__item-tooltip-icon">
                        <span class="service-timeline__item-tooltip-timing">
                        </span>
                      </div>`;

                    tooltipList.appendChild(tooltipElement);
                });
            }

            serviceTimelineGrid.appendChild(itemElement);
        });
    };

    const initializeDates = () => {
        const serviceTimelineItems: NodeListOf<HTMLDivElement> =
            serviceTimeline.querySelectorAll('.service-timeline__item');
        if (serviceTimelineItems.length === 1) {
            serviceTimelineItems[0].classList.add('service-timeline__item--single');
        }

        const setDates = (month: HTMLSpanElement, day: HTMLSpanElement, year: HTMLSpanElement, arr: string[]) => {
            month.textContent = arr[1];
            day.textContent = arr[0];
            year.textContent = arr[2];
        };

        serviceTimelineItems.forEach((item) => {
            const dateFrom = item.querySelector('.service-timeline__date-from').getAttribute('data-dateFrom') as string;
            const dateTo = item.querySelector('.service-timeline__date-to')?.getAttribute('data-dateTo') as string;
            const monthFromElement = item.querySelector('.service-timeline__date-month-from') as HTMLSpanElement;
            const dayFromElement = item.querySelector('.service-timeline__date-day-from') as HTMLSpanElement;
            const yearFromElement = item.querySelector('.service-timeline__date-year-from') as HTMLSpanElement;
            const dateFromArray: string[] = formatDateString(dateFrom).split(' ');

            setDates(monthFromElement, dayFromElement, yearFromElement, dateFromArray);

            if (dateTo) {
                const monthToElement = item.querySelector('.service-timeline__date-month-to') as HTMLSpanElement;
                const dayToElement = item.querySelector('.service-timeline__date-day-to') as HTMLSpanElement;
                const yearToElement = item.querySelector('.service-timeline__date-year-to') as HTMLSpanElement;
                const dateToArray: string[] = formatDateString(dateTo).split(' ');

                setDates(monthToElement, dayToElement, yearToElement, dateToArray);
            }

            const tooltipDateElements: NodeListOf<HTMLDivElement> = item.querySelectorAll(
                '.service-timeline__item-tooltip-date'
            );
            const tooltipTimeElements: NodeListOf<HTMLDivElement> = item.querySelectorAll(
                '.service-timeline__item-tooltip-time'
            );

            if (tooltipDateElements.length > 0) {
                tooltipDateElements.forEach((tool) => {
                    const content = tool.querySelector('.service-timeline__item-tooltip-content') as HTMLSpanElement;

                    content.textContent = formatDateString(tool.getAttribute('data-tooltipDate'));
                });
            }

            if (tooltipTimeElements.length > 0) {
                tooltipTimeElements.forEach((tool, index) => {
                    const timing = tool.querySelector('.service-timeline__item-tooltip-timing') as HTMLSpanElement;

                    if (timing) {
                        timing.textContent = convertTime(tool.getAttribute('data-tooltipTime'), direction);
                    }
                });
            }
        });
    };

    fetchServiceTimeline();
}
