import { FetchImagesGalleryPayload, FetchImagesGalleryResponse, ImageCard } from './imageGallery.types';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import copyToClipboard from '../../../helpers/copyToClipboard';
import insertPageView from '../../../helpers/InsertPageView';
import { IsFavourited } from '../../../helpers/isFavourite';
import IsUserLoggedIn from '../../../helpers/isUserLoggedIn';

const imagesGallery: HTMLDivElement = document.querySelector('.image-gallery');

if (imagesGallery) {
    const imagesGrid = imagesGallery.querySelector('.image-gallery__grid') as HTMLDivElement;
    const imagesFilters = imagesGallery.querySelector('.image-gallery__filters') as HTMLDivElement;
    const imagesGallerySearchInput = imagesGallery.querySelector('.image-gallery__search-input') as HTMLInputElement;
    const nextPageButton = imagesGallery.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = imagesGallery.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = imagesGallery.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = imagesGallery.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = imagesGallery.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = imagesGallery.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = imagesGallery.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const favouritedIds: number[] = JSON.parse(imagesGrid.getAttribute('data-ids'));

    let imagesCards: ImageCard[] = [];
    let keyword: string = '';
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchImagesGallery = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchImagesGalleryPayload = {
            keyword,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchImagesGalleryResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/imagesGallery',
                    payload,
                );
            } else {
                response = await axios.post<FetchImagesGalleryResponse>('/api/imagesGallery', payload);
            }
            imagesCards = response.data.items;
            if (imagesCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                imagesCards,
                fetchImagesGallery,
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        imagesGrid.innerHTML = '';

        imagesCards.forEach((card: ImageCard) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'image-card flex flex--column';

            var _class = '';
            if (IsFavourited(card.id, favouritedIds)) {
                _class = 'active';
            }

            cardElement.innerHTML = `
            <div class='image-card__tooltip'>${card.title}</div> 
                <img src='${card.imageURL}' alt='image' class='image-card__image' />
                <div class='image-card__border flex flex--justify-between'>
                    <a href="${
                        card.imageURL
                    }" draggable="false" download class="button button--round-small image-card__download flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/icons/download--blue-bg.svg" alt="download icon" class="button__image">
                    </a>
                    <div class='image-card__icons flex flex--align-center flex--justify-center'>
                        <div class="flex">
                            <span class='image-card__count'>${card.views}</span>
                            <img src='/assets/img/img/icons/eye.svg' alt='views icon' class='image-card__eye' />
                        </div>
                        ${
                            IsUserLoggedIn()
                                ? `<button
                                    class="button button--round-small favouriteButton image-card__button image-card__icon flex flex--justify-center flex--align-center ${_class}" data-type="image" data-id="${card.id}">
                                    <img src="/assets/img/img/heart-icon.svg" alt="heart icon" class="button__image">
                                    </button>`
                                : ''
                        }
                        
                        <button
                            class="button button--round-small shareButton image-card__button image-card__icon flex flex--justify-center flex--align-center">
                            <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                        </button>
                        <div class='modal modal--empty' data-page-id='${card.id}'>
                            <button tabindex="0" class="modal__open">
                                <img tabindex="0" class='modal__icon modal__icon--empty' />
                            </button>
                            <dialog class='modal__dialog modal__dialog-image-gallery'>
                                <div class='modal__image-gallery-inner'>
                                    <img src='${card.imageURL}' alt='image' class='image-card__image' />
                                    <div class='image-card__border flex flex--justify-between'>
                                        <a href="${
                                            card.imageURL
                                        }" draggable="false" download class="button button--round-small image-card__download flex flex--justify-center flex--align-center">
                                            <img src="/assets/img/img/icons/download--blue-bg.svg" alt="download icon" class="button__image">
                                        </a>
                                        <div class='image-card__icons flex flex--align-center flex--justify-center'>
                                            <div class="flex">
                                                <span class='image-card__count'>${card.views}</span>
                                                <img src='/assets/img/img/icons/eye.svg' alt='views icon' class='image-card__eye' />
                                            </div>
                                            ${
                                                IsUserLoggedIn()
                                                    ? `<button class="button button--round-small favouriteButton image-card__button image-card__icon flex flex--justify-center flex--align-center ${_class}" data-type="image" data-id="${card.id}">
                                                            <img src="/assets/img/img/heart-icon.svg" alt="heart icon" class="button__image">
                                                        </button>`
                                                    : ''
                                            }
                                            
                                            <button
                                                class="button button--round-small shareButton image-card__button image-card__icon flex flex--justify-center flex--align-center">
                                                <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </dialog>
                        </div>
                    </div>
                </div>
                `;
            imagesGrid.appendChild(cardElement);
        });

        addCardListeners();
        enableModals();
    };

    const enableModals = () => {
        const modals: NodeListOf<HTMLDivElement> = document.querySelectorAll('.modal');
        const html = document.querySelector('html') as HTMLHtmlElement;

        if (modals.length > 0) {
            modals?.forEach((modalContainer, index) => {
                const modalDialog = modalContainer.querySelector<HTMLDialogElement>('dialog');
                if (!modalDialog) return;

                const modalId = `modal-${index}`;
                const openButtonId = `open-button-${index}`;

                modalDialog.id = modalId;
                const openButton = modalContainer.querySelector<HTMLImageElement>('.modal__open');
                if (openButton) {
                    openButton.id = openButtonId;
                }

                const closeButton = modalDialog.querySelector<HTMLButtonElement>('.modal__close');

                const pageId = parseInt(modalContainer.getAttribute('data-page-id'));
                document.getElementById(openButtonId)?.addEventListener('click', (event) => {
                    modalDialog.showModal();
                    html.style.overflow = 'hidden';
                    if (pageId > 0) {
                        insertPageView(pageId);
                    }
                });

                closeButton?.addEventListener('click', (event) => {
                    modalDialog.close();
                    html.style.overflow = '';
                });

                modalDialog.addEventListener('click', (event) => {
                    if (event.target === modalDialog) {
                        modalDialog.close();
                        html.style.overflow = '';
                    }
                });

                window.addEventListener('keydown', (e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                        html.style.overflow = '';
                    }
                });
            });
        }
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        imagesFilters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        imagesFilters.classList.remove('disabled');
    };

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchImagesGallery();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchImagesGallery();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchImagesGallery();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchImagesGallery();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchImagesGallery();
    });

    let timeout = null;
    imagesGallerySearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = imagesGallerySearchInput.value.trim();
            pageNumber = 1;
            fetchImagesGallery();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = latest
        // 2 = oldest
        // 3 = mostViewed

        radio.addEventListener('change', () => {
            const checkedRadio = imagesGallery.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchImagesGallery();
        });
    });

    fetchImagesGallery();

    const addCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = imagesGrid.querySelectorAll('.image-card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.image-card__button');

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });
        }
    };
}
