import copyToClipboard from '../../../helpers/copyToClipboard';

const eacomponent: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(".employee-access-component");

if (eacomponent) {
  const buttons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".employee-access-component__button");

  buttons.forEach(button => {
    button.addEventListener("click", e => {
      const target = e.target as HTMLElement;

      if (target.classList.contains("button") || target.classList.contains("button__image")) {
        e.preventDefault()
      }
      
      if (target.classList.contains("shareButton")) {
        copyToClipboard(button);   
      }
    });
  });
}
