const initializeProgramStatsComponent = (webStatsComponent: HTMLElement) => {
    const programStatsTabs: NodeListOf<HTMLElement> = webStatsComponent.querySelectorAll('.program-stats__tab');
    const programStatsContents: NodeListOf<HTMLElement> = webStatsComponent.querySelectorAll('.program-stats__content');
    let activeTab = 0;

    // Tab logic
    const showSelectedItems = (activeTab: number) => {
        programStatsTabs.forEach((tab, index) => {
            tab.classList.remove('program-stats__tab--active');
            programStatsContents[index].style.display = 'none';   
        });

        programStatsTabs[activeTab].classList.add('program-stats__tab--active');
        programStatsContents[activeTab].style.display = 'block';
    };

    const handleTabClick = (index: number) => {
        activeTab = index;
        showSelectedItems(activeTab);
    };

    programStatsTabs.forEach((tab, index) => {
        tab.addEventListener('click', () => handleTabClick(index));
    });

    showSelectedItems(activeTab);
};

document.querySelectorAll('.program-stats').forEach((webStatsComponent) => {
    initializeProgramStatsComponent(webStatsComponent as HTMLElement);
});
