const digitalLibrary = document.querySelector('.digital-library-list__wrapper') as HTMLDivElement; 

if (digitalLibrary) {
  const digitalCards: NodeListOf<HTMLDivElement> = digitalLibrary.querySelectorAll('.digital-library-list__item');

  if (digitalCards.length > 0) {  
    let top: number = 180;
    const headerHeight: number = 65;
    if (window.innerWidth < 1200) {
      top = 20;
    }

    digitalCards.forEach((card, i) => {
      const incValue: number = i * headerHeight;

      if (i === 0) {
          card.style.top = `${top}px`;
      } else {
          card.style.top = `${top + incValue}px`;
      }
    });
  }
}