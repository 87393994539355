import { FetchFaqPayload, FetchFaqResponse, FaqItem, FaqGroupItem } from './faq.types';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';

const faq: HTMLDivElement = document.querySelector('.faq');

if (faq) {
    const faqsList = faq.querySelector('.accordion__items') as HTMLDivElement;
    const faqFilters = faq.querySelector('.faq__filters') as HTMLDivElement;
    const faqSearchInput = faq.querySelector('.input--search') as HTMLInputElement;
    const nextPageButton = faq.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = faq.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = faq.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = faq.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = faq.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = faq.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = faq.querySelectorAll('.sort-filter__input');
    const dropdownFilters: NodeListOf<HTMLDivElement> = faq.querySelectorAll('.dropdown-filter');
    const singleDropdown = faq.querySelector('.single-select') as HTMLSelectElement | null;
    const singleDropdownFilterTrigger: HTMLDivElement = faq.querySelector('.single-dropdown-filter__trigger');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const requestUrl : string = (document.getElementById("requestUrl") as HTMLInputElement).value;
    const faqGroupKey : string = (document.getElementById("faqGroupKey" )as HTMLInputElement).value;

    let faqGroups: FaqGroupItem[] = [];
    let keyword: string = '';
    let faqsGroup: string = faqGroupKey;
    let beneficiaries: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchFaqs = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchFaqPayload = {
            keyword,
            faqsGroup,
            beneficiaries,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;
        
        disableInputs();
        try {
            let response = await axios.post<FetchFaqResponse>(
                requestUrl,
                payload
            );
            faqGroups = response.data.items;
            if (faqGroups.length > pageSize) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                faqGroups,
                fetchFaqs
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        faqsList.innerHTML = '';

        faqGroups.forEach((group: FaqGroupItem) => {
            const groupElement = document.createElement('div');
            groupElement.className = 'accordion__subtitle';
            groupElement.innerHTML = group.subCategory;
            faqsList.appendChild(groupElement);

            group.items.forEach((item: FaqItem) => {
                const cardElement = document.createElement('details');

                cardElement.className = 'accordion__item fade-in';

                cardElement.innerHTML = `
                <summary
                    class="accordion__item-title-container flex flex--align-center flex--justify-between">
                    <span class="accordion__item-title">${item.question}</span>
                </summary>
                <div class="accordion__item-inner rte">
                    ${item.answer}
                </div>`;

                faqsList.appendChild(cardElement);
            });
        });
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        faqFilters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        faqFilters.classList.remove('disabled');
    };

    singleDropdown?.addEventListener('change', () => {
        const selected: HTMLSelectElement = singleDropdown.querySelector('option:checked');
        faqsGroup = selected.value;
        pageNumber = 1;
        fetchFaqs();
    });
  
    singleDropdownFilterTrigger?.addEventListener('keydown', (e) => {
        e.preventDefault();
    });
    

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchFaqs();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchFaqs();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchFaqs();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchFaqs();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchFaqs();
    });

    let timeout = null;
    faqSearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = faqSearchInput.value.trim();
            pageNumber = 1;
            fetchFaqs();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = latest
        // 2 = oldest

        radio.addEventListener('change', () => {
            const checkedRadio = faq.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchFaqs();
        });
    });

    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const beneficiariesOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    beneficiariesOptions.push(element.textContent);
                                });
                                beneficiaries = beneficiariesOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchFaqs();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });

    fetchFaqs();

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}
