document.addEventListener('DOMContentLoaded', () => {
    const videoCards = document.querySelectorAll('.video-inline-card');

    if (videoCards) {
        const mobileBreakpoint = '(max-width: 767px)';

        videoCards.forEach((card: Element) => {
            if(card.classList.contains('video-inline-card--youtube')){
                return
            } else {
                const video = card.querySelector('.video-inline-card__video') as HTMLVideoElement;
                const playButton = card.querySelector('.video-inline-card__play') as HTMLImageElement;
    
                card.addEventListener('click', () => {
                    if (window.matchMedia(mobileBreakpoint).matches) {
                        if (video.paused) {
                            video.play();
                            playButton.style.opacity = '0';
                        } else {
                            video.pause();
                            playButton.style.opacity = '1';
                        }
                    }
                });
    
                card.addEventListener('mouseenter', () => {
                    if (!window.matchMedia(mobileBreakpoint).matches) {
                        video.play();
                    }
                });
    
                card.addEventListener('mouseleave', () => {
                    if (!window.matchMedia(mobileBreakpoint).matches) {
                        video.pause();
                        video.currentTime = 0;
                    }
                });
            }

        });

        const videoButtons = document.querySelectorAll('.video-inline-card__download') as NodeListOf<HTMLButtonElement>;

        videoButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                const fileUrl = button.parentElement.getAttribute('data-video') as string;

                if (fileUrl) {
                    window.open(fileUrl, '_blank');
                }
            });
        });
    }
});
