import Glide from '@glidejs/glide';

const partnersComponent: HTMLDivElement = document.querySelector('.partners');

if (partnersComponent) {
    const partnersCarousels: NodeListOf<Element> = partnersComponent.querySelectorAll('.partners__carousel-inner');
    const direction: string = document.querySelector('html').getAttribute('dir');
    const partnersTabs: NodeListOf<HTMLButtonElement> = partnersComponent.querySelectorAll('.partners__tab');
    const partnersCarouselsContainer: NodeListOf<HTMLDivElement> =
        partnersComponent.querySelectorAll('.partners__carousel');
    let activeTab: number = 0;

    if (partnersCarousels.length > 0) {
        const partnersCarouselsArray = Array.from(partnersCarousels) || [];

        partnersCarouselsArray.forEach((item: HTMLElement) => {
            const glideSettings = {
                type: 'slider',
                perView: 4,
                gap: 24,
                rewind: true,
                bound: true,
                hoverpause: true,
                direction: direction || 'ltr',
                breakpoints: {
                    1400: {
                        perView: 4,
                    },
                    1100: {
                        perView: 3,
                        gap: 16,
                    },
                },
            };

            const glide = new Glide(item, glideSettings);

            glide.mount();
        });
    }

    const resetAll = () => {
        partnersCarouselsContainer.forEach((carousel) => carousel.classList.remove('partners__carousel--active'));
        partnersTabs.forEach((tab) => tab.classList.remove('partners__tab--active'));
    };

    const showActiveItems = (activeTabNum: number) => {
        resetAll();
        partnersTabs[activeTabNum].classList.add('partners__tab--active');
        partnersCarouselsContainer[activeTabNum].classList.add('partners__carousel--active');
    };

    partnersTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            showActiveItems(activeTab);
        });
    });

    showActiveItems(activeTab);
}
