import {
    FetchEventsListPayload,
    FetchEventsListResponse,
    EventListCard,
    FetchEventCardSettingsResponse,
} from './eventsList.types';
import formatDateString from '../../../helpers/formatDate';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import copyToClipboard from '../../../helpers/copyToClipboard';
import addToCalendar from '../../../helpers/addToCalendar';
import IsUserLoggedIn from '../../../helpers/isUserLoggedIn';

const eventsList: HTMLDivElement = document.querySelector('.events-list');

if (eventsList) {
    const eventsGrid = eventsList.querySelector('.events-list__grid') as HTMLDivElement;
    const eventsFilters = eventsList.querySelector('.events-list__search') as HTMLDivElement;
    const eventsListSearchInput = eventsList.querySelector('.events-list__search-input') as HTMLInputElement;
    const eventDateFrom = eventsList.querySelector('.date-range__input-from') as HTMLInputElement;
    const eventDateTo = eventsList.querySelector('.date-range__input-to') as HTMLInputElement;
    const eventTabs: NodeListOf<HTMLButtonElement> = eventsList.querySelectorAll('.events-list__tab');
    const eventTabsComponent = eventsList.querySelector('.events-list__tabs') as HTMLDivElement;
    const nextPageButton = eventsList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = eventsList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = eventsList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = eventsList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = eventsList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = eventsList.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = eventsList.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const favouritedIds: number[] = JSON.parse(eventsGrid.getAttribute('data-ids'));

    let activeTab: number = 0;

    let eventCards: EventListCard[] = [];
    let keyword: string = '';
    let dateFrom: string = '';
    let dateTo: string = '';
    let eventType: number = 1;
    let sortBy: number = 2;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    let eventCardSettings: FetchEventCardSettingsResponse = {
        dateFromText: '',
        dateToText: '',
        locationText: '',
    };

    // Functions
    const resetTabs = () => {
        eventTabs.forEach((tab) => tab.classList.remove('events-list__tab--active'));
    };

    const fetchEventCardSettings = async () => {
        try {
            const response = await axios.get<FetchEventCardSettingsResponse>(
                `https://app-prd-umbracoportal-uaenorth.azurewebsites.net/api/events/cardsettings?currentCulture=${currentCulture}`
            );

            eventCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEvents = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchEventsListPayload = {
            keyword,
            dateFrom,
            dateTo,
            eventType,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response = await axios.post<FetchEventsListResponse>(
                '/api/events',
                payload
            );
            eventCards = response.data.items;
            if (eventCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                eventCards,
                fetchEvents
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        eventsGrid.innerHTML = '';

        eventCards.forEach((card: EventListCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'events-card flex fade-in';

            var _class = '';
            if (IsFavourited(card.id, favouritedIds)) {
                _class = 'active';
            }

            cardElement.innerHTML = `
        <div class="events-card__image-container">
          <img src="${card.image}" alt="" class="events-card__image">
        </div>
        <div class="events-card__content flex flex--column">
          <div class="events-card__buttons flex flex--align-center flex--justify-end">
          ${
              IsUserLoggedIn()
                  ? `<button class="button button--favourite-small favouriteButton events-card__button flex flex--justify-center flex--align-center ${_class}" data-type="event" data-id="${card.id}">
                    </button>`
                  : ''
          }
          
          <button class="button  button--round-small shareButton events-card__button events-card__button-share  flex flex--justify-center flex--align-center">
            <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
          </button>

          <button class="button  button--round-small calButton events-card__button events-card__button-calendar  flex flex--justify-center flex--align-center"
            data-cal-name="${card.title}"
            data-cal-description=""
            data-cal-location="${card.location}"
            data-cal-start="${card.start_Date_iso}"
            data-cal-end="${card.end_Date_iso}">
            <img src="/assets/img/img/calendar-add.svg" alt="calendar icon" class="button__image">
          </button>

          </div>
            <h3 class="events-card__title">${card.title}</h3>
            <div class="events-card__bottom flex flex--wrap flex--justify-between flex--align-center">
    
            <div class="events-card__column flex flex--column">
            <p class="events-card__column-title">${eventCardSettings.dateFromText}</p>
            <p class="events-card__column-text">${formatDateString(card.start_Date)}</p>
          </div>

          <div class="events-card__column flex flex--column">
            <p class="events-card__column-title">${eventCardSettings.dateToText}</p>
            <p class="events-card__column-text">${formatDateString(card.end_Date)}</p>
          </div>
          ${
              card.location !== ''
                  ? `<div class="events-card__column flex flex--column">
                      <p class="events-card__column-title">${eventCardSettings.locationText}</p>
                      <p class="events-card__column-text">${card.location}</p>
                    </div>`
                  : ''
          }
          </div>
        </div>
        `;

            eventsGrid.appendChild(cardElement);
        });
        addCardListeners();
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        eventTabsComponent.classList.add('disabled');
        eventsFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        eventTabsComponent.classList.remove('disabled');
        eventsFilters.classList.remove('disabled');
    };

    // Listeners
    eventTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();
            eventTabs[activeTab].classList.add('events-list__tab--active');
            pageNumber = 1;

            if (index === 0) {
                eventType = 3;
            } else if (index === 1) {
                eventType = 1;
            } else if (index === 2) {
                eventType = 2;
            }

            fetchEvents();
        });
    });

    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchEvents();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchEvents();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchEvents();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchEvents();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchEvents();
    });

    let timeout = null;
    eventsListSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = eventsListSearchInput.value.trim();
            pageNumber = 1;
            fetchEvents();
        }, 1000);
    });

    // eventDateFrom.addEventListener('change', () => {
    //     dateFrom = eventDateFrom.value;
    //     fetchEvents();
    // });

    // eventDateTo.addEventListener('change', () => {
    //     dateTo = eventDateTo.value;
    //     fetchEvents();
    // });

    radios.forEach((radio) => {
        // 1 = Availble for registration
        // 2 = closing soon
        if (radio.value === sortBy.toString()) {
            radio.checked = true;
        } else {
            radio.checked = false;
        }
        radio.addEventListener('change', () => {
            const checkedRadio = eventsList.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchEvents();
        });
    });

    const initializeEventsList = async () => {
        await fetchEventCardSettings();
        await fetchEvents();
    };

    // Call the initialize function
    initializeEventsList();

    const addCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = eventsList.querySelectorAll('.events-card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.events-card__button');

            serviceListCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    console.log('small button clicked - events list');
                    if (button.classList.contains('favouriteButton')) {
                        const t = button.getAttribute('data-type');
                        var id = Number(button.getAttribute('data-id'));
                        saveOrRemoveFavourite(t, id, button);
                    } else if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    } else if (button.classList.contains('calButton')) {
                        addToCalendar(button);
                    }
                });
            });
        }
    };
}

const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');

if (shareButtons && shareButtons.length > 0) {
    shareButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
            copyToClipboard(button);
        });
    });
}
