const mobileMenu = document.querySelector(".menu-mobile__container") as HTMLElement

if (mobileMenu) {
  const mobileMenuIcon = mobileMenu.querySelector(".menu-mobile__icon") as HTMLElement
  const mobileMenuIconContainer = mobileMenu.querySelector(".menu-mobile__icon-container") as HTMLElement
  const mobileMenuButton = mobileMenu.querySelector(".menu-mobile__icon-container") as HTMLElement
  const mobileMenuOverlay = mobileMenu.querySelector(".menu-mobile__overlay") as HTMLElement
  const mobileMenuClose = mobileMenu.querySelector(".menu-mobile__close") as HTMLElement
  const accordions: NodeListOf<HTMLDivElement> = mobileMenu.querySelectorAll(".menu-mobile__accordion");
  const chevrons: NodeListOf<HTMLImageElement> = mobileMenu.querySelectorAll(".menu-mobile__chevron");
  const html = document.querySelector("html") as HTMLElement
  const dir = document.querySelector("html").getAttribute("dir") as string;
  const title = mobileMenu.querySelector(".menu-mobile__title") as HTMLHeadingElement;

  const openMenu = () => {
    mobileMenuIcon.classList.add("menu-mobile__icon--open")
    mobileMenuOverlay.classList.add("menu-mobile__overlay--active")
    html.style.overflow = "hidden"
  }

  const closeMenu = () => {
    mobileMenuIcon.classList.remove("menu-mobile__icon--open")
    mobileMenuOverlay.classList.remove("menu-mobile__overlay--active")
    setTimeout(() => {
      closeAllAccordions()
    }, 500)
    html.style.overflow = "auto";
  }

  const closeAllAccordions = () => {
    accordions.forEach(acc => {
      const pan = acc.nextElementSibling as HTMLDivElement;
      pan.style.maxHeight = "";
      pan.classList.remove("menu-mobile__accordion-panel--active");
      acc.classList.remove("menu-mobile__accordion--active");
    });
  }

  const handleAccordionClick = (element) => {
    const panel = element.nextElementSibling as HTMLDivElement;
    const isActive = element.classList.contains("menu-mobile__accordion--active");

    closeAllAccordions()

    if (!isActive) {
      panel.classList.add("menu-mobile__accordion-panel--active");
      panel.style.maxHeight = panel.scrollHeight + "px";
      element.classList.add("menu-mobile__accordion--active");
    }
  };

  mobileMenuIconContainer.addEventListener("click", e => {
    if (mobileMenuIcon.classList.contains("menu-mobile__icon--open")) {
      closeMenu()
    } else {
      openMenu()
    }
  })

  mobileMenuButton.addEventListener("keydown", e => {
    if (e.key === "Enter") {
      if (mobileMenuIcon.classList.contains("menu-mobile__icon--open")) {
        closeMenu()
      } else {
        openMenu()
      }
    }
  })

  mobileMenuOverlay.addEventListener("keydown", e => {
    if (e.key === "Escape") {
      closeMenu()
    }
  })

  mobileMenuOverlay.addEventListener("click", e => {
    const targetElement = e.target as HTMLElement;

    if (targetElement.className === "menu-mobile__overlay menu-mobile__overlay--active") {
      closeMenu()
    }
  })

  chevrons.forEach((chevron) => {
    chevron.addEventListener("keydown", (e) => {

      if (e.key === "Enter") {
        handleAccordionClick(chevron.parentElement)
      }
    })
  })

  mobileMenuClose.addEventListener("click", e => {
    closeMenu()
  })

  mobileMenuClose.addEventListener("keydown", e => {
    if (e.key === "Enter") {
      closeMenu()
    }
  })

  accordions.forEach((accordion) => {
    accordion.addEventListener("click", function (this: HTMLElement) {
      if (this.nextElementSibling.classList.contains("menu-mobile__accordion-panel")) {
        handleAccordionClick(this)
      }
    });
  });
}