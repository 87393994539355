/**
 * Toggles the presence of an favourited item in a local storage-based favourites list.
 * If the item is not already in the list for the specified type, it adds it;
 * if it is in the list, it removes it.
 *
 * @param type The category of the item to be added or removed from favourites.
 * @param id The unique identifier of the item to be toggled in the favourites list.
 */
import axios from 'axios';

export default function saveOrRemoveFavourite(type: string, id: number, button: HTMLButtonElement): void {
    try {
        axios.post<boolean>(`/api/favourites?pageId=${id}&type=${type}`).then((response) => {
            console.log(response.data);
            if (response.data) {
                if (button != null) {
                    button.classList.add('active');
                }
            } else {
                if (button != null) {
                    button.classList.remove('active');
                }
            }
        });
    } catch (error) {
        console.error(error);
    }
}
