import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import { updatePaginationButtons } from '../pagination/pagination';
import { ResultItem } from '../serviceResults/serviceResults.types';
import {
    FetchIsefWinnersStudentsListItemsPayload,
    FetchIsefWinnersStudentsListItemsResponse,
    FetchIsefWinnersStudentsTableColumnsNamesResponse,
} from './isefComponent.types';
import axios from 'axios';

const isef = document.querySelector('.isef') as HTMLDivElement;

if (isef) {
    const eventTabs: NodeListOf<HTMLButtonElement> = isef.querySelectorAll('.isef__tab');
    const sections: NodeListOf<HTMLElement> = isef.querySelectorAll('.isef__section');
    const winnersSection: HTMLDivElement = isef.querySelector('.isef__mega-table-wrapper');
    let activeTab: number = 0;

    const resetTabs = () => {
        eventTabs.forEach((tab) => tab.classList.remove('isef__tab--active'));
        sections.forEach((tab) => tab.classList.remove('isef__section--active'));
    };

    eventTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();

            eventTabs[activeTab].classList.add('isef__tab--active');
            sections[activeTab].classList.add('isef__section--active');
        });
    });

    sections.forEach((section) => {
        const accordion = section.firstElementChild as HTMLDetailsElement;
        if (accordion != null) {
            if (accordion.classList.contains('isef__accordion')) {
                accordion.setAttribute('open', 'true');
            }
            const accordions = section.querySelectorAll('.isef__accordion') as NodeListOf<HTMLDetailsElement>;
            accordions.forEach((childAccordion) => {
                childAccordion.addEventListener('toggle', () => {
                    if (childAccordion.open) {
                        accordions.forEach((otherAccordion) => {
                            if (otherAccordion !== childAccordion) {
                                otherAccordion.removeAttribute('open');
                            }
                        });
                    }
                });
            });
        }
    });

    const favButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.favouriteButton');
    if (favButtons && favButtons.length > 0) {
        favButtons.forEach((button) => {
            const dataIdAttr: string | null = button.getAttribute('data-id');
            const type: string | null = button.getAttribute('data-type');
            const favouritedIds: number[] = JSON.parse(button.getAttribute('data-ids'));

            if (dataIdAttr !== null && favouritedIds != null) {
                const dataId: number = parseInt(dataIdAttr);

                if (IsFavourited(dataId, favouritedIds)) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }

                button.addEventListener('click', (e) => {
                    console.log('small button clicked - program details');
                    saveOrRemoveFavourite(type, dataId, button);
                });
            }
        });
    }

    if (winnersSection) {
        const studentsTableBody = isef.querySelector('.isef__mega-table-body') as HTMLDivElement;
        const studentsMobileTableBody = isef.querySelector('.isef__mega-table-mobile-body') as HTMLDivElement;
        const nextPageButton = isef.querySelector('.pagination__page-next') as HTMLButtonElement;
        const prevPageButton = isef.querySelector('.pagination__page-prev') as HTMLButtonElement;
        const paginationDropdown = isef.querySelector('.pagination__dropdown') as HTMLSelectElement;
        const paginationLast = isef.querySelector('.pagination__page-last') as HTMLSelectElement;
        const paginationFirst = isef.querySelector('.pagination__page-first') as HTMLSelectElement;
        const pagination = isef.querySelector('.pagination') as HTMLDivElement;
        const radios: NodeListOf<HTMLInputElement> = isef.querySelectorAll('.sort-filter__input');
        const isefFilters = isef.querySelector('.isef__filters') as HTMLDivElement;
        const isefSearchInput = isef.querySelector('.isef__search-input') as HTMLInputElement;
        const dropdownFilters: NodeListOf<HTMLDivElement> = isef.querySelectorAll('.dropdown-filter');
        const currentCulture: string = document.documentElement.getAttribute('culture');
        const parentNodeId = winnersSection.getAttribute('data-id');

        let isefWinnersItems: ResultItem[] = [];
        let keyword = '';
        let educationDepartments = [];
        let positions = [];
        let years = [];
        let sortBy = 1;
        let pageNumber = 1;
        let pageSize = +paginationDropdown.value;
        let totalPages = 0;
        let totalCount: number = 0;
        let hasNextPage = false;
        let hasPreviousPage = false;
        let buttonsToShow: number = 3;
        let isLoading = false;
        let clickedNumber = null;

        let isefWinnersTableColumnsNames: FetchIsefWinnersStudentsTableColumnsNamesResponse[] = [];

        const fetchIsefWinnersTableColumnsNames = async () => {
            try {
                const response = await axios.get<FetchIsefWinnersStudentsTableColumnsNamesResponse[]>(
                    `/api/services/results/columnsNames/${parentNodeId}?currentCulture=${currentCulture}`
                );

                isefWinnersTableColumnsNames = response.data;
                console.log(isefWinnersTableColumnsNames);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchIsefWinnersResultsList = async (page = null) => {
            clickedNumber = page;
            pagination.classList.add('hidden');

            if (clickedNumber) {
                pageNumber = page;
            }

            const payload: FetchIsefWinnersStudentsListItemsPayload = {
                parentNodeId,
                keyword,
                educationDepartments,
                positions,
                years,
                sortBy,
                pageNumber,
                pageSize,
                currentCulture,
            };

            isLoading = true;
            disableInputs();

            try {
                let response;
                if (
                    (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                    window.location.hostname === 'mawhiba-fractal.netlify.app'
                ) {
                    response = await axios.post<FetchIsefWinnersStudentsListItemsResponse>(
                        'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/services/results',
                        payload
                    );
                } else {
                    response = await axios.post<FetchIsefWinnersStudentsListItemsResponse>(
                        '/api/services/results',
                        payload
                    );
                }
                isefWinnersItems = response.data.items;
                if (isefWinnersItems.length > 0) {
                    pagination.classList.remove('hidden');
                }
                totalPages = response.data.totalPages;
                totalCount = response.data.totalCount;
                hasNextPage = response.data.hasNextPage;
                hasPreviousPage = response.data.hasPreviousPage;
            } catch (error) {
                console.error(error);
                enableInputs();
            } finally {
                updatePaginationButtons(
                    totalPages,
                    hasPreviousPage,
                    hasNextPage,
                    pageNumber,
                    pageSize,
                    totalCount,
                    buttonsToShow,
                    isefWinnersItems,
                    fetchIsefWinnersResultsList
                );
                isLoading = false;
                updateItems();
                enableInputs();
            }
        };

        const disableInputs = () => {
            pagination.classList.add('disabled');
            isefFilters.classList.add('disabled');
        };

        const enableInputs = () => {
            pagination.classList.remove('disabled');
            isefFilters.classList.remove('disabled');
        };

        const updateItems = () => {
            studentsTableBody.innerHTML = '';
            studentsMobileTableBody.innerHTML = '';

            isefWinnersItems.forEach((item: ResultItem) => {
                const rowElement = document.createElement('tr');
                rowElement.className = 'isef__mega-table-row';

                rowElement.innerHTML = `
                ${
                    isefWinnersTableColumnsNames.some((item) => item.key.toLowerCase() === 'student/team name')
                        ? `<td class="isef__mega-table-data">
                                <div class="isef__mega-table-content flex flex--align-center">
                                    <div class="isef__mega-table-images"> </div>
                                    <span class="isef__mega-table-content-title">${item.students[0].name}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    isefWinnersTableColumnsNames.some((item) => item.key.toLowerCase() === 'project title')
                        ? `<td class="isef__mega-table-data">
                                <div
                                    class="isef__mega-table-content flex flex--align-center">
                                    <span class="isef__mega-table-content-title">${item.projectTitle}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    isefWinnersTableColumnsNames.some((item) => item.key.toLowerCase() === 'education department')
                        ? `<td class="isef__mega-table-data">
                                <div
                                    class="isef__mega-table-content flex flex--align-center">
                                    <span class="isef__mega-table-content-title">${item.educationDepartment}</span>
                                </div>
                            </td>`
                        : ''
                }

                ${
                    isefWinnersTableColumnsNames.some((item) => item.key.toLowerCase() === 'school')
                        ? `<td class="isef__mega-table-data">
                                <div
                                    class="isef__mega-table-content flex flex--align-center">
                                    <span class="isef__mega-table-content-title">${item.school}</span>
                                </div>
                            </td>`
                        : ''
                }
                ${
                    isefWinnersTableColumnsNames.some((item) => item.key.toLowerCase() === 'search entity')
                        ? `<td class="isef__mega-table-data">
                                <div
                                    class="isef__mega-table-content flex flex--align-center">
                                    <span class="isef__mega-table-content-title">${
                                        item.searchEntity !== null ? item.searchEntity : '-'
                                    }</span>
                                </div>
                            </td>`
                        : ''
                }
                
                ${
                    isefWinnersTableColumnsNames.some((item) => item.key.toLowerCase() === 'prize')
                        ? `<td class="isef__mega-table-data">
                                <div
                                    class="isef__mega-table-content flex flex--align-center isef__mega-table-content-icon">
                                    <img src="/assets/img/img/icons/medal-star.svg" alt="icon" class="isef__mega-table-icon">
                                    <span class="isef__mega-table-content-title">${item.position}</span>
                                </div>
                            </td>`
                        : ''
                }`;

                var images = rowElement.querySelector('.isef__mega-table-images') as HTMLDivElement;

                if (images) {
                    item.students
                        .filter((std) => std.image !== null && std.image !== undefined && std.image !== '')
                        .forEach((student) => {
                            const imageItem = document.createElement('img');
                            imageItem.alt = 'student image';
                            imageItem.src = student.image;
                            imageItem.classList.add('isef__mega-table-image');
                            images.appendChild(imageItem);
                        });
                }

                addMobileRow(item);

                studentsTableBody.appendChild(rowElement);
            });
        };

        const addMobileRow = (item: ResultItem) => {
            const studentName = isefWinnersTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'student/team name',
            );
            const projectTitle = isefWinnersTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'project title',
            );
            const educationDepartment = isefWinnersTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'education department',
            );
            const school = isefWinnersTableColumnsNames.find((item) => item.key.toLowerCase() === 'school');
            const searchEntity = isefWinnersTableColumnsNames.find(
                (item) => item.key.toLowerCase() === 'search entity'
            );
            const prize = isefWinnersTableColumnsNames.find((item) => item.key.toLowerCase() === 'prize');

            studentsMobileTableBody.insertAdjacentHTML(
                'beforeend',
                `
        ${
            studentName !== undefined
                ? `<tr class="isef__mega-table-row">
                        <th class="isef__mega-table-header">${studentName.value}</th>
                        <td class="isef__mega-table-data">
                            <div class="isef__mega-table-content flex flex--align-center">
                                <div class="isef__mega-table-images"> </div>
                                <span class="isef__mega-table-content-title">${item.students[0].name}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            projectTitle !== undefined
                ? `<tr class="isef__mega-table-row">
                        <th class="isef__mega-table-header">${projectTitle.value}</th>
                        <td class="isef__mega-table-data">
                            <div class="isef__mega-table-content flex flex--align-center">
                                <span class="isef__mega-table-content-title">${item.projectTitle}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            educationDepartment !== undefined
                ? `<tr class="isef__mega-table-row">
                        <th class="isef__mega-table-header">${educationDepartment.value}</th>
                        <td class="isef__mega-table-data">
                            <div class="isef__mega-table-content flex flex--align-center">
                                <span class="isef__mega-table-content-title">${item.educationDepartment}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            prize !== undefined
                ? `<tr class="isef__mega-table-row">
                        <th class="isef__mega-table-header">${prize.value}</th>
                        <td class="isef__mega-table-data">
                            <div class="isef__mega-table-content flex flex--align-center isef__mega-table-content-icon">
                                <img src="/assets/img/img/icons/medal-star.svg" alt="alt" class="isef__mega-table-icon" />
                                <span class="isef__mega-table-content-title">${item.position}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            school !== undefined
                ? `<tr class="isef__mega-table-row">
                        <th class="isef__mega-table-header">${school.value}</th>
                        <td class="isef__mega-table-data">
                            <div class="isef__mega-table-content flex flex--align-center">
                                <span class="isef__mega-table-content-title">${item.school}</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }

        ${
            searchEntity !== undefined
                ? `<tr class="isef__mega-table-row">
                        <th class="isef__mega-table-header">${searchEntity.value}</th>
                        <td class="isef__mega-table-data">
                            <div class="isef__mega-table-content flex flex--align-center">
                                <span class="isef__mega-table-content-title">${
                                    item.searchEntity !== null ? item.searchEntity : '-'
                                }</span>
                            </div>
                        </td>
                    </tr>`
                : ''
        }`
            );
        };

        paginationDropdown.addEventListener('change', () => {
            pageSize = +paginationDropdown.value;
            pageNumber = 1;
            fetchIsefWinnersResultsList();
        });

        nextPageButton.addEventListener('click', () => {
            pageNumber++;

            fetchIsefWinnersResultsList();
        });

        prevPageButton.addEventListener('click', () => {
            pageNumber--;

            fetchIsefWinnersResultsList();
        });

        paginationFirst.addEventListener('click', () => {
            pageNumber = 1;
            fetchIsefWinnersResultsList();
        });

        paginationLast.addEventListener('click', () => {
            pageNumber = totalPages;
            fetchIsefWinnersResultsList();
        });

        let timeout = null;
        isefSearchInput.addEventListener('input', (e) => {
            clearTimeout(timeout);

            timeout = setTimeout(function () {
                keyword = isefSearchInput.value.trim();
                pageNumber = 1;
                fetchIsefWinnersResultsList();
            }, 1000);
        });

        radios.forEach((radio) => {
            // 1 = Lowest
            // 2 = Highest

            radio.addEventListener('change', () => {
                const checkedRadio = isef.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
                const checkedRadioValue = checkedRadio.value;
                sortBy = +checkedRadioValue;
                fetchIsefWinnersResultsList();
            });
        });

        // Observe changes in dropdown filters
        dropdownFilters.forEach((dropdown, index) => {
            const optionsContainer =
                (dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement) || null;

            if (optionsContainer) {
                const config: MutationObserverInit = { childList: true };

                const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            switch (index) {
                                case 0:
                                    const educationDepartmentsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        educationDepartmentsOptions.push(element.textContent);
                                    });
                                    educationDepartments = educationDepartmentsOptions;
                                    break;
                                case 1:
                                    const positionsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        positionsOptions.push(element.textContent);
                                    });
                                    positions = positionsOptions;
                                    break;
                                case 2:
                                    const yearsOptions = [];
                                    Array.from(optionsContainer.children).forEach((element) => {
                                        yearsOptions.push(element.textContent);
                                    });
                                    years = yearsOptions;
                                    break;
                                default:
                                    break;
                            }

                            pageNumber = 1;
                            fetchIsefWinnersResultsList();
                        }
                    }
                };

                const observer: MutationObserver = new MutationObserver(callback);

                observer.observe(optionsContainer, config);
            }
        });

        fetchIsefWinnersTableColumnsNames();
        fetchIsefWinnersResultsList();
    }
}
