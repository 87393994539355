import formatDateString from '../../../helpers/formatDate';
import convertTime from '../../../helpers/formatTime';

const serviceTimelineTwo = document.querySelector(".service-timeline-two") as HTMLElement;
const html = document.querySelector("html") as HTMLElement;
const dir = html.getAttribute("dir") as string;

if (serviceTimelineTwo) {
  const dates: NodeListOf<HTMLElement> = serviceTimelineTwo.querySelectorAll(".service-timeline-two__table-content-date");
  const times: NodeListOf<HTMLElement> = serviceTimelineTwo.querySelectorAll(".service-timeline-two__table-content-time");
  const tableRows: NodeListOf<HTMLElement> = serviceTimelineTwo.querySelectorAll(".service-timeline-two__table-row");
  const datesTriggers: NodeListOf<HTMLElement> = serviceTimelineTwo.querySelectorAll(".service-timeline-two__table-content-dates-multi");
  const innerTables: NodeListOf<HTMLElement> = serviceTimelineTwo.querySelectorAll(".service-timeline-two__table-inner");

  dates.forEach(date => {
    date.textContent = formatDateString(date.textContent)
  })

  times.forEach(time => {
    time.textContent = convertTime(time.textContent, dir)
  })

  tableRows.forEach(row => {
    const height = row.clientHeight as Number;

    row.setAttribute("data-height", height.toString())
  })

  datesTriggers.forEach((trigger, index) => {
    trigger.addEventListener("click", (e) => {
      const innerTable = innerTables[index] as HTMLElement;
      const icon = trigger.querySelector(".service-timeline-two__table-content-chevron") as HTMLElement;

      if (innerTable.classList.contains("service-timeline-two__table-inner--active")) {
        innerTable.classList.remove("service-timeline-two__table-inner--active");
        icon.classList.remove("service-timeline-two__table-content-chevron--rotate")

        const activeRow = trigger.parentElement.parentElement.parentElement as HTMLElement;
        const activeRowHeight = activeRow.getAttribute("data-height") as String;

        activeRow.style.height = `${activeRowHeight}px`;
        innerTable.style.top = "0";
      } else {
        innerTable.classList.add("service-timeline-two__table-inner--active");
        icon.classList.add("service-timeline-two__table-content-chevron--rotate")

        const activeRow = trigger.parentElement.parentElement.parentElement as HTMLElement;
        const activeRowHeight = activeRow.clientHeight;
        const innerTableHeight = innerTable.clientHeight;
        const margin = 16;

        activeRow.style.height = `${innerTableHeight + activeRowHeight + margin}px`;
        innerTable.style.top = `${activeRowHeight}px`;
      }
    })
  })
}