const filters: NodeListOf<HTMLDivElement> = document.querySelectorAll('.single-dropdown-filter');
const direction: string = document.querySelector('html').getAttribute('dir');

filters.forEach((item) => {
    // get select that has the options available
    const selects: NodeListOf<HTMLSelectElement> = item.querySelectorAll('.single-select');
    selects.forEach((select) => {
        init(select);
    });

    document.addEventListener('click', (e) => {
        // get select that has the options available
        const selects: NodeListOf<HTMLSelectElement> = document.querySelectorAll('.single-select');
        const triggers = document.querySelectorAll('.single-dropdown-filter__trigger');

        for (let i = 0; i < selects.length; i++) {
            if (e) {
                var isClickInside = selects[i].parentElement.parentElement.contains(e.target as Node);

                if (!isClickInside) {
                    const wrapper: HTMLDivElement = selects[i].parentElement.parentElement as HTMLDivElement;
                    const dropdown = wrapper.querySelector('.single-dropdown-filter__dropdown-icon');
                    const autocomplete_list = wrapper.querySelector('.single-dropdown-filter__autocomplete-list');
                    //the click was outside the specifiedElement, do something
                    dropdown.classList.remove('active');
                    const multiSelects = document.querySelectorAll('.single-dropdown-filter__multi-select-container');
                    multiSelects[i].classList.remove('single-dropdown-filter__multi-select-container--active');
                    triggers[i].classList.remove('single-dropdown-filter__trigger--active');
                    autocomplete_list.innerHTML = '';
                    addPlaceholder(wrapper);
                }
            }
        }
    });

    // Show dropdown on trigger click
    const trigger = item.querySelector('.single-dropdown-filter__trigger');

    trigger.addEventListener('click', handleTrigger);
    trigger.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleTrigger(e);
        }
    });

    // FUNCTIONS
    function handleTrigger(e) {
        const wrapper = item.querySelector('.single-dropdown-filter__multi-select-container');

        if (wrapper.classList.contains('single-dropdown-filter__multi-select-container--active')) {
            wrapper.classList.remove('single-dropdown-filter__multi-select-container--active');
            trigger.classList.remove('single-dropdown-filter__trigger--active');
        } else {
            wrapper.classList.add('single-dropdown-filter__multi-select-container--active');
            trigger.classList.add('single-dropdown-filter__trigger--active');
        }

        const dropdown = wrapper.querySelector('.single-dropdown-filter__dropdown-icon');
        const event = new Event('click');
        dropdown.dispatchEvent(event);
    }

    function init(element: HTMLSelectElement) {
        const filter = item;
        // Create div that wraps all the elements inside (select, elements selected, search div) to put select inside
        const wrapper = document.createElement('div');
        wrapper.addEventListener('click', clickOnWrapper);
        wrapper.classList.add('single-dropdown-filter__multi-select-container');

        // Create elements of search
        const search_div = document.createElement('div');
        search_div.classList.add('single-dropdown-filter__search-container');

        const input = document.createElement('input');
        input.classList.add('selected-input');
        input.setAttribute('autocomplete', 'off');
        input.setAttribute('tabindex', '0');
        input.addEventListener('keyup', inputChange);
        input.addEventListener('click', openOptions);

        const dropdown_icon = document.createElement('a');
        dropdown_icon.classList.add('single-dropdown-filter__dropdown-icon');

        dropdown_icon.addEventListener('click', clickDropdown);
        const autocomplete_list = document.createElement('ul');
        autocomplete_list.classList.add('single-dropdown-filter__autocomplete-list');

        search_div.appendChild(input);
        search_div.appendChild(autocomplete_list);
        search_div.appendChild(dropdown_icon);

        // set the wrapper as child (instead of the element)
        element.parentNode.replaceChild(wrapper, element);
        // set element as child of wrapper
        wrapper.appendChild(element);
        wrapper.appendChild(search_div);

        addPlaceholder(wrapper);
    }

    function removePlaceholder(wrapper: HTMLDivElement) {
        const input_search = wrapper.querySelector('.selected-input');
        input_search.removeAttribute('placeholder');
    }

    function addPlaceholder(wrapper: HTMLDivElement) {
        const input_search = wrapper.querySelector('.selected-input');
        const tokens = wrapper.querySelectorAll('.single-dropdown-filter__selected-wrapper');
        if (!tokens.length && !(document.activeElement === input_search))
            input_search.setAttribute('placeholder', '---------');
    }

    // Listener of user search
    function inputChange(e) {
        const wrapper = e.target.parentNode.parentNode;
        const select = wrapper.querySelector('select');
        const dropdown = wrapper.querySelector('.single-dropdown-filter__dropdown-icon');

        const input_val = e.target.value;

        if (input_val) {
            dropdown.classList.add('active');
            populateAutocompleteList(select, input_val.trim());
        } else {
            dropdown.classList.remove('active');
            const event = new Event('click');
            dropdown.dispatchEvent(event);
        }
    }

    // Listen for clicks on the wrapper, if click happens focus on the input
    function clickOnWrapper(e) {
        const wrapper = e.target;
        if (wrapper.tagName == 'DIV') {
            const input_search = wrapper.querySelector('.selected-input');
            const dropdown = wrapper.querySelector('.single-dropdown-filter__dropdown-icon');
            if (!dropdown.classList.contains('active')) {
                const event = new Event('click');
                dropdown.dispatchEvent(event);
            }
            input_search.focus();
            removePlaceholder(wrapper);
        }
    }

    function openOptions(e) {
        const input_search = e.target;
        const wrapper = input_search.parentElement.parentElement;
        const dropdown = wrapper.querySelector('.single-dropdown-filter__dropdown-icon');
        if (!dropdown.classList.contains('active')) {
            const event = new Event('click');
            dropdown.dispatchEvent(event);
        }
        e.stopPropagation();
    }

    // Listen for clicks in the dropdown option
    function clickDropdown(e) {
        const dropdown = e.target;
        const wrapper = dropdown.parentNode.parentNode;
        const input_search = wrapper.querySelector('.selected-input');
        const select = wrapper.querySelector('select');
        dropdown.classList.toggle('active');

        if (dropdown.classList.contains('active')) {
            removePlaceholder(wrapper);
            input_search.focus();

            if (!input_search.value) {
                populateAutocompleteList(select, '', true);
            } else {
                populateAutocompleteList(select, input_search.value);
            }
        } else {
            clearAutocompleteList(select);
            addPlaceholder(wrapper);
        }
    }

    // Clears the results of the autocomplete list
    function clearAutocompleteList(select) {
        const wrapper = select.parentNode;
        const autocomplete_list: HTMLUListElement = wrapper.querySelector('.single-dropdown-filter__autocomplete-list');
        autocomplete_list.innerHTML = '';
    }

    // Populate the autocomplete list following a given query from the user
    function populateAutocompleteList(select, query, dropdown = false) {
        const { autocomplete_options, all_options, option_selected } = getOptions(select);

        let options_to_show: Array<any>;

        if (dropdown) options_to_show = autocomplete_options;
        else options_to_show = autocomplete(query, autocomplete_options);

        const wrapper = select.parentNode;
        const autocomplete_list = wrapper.querySelector('.single-dropdown-filter__autocomplete-list');
        autocomplete_list.innerHTML = '';
        const result_size = options_to_show.length;

        if (result_size > 0) {
            for (let i = 0; i < result_size; i++) {
                if (options_to_show[i] === '') {
                    // this is an empty option
                    const emptyli = document.createElement('li');
                    emptyli.innerText = '';
                    emptyli.classList.add('hidden');
                    if (result_size == 1) {
                        const li = document.createElement('li');
                        li.classList.add('not-cursor');
                        if (direction === 'rtl') {
                            li.innerText = 'لم يتم العثور على خيارات';
                        } else {
                            li.innerText = 'No options found';
                        }
                        autocomplete_list.appendChild(li);
                    }
                } else {
                    const li = document.createElement('li');
                    li.innerText = options_to_show[i];
                    li.setAttribute('data-value', options_to_show[i]);
                    li.addEventListener('click', selectOption);
                    autocomplete_list.appendChild(li);
                }
            }
        } else {
            const li = document.createElement('li');
            li.classList.add('not-cursor');
            if (direction === 'rtl') {
                li.innerText = 'لم يتم العثور على خيارات';
            } else {
                li.innerText = 'No options found';
            }
            autocomplete_list.appendChild(li);
        }

        handleListItems(option_selected);
    }

    // Listener to autocomplete results when clicked set the selected property in the select option
    function selectOption(e) {
        const filterComponent = item;

        // get selected options
        const select: HTMLSelectElement = filterComponent.querySelector('.single-select');

        const option_selected: HTMLSelectElement = select.querySelector('option:checked');
        option_selected.removeAttribute('selected');

        const wrapper = e.target.parentNode.parentNode.parentNode;
        const input_search: HTMLInputElement = wrapper.querySelector('.selected-input');
        const option: HTMLOptionElement = wrapper.querySelector(`select option[value="${e.target.dataset.value}"]`);

        option.setAttribute('selected', '');
        select.dispatchEvent(new Event('change'));

        trigger.innerHTML = e.target.dataset.value;

        if (input_search.value) {
            input_search.value = '';
        }

        input_search.focus();

        const autocomplete_list: HTMLUListElement = wrapper.querySelector('.single-dropdown-filter__autocomplete-list');

        if (!autocomplete_list.children.length) {
            const li = document.createElement('li');
            li.classList.add('not-cursor');
            li.innerText = 'No options found';
            autocomplete_list.appendChild(li);
        }

        const event = new Event('keyup');
        input_search.dispatchEvent(event);
        e.stopPropagation();
    }

    // function that returns a list with the autcomplete list of matches
    function autocomplete(query, options) {
        // No query passed, just return entire list
        if (!query) {
            return options;
        }

        let options_return = [];

        for (let i = 0; i < options.length; i++) {
            if (options[i].toLowerCase().includes(query.toLowerCase())) {
                options_return.push(options[i]);
            }
        }

        return options_return;
    }

    // Returns the options that are selected by the user and the ones that are not
    function getOptions(select) {
        // Select all the options available
        const all_options = Array.from(select.querySelectorAll('option')).map((el: HTMLSelectElement) => el.value);

        // Get the options that are selected from the user
        const option_selected: HTMLSelectElement = select.querySelector('option:checked');
        // Create an autocomplete options array with the options that are not selected by the user
        let autocomplete_options = [];
        all_options.forEach((option) => {
            if (option_selected.value !== option) {
                autocomplete_options.push(option);
            }
        });

        autocomplete_options.sort();

        autocomplete_options = all_options;

        return {
            autocomplete_options,
            all_options,
            option_selected: option_selected.value,
        };
    }

    const handleListItems = (option: any) => {
        const list: NodeListOf<HTMLDListElement> = item.querySelectorAll(
            '.single-dropdown-filter__autocomplete-list li'
        );

        list.forEach((item) => {
            if (option === item.textContent) {
                item.classList.add('item--active');
            } else {
                item.classList.remove('item--active');
            }
        });
    };
});
