import {
    FetchNewsListPayload,
    FetchNewsListResponse,
    NewsListCard,
    FetchNewsCardSettingsResponse,
} from './newsList.types';
import formatDateString from '../../../helpers/formatDate';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';

const newsList: HTMLDivElement = document.querySelector('.news-list');

if (newsList) {
    const Grid = newsList.querySelector('.news-list__grid') as HTMLDivElement;
    const Filters = newsList.querySelector('.news-list__search') as HTMLDivElement;
    const ListSearchInput = newsList.querySelector('.news-list__search-input') as HTMLInputElement;
    const DateFrom = newsList.querySelector('.date-range__input-from') as HTMLInputElement;
    const DateTo = newsList.querySelector('.date-range__input-to') as HTMLInputElement;
    const nextPageButton = newsList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = newsList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = newsList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = newsList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = newsList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = newsList.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = newsList.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const dropdownFilters: NodeListOf<HTMLDivElement> = newsList.querySelectorAll('.dropdown-filter');

    let newsCards: NewsListCard[] = [];
    let keyword: string = '';
    let dateFrom: string = '';
    let dateTo: string = '';
    let userTypes: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    let newsCardSettings: FetchNewsCardSettingsResponse = {
        Text: '',
    };

    // Functions
    const fetchNewsCardSettings = async () => {
        try {
            const response = await axios.get<FetchNewsCardSettingsResponse>(
                `/api/news/cardsettings?currentCulture=${currentCulture}`
            );

            newsCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchNews = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchNewsListPayload = {
            keyword,
            dateFrom,
            dateTo,
            userTypes,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchNewsListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/news',
                    payload
                );
            } else {
                response = await axios.post<FetchNewsListResponse>('/api/news', payload);
            }
            newsCards = response.data.items;
            if (newsCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                newsCards,
                fetchNews
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        Grid.innerHTML = '';

        newsCards.forEach((card: NewsListCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.className = 'news__card flex flex--column';

            cardElement.innerHTML = `            
            <img src='${card.image}' alt='${card.title}' class='news__card-image' />
            <p class='news__card-title'>${card.title}</p>
            <p class='news__card-date flex flex--align-center'>
                <img src='/assets/img/img/calendar-icon-yellow.svg' alt='calendar icon' class='news__card-icon' />
                <span class='news__card-text'>
                    ${formatDateString(card.date)}
                </span>
            </p>
            `;

            Grid.appendChild(cardElement);
        });
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        Filters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        Filters.classList.remove('disabled');
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchNews();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;

        fetchNews();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;

        fetchNews();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchNews();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchNews();
    });

    let timeout = null;
    ListSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = ListSearchInput.value.trim();
            pageNumber = 1;
            fetchNews();
        }, 1000);
    });

    DateFrom.addEventListener('change', () => {
        dateFrom = DateFrom.value;
        fetchNews();
    });

    DateTo.addEventListener('change', () => {
        dateTo = DateTo.value;
        fetchNews();
    });

    radios.forEach((radio) => {
        // 1 = Latest
        // 2 = A-Z
        // 3 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = newsList.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchNews();
        });
    });

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const userTypesOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    userTypesOptions.push(element.textContent);
                                });
                                userTypes = userTypesOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchNews();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });
    
    const initializeEventsList = async () => {
        await fetchNewsCardSettings();
        await fetchNews();
    };

    // Call the initialize function
    initializeEventsList();

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".shareButton");
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            copyToClipboard(button);                 
        });   
        })
    }
}
