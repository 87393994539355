import {
    FetchScienceCardListPayload,
    FetchScienceCardListResponse,
    ScienceCard,
    FilterItem,
    Filters,
} from './scientificPublications.types';
import { updatePaginationButtons } from '../pagination/pagination';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { initStarRatings } from '../starRatings/starRatings';

const list: HTMLDivElement = document.querySelector('.science-pubs__wrapper');

if (list) {
    const grid = list.querySelector('.science-pubs__grid') as HTMLDivElement;

    const filters = list.querySelector('.science-pubs__filter-content') as HTMLDivElement;
    const resultsnumber = list.querySelector('.science-pubs__number') as HTMLSpanElement;
    const filterButton = list.querySelector('.science-pubs__button') as HTMLButtonElement;
    const filterCount = list.querySelector('.science-pubs__button-count-content') as HTMLDivElement;

    const listSearchInput = list.querySelector('.science-pubs__search-input') as HTMLInputElement;
    const publicationsAuthorsDiv = list.querySelector('.science-pubs__sub-filters') as HTMLDivElement;
    const publicationsFileTypesDiv = list.querySelector('.science-pubs__summary-filetypes') as HTMLDivElement;
    const nextPageButton = list.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = list.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = list.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = list.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = list.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = list.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = list.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');
    const pageId = Number(list.getAttribute('data-id'));

    const checkboxFiltersViews: NodeListOf<HTMLInputElement> = list.querySelectorAll('.science-pubs__view-checkbox');

    let scienceCards: ScienceCard[] = [];
    let scienceCardsAll: ScienceCard[] = [];
    let publicationsAuthors: FilterItem[] = [];
    let publicationsFileTypes: FilterItem[] = [];
    let keyword: string = '';
    let authors: string[] = [];
    let views: string[] = [];
    let ratings: string[] = [];
    let fileTypes: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const addPageView = async (pageViewedId: number) => {
        try {
            await axios.post<boolean>(`/api/pageview?pageId=${pageViewedId}`);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchPublicationsFilters = async () => {
        try {
            const payload: FetchScienceCardListPayload = {
                pageId,
                keyword,
                authors,
                views,
                ratings,
                fileTypes,
                sortBy,
                pageNumber,
                pageSize,
                currentCulture,
            };

            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<Filters>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/scientificpublications/filters',
                    payload
                );
            } else {
                response = await axios.post<Filters>('/api/scientificpublications/filters', payload);
            }

            publicationsAuthors = response.data.authors;
            publicationsFileTypes = response.data.fileTypes;
        } catch (error) {
            console.error(error);
        } finally {
            updateFilters();
            updateNumberCards();
        }
    };

    const updateFilters = () => {
        publicationsAuthorsDiv.innerHTML = '';

        publicationsAuthors.forEach((card: FilterItem) => {
            const cardElement = document.createElement('summary');

            cardElement.innerHTML = `
                    <div class="science-pubs__sub-filters-text flex flex--align-center">
                        <input type="checkbox" id="author-${card.id}" value="${card.name}"
                            class="science-pubs__sub-filters-text-checkbox science-pubs__category-checkbox">
                        <label for="author-${card.id}"
                            class="science-pubs__sub-filters-text-name flex flex--align-center">
                            <span class="science-pubs__sub-filters-span">
                                ${card.name}
                            </span>
                            <span
                                class="science-pubs__sub-filters-text-number">${card.count}</span>
                        </label>
                    </div>
            `;

            publicationsAuthorsDiv.appendChild(cardElement);
        });

        const checkboxFiltersAuthors: NodeListOf<HTMLInputElement> = list.querySelectorAll(
            '.science-pubs__category-checkbox'
        );
        if (checkboxFiltersAuthors.length > 0) {
            checkboxFiltersAuthors.forEach((checkbox) => {
                checkbox.addEventListener('change', () => {
                    if (authors.includes(checkbox.value)) {
                        authors = authors.filter((cat) => cat !== checkbox.value);
                    } else {
                        authors.push(checkbox.value);
                    }

                    fetchPublications();
                    fetchAllPublications();
                });
            });
        }

        publicationsFileTypesDiv.innerHTML = '';

        publicationsFileTypes.forEach((card: FilterItem) => {
            const cardElement = document.createElement('div');

            cardElement.className = 'science-pubs__sub-filters-text flex flex--align-center';

            cardElement.innerHTML = `
                    <input type="checkbox" id="filetype-${card.name}" value="${card.name}"
                        class="science-pubs__sub-filters-text-checkbox science-pubs__filetype-checkbox">
                        <label for="filetype-${card.name}"
                            class="science-pubs__sub-filters-text-name flex flex--align-center">
                        <span>
                            ${card.name}
                        </span>
                        <span class="science-pubs__sub-filters-text-number">${card.count}</span>
                    </label>
            `;

            publicationsFileTypesDiv.appendChild(cardElement);
        });

        const checkboxFiltersFileTypes: NodeListOf<HTMLInputElement> = list.querySelectorAll(
            '.science-pubs__filetype-checkbox'
        );
        if (checkboxFiltersFileTypes.length > 0) {
            checkboxFiltersFileTypes.forEach((checkbox) => {
                checkbox.addEventListener('change', () => {
                    if (fileTypes.includes(checkbox.value)) {
                        fileTypes = fileTypes.filter((cat) => cat !== checkbox.value);
                    } else {
                        fileTypes.push(checkbox.value);
                    }

                    fetchPublications();
                    fetchAllPublications();
                });
            });
        }
    };

    const fetchPublications = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchScienceCardListPayload = {
            pageId,
            keyword,
            authors,
            views,
            ratings,
            fileTypes,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        const fCount = authors.length + views.length;
        filterCount.innerHTML = fCount.toString();

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchScienceCardListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/scientificpublications',
                    payload
                );
            } else {
                response = await axios.post<FetchScienceCardListResponse>('/api/scientificpublications', payload);
            }
            scienceCards = response.data.items;
            if (scienceCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;

            resultsnumber.innerHTML = response.data.totalCount;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                scienceCards,
                fetchPublications
            );
            isLoading = false;
            updateCards();
            updateNumberCards();
            enableInputs();
        }
    }

    const fetchAllPublications = async (page = null) => {
        const payload: FetchScienceCardListPayload = {
            pageId,
            keyword: '',
            authors: [],
            views: [],
            fileTypes: [],
            ratings: [],
            sortBy: 1,
            pageNumber: 1,
            pageSize: -1,
            currentCulture,
        };

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchScienceCardListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/scientificpublications',
                    payload
                );
            } else {
                response = await axios.post<FetchScienceCardListResponse>('/api/scientificpublications', payload);
            }
            scienceCardsAll = response.data.items;
        } catch (error) {
            console.error(error);
        }
    };

    const updateCards = () => {
        grid.innerHTML = '';

        scienceCards.forEach((card: ScienceCard) => {
            const cardElement = document.createElement('div');
            cardElement.className = 'science-card flex flex--column flex--align-start flex--justify-between fade-in';

            cardElement.innerHTML = `
                    <div class="science-card__top flex flex--wrap">
                        <div class="science-card__image-container flex flex--align-center flex--justify-center">
                            <img src=${card.image} alt="publciation image" class="science-card__image">
                        </div>
                        <div class="science-card__content flex flex--column flex--justify-between">
                            ${
                                card.fileType !== null && card.fileType !== ''
                                    ? `<div class='science-card__date flex flex--align-center'>
                                        <img src="/assets/img/fractal/filetype--${card.fileType}.svg" alt="pdf icon" class="science-card__pdf">
                                    </div>`
                                    : ''
                            }
                            <span class="science-card__title">${card.title}</span>
                            <span class="science-card__text">${card.description}</span>
                            <div class="science-card__row flex flex--wrap flex--justify-between flex--align-center">
                                <div class="science-card__row-item flex flex--align-center">
                                    <img src="/assets/img/img/icons/user-tick-article-card.svg" alt="user icon"
                                        class="science-card__row-item-icon">
                                    <span class="science-card__row-item-text">${card.author}</span>
                                </div>
                                <div class="science-card__row-item science-card__row-item-review flex flex--align-center" tabindex="0">
                                    <div class="star-rating-trigger flex flex--justify-center flex--align-center">

                                    ${card.isRated 
                                        ? 
                                        '<svg width="16" height="16" viewBox="0 0 24 24" fill="#e2a947" xmlns="http://www.w3.org/2000/svg"><path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" fill="#e2a947" /></svg>'
                                        :
                                        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.7733 15.1132C11.42 15.1132 10.9667 14.9999 10.4 14.6665L8.40666 13.4865C8.2 13.3665 7.8 13.3665 7.6 13.4865L5.6 14.6665C4.42 15.3666 3.72666 15.0866 3.41333 14.8599C3.10666 14.6332 2.62666 14.0532 2.94 12.7199L3.41333 10.6732C3.46666 10.4599 3.36 10.0932 3.2 9.93322L1.54666 8.27988C0.719997 7.45322 0.786664 6.74655 0.899997 6.39988C1.01333 6.05322 1.37333 5.43988 2.52 5.24655L4.64666 4.89322C4.84666 4.85988 5.13333 4.64655 5.22 4.46655L6.4 2.11322C6.93333 1.03988 7.63333 0.879883 8 0.879883C8.36666 0.879883 9.06666 1.03988 9.6 2.11322L10.7733 4.45988C10.8667 4.63988 11.1533 4.85322 11.3533 4.88655L13.48 5.23988C14.6333 5.43322 14.9933 6.04655 15.1 6.39322C15.2067 6.73988 15.2733 7.44655 14.4533 8.27322L12.8 9.93322C12.64 10.0932 12.54 10.4532 12.5867 10.6732L13.06 12.7199C13.3667 14.0532 12.8933 14.6332 12.5867 14.8599C12.42 14.9799 12.1533 15.1132 11.7733 15.1132ZM8 12.3932C8.32666 12.3932 8.65333 12.4732 8.91333 12.6266L10.9067 13.8065C11.4867 14.1532 11.8533 14.1532 11.9933 14.0532C12.1333 13.9532 12.2333 13.5999 12.0867 12.9466L11.6133 10.8999C11.4867 10.3465 11.6933 9.63322 12.0933 9.22655L13.7467 7.57322C14.0733 7.24655 14.22 6.92655 14.1533 6.70655C14.08 6.48655 13.7733 6.30655 13.32 6.23322L11.1933 5.87988C10.68 5.79322 10.12 5.37988 9.88666 4.91322L8.71333 2.56655C8.5 2.13988 8.23333 1.88655 8 1.88655C7.76666 1.88655 7.5 2.13988 7.29333 2.56655L6.11333 4.91322C5.88 5.37988 5.32 5.79322 4.80666 5.87988L2.68666 6.23322C2.23333 6.30655 1.92666 6.48655 1.85333 6.70655C1.78 6.92655 1.93333 7.25322 2.26 7.57322L3.91333 9.22655C4.31333 9.62655 4.52 10.3465 4.39333 10.8999L3.92 12.9466C3.76666 13.6066 3.87333 13.9532 4.01333 14.0532C4.15333 14.1532 4.51333 14.1465 5.1 13.8065L7.09333 12.6266C7.34666 12.4732 7.67333 12.3932 8 12.3932Z" fill="#2B254B"/></svg>'
                                    }

                                        <span class="star-rating-text">${card.rating}</span>
                                    </div>
                                    <div class="science-card__rating flex flex--align-center reviews">
                                        <div class="star-rating" data-pageid="${card.id}"
                                            data-user-rated="${card.isRated ? 1 : 0}"
                                            data-avg-rating="${card.rating}">
                                        <span class="star" data-value="1">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" fill="#D1D5DB" />
                                            </svg>
                                        </span>
                                        <span class="star" data-value="2">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" fill="#D1D5DB" />
                                            </svg>
                                        </span>
                                        <span class="star" data-value="3">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" fill="#D1D5DB" />
                                            </svg>
                                        </span>
                                        <span class="star" data-value="4">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" fill="#D1D5DB" />
                                            </svg>
                                        </span>
                                        <span class="star" data-value="5">
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.2703 3.79429L14.9713 7.19642C15.2033 7.67001 15.8218 8.12427 16.3438 8.21126L19.4269 8.72351C21.3986 9.05212 21.8626 10.4826 20.4418 11.8937L18.0448 14.2906C17.6389 14.6966 17.4166 15.4794 17.5422 16.04L18.2285 19.0072C18.7697 21.3558 17.5229 22.2644 15.4449 21.0369L12.555 19.3262C12.0331 19.0169 11.1729 19.0169 10.6413 19.3262L7.75146 21.0369C5.68312 22.2644 4.42665 21.3462 4.9679 19.0072L5.65413 16.04C5.77977 15.4794 5.55747 14.6966 5.15154 14.2906L2.75459 11.8937C1.34348 10.4826 1.79774 9.05212 3.76943 8.72351L6.8526 8.21126C7.36485 8.12427 7.98342 7.67001 8.21539 7.19642L9.91645 3.79429C10.8443 1.94825 12.3521 1.94825 13.2703 3.79429Z" fill="#D1D5DB" />
                                            </svg>
                                        </span>
                                        </div>
                                        <div class="science-card__rating-value rating-value">
                                            ${card.rating}
                                        </div>
                                    </div>
                                </div>
                                <div class="science-card__row-item flex flex--align-center">
                                    <img src="/assets/img/img/icons/eye.svg" alt="eye icon" class="science-card__row-item-icon">
                                    <span class="science-card__row-item-text">${card.views}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="science-card__bottom flex flex--wrap flex--align-center">
                        <a href="${card.link}" data-id="${card.id}" target="_blank" draggable="false" class="link link--secondary science-card__link science-card__link--full flex flex--justify-center flex--align-center">
                            <span class="link__label">${card.linkTitle}</span>
                            <img class="link__image" src="/assets/img/img/arrow-top-left-dark.svg" alt="/assets/img/img/arrow-top-left-dark.svg">
                        </a>
                        <div class="science-card__buttons flex flex--align-center">
                            <button class="button button--round-tiny shareButton science-card__button science-card__button-share flex flex--justify-center flex--align-center">
                                <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                            </button>
                            <a href="${card.link}" draggable="false" class="link link--round-tiny science-card__button science-card__button-download flex flex--justify-center flex--align-center" download>
                                <img class="link__image" src="/assets/img/img/download-icon-dark.svg" alt="/assets/img/img/download-icon-dark.svg">
                            </a>
                        </div>
                    </div>
            `;

            //     cardElement.innerHTML = `
            //     <div class="article-card flex flex--column">
            //             <div class="article-card__top">
            //                 <div class="article-card__top-content flex flex--column">
            //                     <h3 class="article-card__top-content-title">${card.title}</h3>
            //                     <img class="article-card__top-content-image" src="${card.image}" alt="${card.title}" />
            //                     <span class="article-card__top-content-description">${card.text}</span>
            //                     <p class='article-card__top-content-date flex flex--align-center'>
            //                         <img src='/assets/img/img/calendar-icon-yellow.svg' alt='calendar icon' class='article-card__top-content-date-icon' />
            //                         <span class='article-card__top-content-date-text'>${formattedDate}</span>
            //                     </p>
            //                 </div>
            //             </div>
            //             <div class="article-card__bottom">
            //                 <div class="article-card__bottom__content flex flex--row flex--align-center flex--justify-between">
            //                     <div class="article-card__bottom__content__author flex flex--row flex--align-center">
            //                         <img class="article-card__bottom__content__author-icon" src="/assets/img/img/icons/user-tick-article-card.svg"/>
            //                         <p class="article-card__bottom__content__author-content">${card.author}</p>
            //                     </div>
            //                     <div class="article-card__bottom__content__article-type flex flex--row flex--align-center">
            //                         <img class="article-card__bottom__content__article-type-icon" src="/assets/img/img/icons/sort-article-card.svg" />
            //                         <p class="article-card__bottom__content__article-type-content">${card.category}</p>
            //                     </div>
            //                     <div class="article-card__bottom__content__views flex flex--row flex--align-center">
            //                         <img class="article-card__bottom__content__views-icon" src="/assets/img/img/icons/eye--article-card.svg" />
            //                         <p class="article-card__bottom__content__views-content">${card.views}</p>
            //                     </div>
            //                 </div>
            //             </div>
            //     </div>
            // `;

            grid.appendChild(cardElement);
        });

        addCardListeners();
        initStarRatings();

        const fileButtons = grid.querySelectorAll('.science-card__link--full') as NodeListOf<HTMLAnchorElement>;
        fileButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                const cardId = Number(button.getAttribute('data-id'));

                addPageView(cardId);
            });
        });
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        filters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        filters.classList.remove('disabled');
    };

    if (checkboxFiltersViews.length > 0) {
        checkboxFiltersViews.forEach((checkbox) => {
            checkbox.addEventListener('change', () => {
                if (views.includes(checkbox.value)) {
                    views = views.filter((cat) => cat !== checkbox.value);
                } else {
                    views.push(checkbox.value);
                }
                fetchPublications();
            });
        });
    }

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchPublications();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchPublications();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchPublications();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchPublications();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchPublications();
    });

    let timeout = null;
    listSearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = listSearchInput.value.trim();
            pageNumber = 1;
            fetchPublications();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = latest
        // 2 = A-Z
        // 3 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = list.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchPublications();
        });
    });

    filterButton?.addEventListener('click', () => {
        const filterColumn = list.querySelector('.science-pubs__accordions') as HTMLDivElement;
        if (filterColumn?.classList.contains('hidden')) {
            filterColumn?.classList.remove('hidden');
        } else {
            filterColumn?.classList.add('hidden');
        }
    });

    const initializeList = async () => {
        await fetchAllPublications();
        await fetchPublicationsFilters();
        await fetchPublications();
    };

    // Call the initialize function
    initializeList();

    // Function to update the counts
    const updateNumberCards = async () => {
        // Get all filter divs
        const filterDivs = document.querySelectorAll('.views-card');

        filterDivs.forEach((filterDiv) => {
            // Extract data-min and data-max values
            const minViews = parseInt(filterDiv.getAttribute('data-min'), 10);
            const maxViews = parseInt(filterDiv.getAttribute('data-max'), 10);

            // Count the articles within the view range
            const count = scienceCardsAll.filter(
                (article) => article.views >= minViews && article.views <= maxViews
            ).length;

            // Update the span with the count
            const countSpan = filterDiv.querySelector('.science-pubs__sub-filters-text-number');
            countSpan.textContent = count.toString();
        });
    };

    updateNumberCards();

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
    // const updateNumberCats = () => {
    //     // Get all filter divs
    //     const filterDivs = document.querySelectorAll('.subcat-card');

    //     filterDivs.forEach(filterDiv => {
    //         // Extract data-min and data-max values
    //         const subcardid = filterDiv.getAttribute('data-id');

    //         // Count the articles within the view range
    //         console.log(scienceCards);
    //         const count = scienceCards.filter(article => article.subCategoryID == subcardid).length;

    //         // Update the span with the count
    //         const countSpan = filterDiv.querySelector('.science-pubs__sub-filters-text-number');
    //         countSpan.textContent = count.toString();
    //     });
    // }

    const addCardListeners = () => {
        const listCards: NodeListOf<HTMLDivElement> = list.querySelectorAll('.science-card');

        if (listCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = list.querySelectorAll('.science-card__button');

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });
        }
    };
}
