const convertTime = (dateStr: string, dir: string) => {
  const date = new Date(dateStr);
  const hour = date.getHours();
  const minutes = date.getMinutes();

  // Determine the time period and hour in 12-hour format
  let period: string;
  let displayHour: number;

  if (hour >= 0 && hour < 12) {
    if (dir !== 'rtl') {
      period = 'in the morning';
    } else {
      period = 'صباحاً';
    }
    displayHour = hour === 0 ? 12 : hour;
  } else if (hour >= 12 && hour < 20) {
    if (dir !== 'rtl') {
      period = 'in the afternoon';
    } else {
      period = 'ظهراً';
    }
    displayHour = hour === 12 ? 12 : hour - 12;
  } else {
    if (dir !== 'rtl') {
      period = 'in the evening';
    } else {
      period = 'مساءً';
    }
    displayHour = hour === 12 ? 12 : hour - 12;
  }

  let formattedMinutes = '';
  if (minutes !== 0) {
    formattedMinutes = `:${minutes.toString().padStart(2, '0')}`;
  }

  const formattedTime = `${displayHour}${formattedMinutes} ${period}`;

  if (dir === 'rtl') {
    return `\u202B${formattedTime}\u202C`;
  }

  return formattedTime;
};

export default convertTime;