import { FetchJobCardSettingsResponse, FetchJobsListPayload, FetchJobsListResponse, JobCard } from './jobsList.types';
import formatDateString from '../../../helpers/formatDate';
import { updatePaginationButtons } from '../pagination/pagination';
import copyToClipboard from '../../../helpers/copyToClipboard';
import axios from 'axios';

const jobsList: HTMLDivElement = document.querySelector('.jobs-list');

if (jobsList) {
    const jobsGrid = jobsList.querySelector('.jobs-list__grid') as HTMLDivElement;
    const jobsFilters = jobsList.querySelector('.jobs-list__filters') as HTMLDivElement;
    const jobsListSearchInput = jobsList.querySelector('.jobs-list__search-input') as HTMLInputElement;
    const dropdownFilters: NodeListOf<HTMLDivElement> = jobsList.querySelectorAll('.dropdown-filter');
    const nextPageButton = jobsList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = jobsList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = jobsList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = jobsList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = jobsList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = jobsList.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = jobsList.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let jobCards: JobCard[] = [];
    let keyword: string = '';
    let departments: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    let jobCardSettings: FetchJobCardSettingsResponse = {
        cardButtonText: '',
        availableText: '',
        endText: '',
        noJobsText: '',
    };

    const fetchJobCardSettings = async () => {
        try {
            const response = await axios.get<FetchJobCardSettingsResponse>(
                `/api/jobs/cardsettings?currentCulture=${currentCulture}`
            );

            jobCardSettings = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchJobs = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchJobsListPayload = {
            keyword,
            departments,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchJobsListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/jobs',
                    payload
                );
            } else {
                response = await axios.post<FetchJobsListResponse>('/api/jobs', payload);
            }
            jobCards = response.data.items;
            if (jobCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                jobCards,
                fetchJobs
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        jobsGrid.innerHTML = '';

        if (jobCards.length === 0) {
            jobsGrid.innerHTML = `<div class="flex" style="align-self: center;">${jobCardSettings.noJobsText}</div>`;
        } else {
            jobCards.forEach((card: JobCard) => {
                const cardElement = document.createElement('a');

                cardElement.setAttribute('href', card.link);
                cardElement.className = 'job-card flex flex--column';

                cardElement.innerHTML = `
                <span class="job-card__tag ${card.isAvailable ? '' : 'job-card__tag--red'}">${
                    card.isAvailable ? jobCardSettings.availableText : jobCardSettings.endText
                }</span>
                <div class="job-card__content flex flex--column">
                    <p class='job-card__title'>${card.title}</p>
                    <p class='job-card__text'>${card.description}</p>
                </div>
                <div class="job-card__items flex flex--wrap flex--align-center">
                    <p class='job-card__info flex flex--align-center'>
                        <img src='/assets/img/img/user-octagon-icon.svg' alt='calendar icon' class='job-card__icon' />
                        <span class='job-card__info-text'>
                            ${card.department}
                        </span>
                    </p>
                    <p class='job-card__info flex flex--align-center'>
                        <img src='/assets/img/img/calendar-icon-yellow.svg' alt='calendar icon' class='job-card__icon' />
                        <span class='job-card__info-text'>
                                ${formatDateString(card.publishDate)}
                        </span>
                    </p>
                </div>
                <div class="job-card__buttons flex flex--wrap flex--align-center flex--justify-between">
                    <div class="job-card__link flex flex--align-center flex--justify-center">
                        ${jobCardSettings.cardButtonText}
                        <img src="/assets/img/img/arrow-top-left-dark.svg" alt="arrow icon" class="job-card__link-icon">
                    </div>
                    <button class="button shareButton button--round-tiny job-card__button job-card__button-share flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                    </button>
                </div>
            `;

                jobsGrid.appendChild(cardElement);
            });

            addCardListeners();
        }
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        jobsFilters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        jobsFilters.classList.remove('disabled');
    };

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchJobs();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchJobs();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchJobs();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchJobs();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchJobs();
    });

    let timeout = null;
    jobsListSearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = jobsListSearchInput.value.trim();
            pageNumber = 1;
            fetchJobs();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = A-Z
        // 2 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = jobsList.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchJobs();
        });
    });

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const departmentsOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    departmentsOptions.push(element.textContent);
                                });
                                departments = departmentsOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchJobs();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });

    const initializeEventsList = async () => {
        await fetchJobCardSettings();
        await fetchJobs();
    };

    // Call the initialize function
    initializeEventsList();

    const addCardListeners = () => {
        const listCards: NodeListOf<HTMLAnchorElement> = jobsList.querySelectorAll('.job-card');

        if (listCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.job-card__button');

            listCards.forEach((card) => {
                card.addEventListener('click', (e) => {
                    const target = e.target as HTMLElement;

                    if (target.classList.contains('button') || target.classList.contains('button__image')) {
                        e.preventDefault();
                    }
                });
            });

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });
        }
    };
}
