const policies = document.querySelector('.policies') as HTMLDivElement;

if (policies) {
    const policiesTabs: NodeListOf<HTMLButtonElement> = policies.querySelectorAll('.policies__tab');
    const policiesSections: NodeListOf<HTMLDivElement> = policies.querySelectorAll('.policies__section');

    let activeTab: number = 0;

    const resetTabs = () => {
        policiesTabs.forEach((tab) => tab.classList.remove('policies__tab--active'));
        policiesSections.forEach((item) => item.classList.add('hidden'));
    };

    policiesTabs.forEach((tab, index: number) => {
        tab.addEventListener('click', () => {
            activeTab = index;
            resetTabs();
            policiesTabs[activeTab].classList.add('policies__tab--active');
            policiesSections[activeTab].classList.remove('hidden');
        });
    });
}
