const sortFilter: HTMLDivElement = document.querySelector(".sort-filter");

if (sortFilter) {
  const dateRangeFilterTrigger: HTMLDivElement = sortFilter.querySelector(".sort-filter__icon-container");
  const dateRangeInner: HTMLDivElement = sortFilter.querySelector(".sort-filter__inner");
  const dateRangeIcon: HTMLDivElement = sortFilter.querySelector(".sort-filter__icon-container");
  const radioInputLabels: NodeListOf<HTMLLabelElement> = sortFilter.querySelectorAll(".sort-filter__input-container");
  const radioInputs: NodeListOf<HTMLInputElement> = sortFilter.querySelectorAll(".sort-filter__input");

  const handleTrigger = () => {
    dateRangeInner.classList.toggle("sort-filter__inner--active");
    dateRangeIcon.classList.toggle("sort-filter__icon-container--active");
  }

  const closeSortFilter = () => {
    dateRangeInner.classList.remove("sort-filter__inner--active")
    dateRangeIcon.classList.remove("sort-filter__icon-container--active")
  }

  radioInputLabels.forEach((label, index) => {
    label.addEventListener('click', (e) => {
      radioInputs.forEach(input => input.setAttribute("data-checked", "false")); // Uncheck all radios
      radioInputs[index].setAttribute("data-checked", "true") // Check the clicked one
    });

    label.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        radioInputs.forEach(input => input.setAttribute("data-checked", "false")); // Uncheck all radios
        radioInputs[index].setAttribute("data-checked", "true") // Check the clicked one
      }
    });
  });

  dateRangeFilterTrigger.addEventListener("click", () => {
    handleTrigger()
  })

  dateRangeFilterTrigger.addEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleTrigger()
    }
  });

  document.addEventListener('click', (event: MouseEvent) => {
    if (event.target instanceof Element) {
      if (sortFilter && !sortFilter.contains(event.target)) {
        closeSortFilter()
      }
    }
  })

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeSortFilter()
    }
  });
}