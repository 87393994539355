const currentCulture: string = document.documentElement.getAttribute('culture');

export default function copyLinkToClipboard(button: HTMLButtonElement): void {
    try {
        navigator.clipboard.writeText(window.location.href);
        showToast(button);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}

const showToast = (button: HTMLButtonElement) => {
    const toast = document.createElement('div');
    toast.className = 'toast';
    toast.textContent = currentCulture === 'ar' ? 'تم نسخ الرابط!' : 'Link copied to clipboard!';

    document.body.appendChild(toast);

    // Get button's position
    const buttonRect = button.getBoundingClientRect();
    const toastWidth = 150; // Same as the min-width in the CSS
    const toastHeight = 40; // Approximate height of the toast including padding

    // Calculate the position
    const top = window.scrollY + buttonRect.top - toastHeight - 10;
    const left = window.scrollX + buttonRect.left + (buttonRect.width / 2) - (toastWidth / 2);

    // Set the position
    toast.style.top = `${top}px`;
    toast.style.left = `${left}px`;

    // Trigger the show class to animate the toast
    setTimeout(() => {
        toast.classList.add('show');
    }, 100);

    // Remove the toast after 3 seconds
    setTimeout(() => {
        toast.classList.remove('show');
        setTimeout(() => {
            document.body.removeChild(toast);
        }, 500); // Match this duration to the CSS transition duration for the toast fade out
    }, 3000);
};