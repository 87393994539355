const serviceTimelineThree = document.querySelector(".service-timeline-three") as HTMLElement;

if (serviceTimelineThree) {
  const getFormattedTime = (dateStr: string | null) => {
    if (!dateStr) return;

    const date = new Date(dateStr);
    const hour = String(date.getHours()).padStart(2, '0') as String;
    const minutes = String(date.getMinutes()).padStart(2, '0') as String;

    return `${hour}:${minutes}`;
  }

  const times: NodeListOf<HTMLElement> = serviceTimelineThree.querySelectorAll(".service-timeline-three__table-content-time");

  times.forEach(time => {
    time.textContent = getFormattedTime(time.textContent);
  });
}
