import { FetchIdentityListPayload, FetchIdentityListResponse, IdentityCard } from './identityList.types';
import { updatePaginationButtons } from '../pagination/pagination';
import copyToClipboard from '../../../helpers/copyToClipboard';
import axios from 'axios';

const identityList: HTMLDivElement = document.querySelector('.identity-list');

if (identityList) {
    const identityGrid = identityList.querySelector('.identity-list__grid') as HTMLDivElement;
    const identityFilters = identityList.querySelector('.identity-list__search') as HTMLDivElement;
    const identityListSearchInput = identityList.querySelector('.identity-list__search-input') as HTMLInputElement;
    const dropdownFilters: NodeListOf<HTMLDivElement> = identityList.querySelectorAll('.dropdown-filter');
    const nextPageButton = identityList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = identityList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = identityList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = identityList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = identityList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = identityList.querySelector('.pagination') as HTMLDivElement;
    const radios: NodeListOf<HTMLInputElement> = identityList.querySelectorAll('.sort-filter__input');
    const currentCulture: string = document.documentElement.getAttribute('culture');

    let identityCards: IdentityCard[] = [];
    let keyword: string = '';
    let programs: string[] = [];
    let fileTypes: string[] = [];
    let sortBy: number = 1;
    let pageNumber: number = 1;
    let pageSize: number = +paginationDropdown?.value;
    let totalPages: number = 0;
    let totalCount: number = 0;
    let hasNextPage: boolean = false;
    let hasPreviousPage: boolean = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchIdentity = async (page = null) => {
        clickedNumber = page;
        pagination?.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchIdentityListPayload = {
            keyword,
            programs,
            fileTypes,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchIdentityListResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/identity',
                    payload
                );
            } else {
                response = await axios.post<FetchIdentityListResponse>('/api/identity', payload);
            }
            identityCards = response.data.items;
            if (identityCards.length > 0) {
                pagination.classList.remove('hidden');
            }
            totalPages = response.data.totalPages;
            totalCount = response.data.totalCount;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                identityCards,
                fetchIdentity
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        identityGrid.innerHTML = '';

        identityCards.forEach((card: IdentityCard) => {
            const cardElement = document.createElement('a');

            cardElement.setAttribute('href', card.link);
            cardElement.setAttribute('download', '');
            cardElement.className = 'fade-in';

            cardElement.innerHTML = `
            <div class='identity-list__card'>
                <div class='identity-list__card-top flex flex--align-center'>
                    <img src='/assets/img/fractal/filetype--${card.fileType}.svg' alt='icon' class='identity-list__card-image' />
                    <div class='identity-list__card-title'>${card.documentName}</div>
                </div>
                <div class='flex flex--justify-between flex--align-center'>
                    <div class='identity-list__card-size' dir='ltr'>${card.size}</div>
                    <div>
                        <div class='identity-list__card-buttons flex flex--align-center'>
                            <button class="button button--round-small identity-list__button identity-list__button-download flex flex--justify-center flex--align-center">
                                <img src="/assets/img/img/download-icon.svg" alt="'download icon" class="button__image">
                            </button>
                            <button class="button button--round-small shareButton identity-list__button identity-list__button-share flex flex--justify-center flex--align-center">
                                <img src="/assets/img/img/heart-icon.svg" alt="share icon" class="button__image">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            `;

            identityGrid.appendChild(cardElement);
        });

        addCardListeners();
    };

    const disableInputs = () => {
        pagination?.classList.add('disabled');
        identityFilters?.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination?.classList.remove('disabled');
        identityFilters.classList.remove('disabled');
    };

    paginationDropdown?.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchIdentity();
    });

    nextPageButton?.addEventListener('click', () => {
        pageNumber++;

        fetchIdentity();
    });

    prevPageButton?.addEventListener('click', () => {
        pageNumber--;

        fetchIdentity();
    });

    paginationFirst?.addEventListener('click', () => {
        pageNumber = 1;
        fetchIdentity();
    });

    paginationLast?.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchIdentity();
    });

    let timeout = null;
    identityListSearchInput?.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = identityListSearchInput.value.trim();
            pageNumber = 1;
            fetchIdentity();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = A-Z
        // 2 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = identityList.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchIdentity();
        });
    });

    // Observe changes in dropdown filters
    dropdownFilters.forEach((dropdown, index) => {
        const optionsContainer = dropdown.querySelector('.dropdown-filter__button-container') as HTMLDivElement | null;

        if (optionsContainer) {
            const config: MutationObserverInit = { childList: true };

            const callback: MutationCallback = (mutationsList: MutationRecord[], observer: MutationObserver) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        switch (index) {
                            case 0:
                                const fileTypesOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    fileTypesOptions.push(element.textContent);
                                });
                                fileTypes = fileTypesOptions;
                                break;
                            case 1:
                                const programsOptions = [];
                                Array.from(optionsContainer.children).forEach((element) => {
                                    programsOptions.push(element.textContent);
                                });
                                programs = programsOptions;
                                break;
                            default:
                                break;
                        }

                        pageNumber = 1;
                        fetchIdentity();
                    }
                }
            };

            const observer: MutationObserver = new MutationObserver(callback);

            observer.observe(optionsContainer, config);
        }
    });

    fetchIdentity();

    const addCardListeners = () => {
        const serviceListCards: NodeListOf<HTMLAnchorElement> = identityGrid.querySelectorAll('.identity-list__card');

        if (serviceListCards.length > 0) {
            const cardButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.identity-list__button');

            cardButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    if (button.classList.contains('shareButton')) {
                        copyToClipboard(button);
                    }
                });
            });
        }
    };

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}
