google.charts.load('current', { packages: ['corechart'] });
const chartDiv = document.getElementById('bar_chart');
if (chartDiv) {
    const chartwidth = chartDiv.parentElement.clientWidth;
    google.charts.setOnLoadCallback(() => {
        const chartDiv = document.getElementById('bar_chart');
        drawChart(chartDiv);
    });

    const changeChartStyles = () => {
        const chartColumns = chartDiv.getElementsByTagName('rect');
        const chartText = chartDiv.getElementsByTagName('text');

        // add border radius
        Array.prototype.forEach.call(chartColumns, function (rect) {
            if (parseFloat(rect.getAttribute('x')) > 0) {
                rect.setAttribute('rx', 5);
                rect.setAttribute('ry', 5);
            }
        });

        // add font family
        Array.prototype.forEach.call(chartText, function (text) {
            text.setAttribute('font-family', 'Alexandria,sans-serif');
            text.setAttribute('font-size', '10px');
        });
    };

    function drawChart(element: HTMLElement) {
        const chartData = element.getAttribute('data-chartdata');
        if (!chartData) {
            console.error('No data found in data-chartdata attribute');
            return;
        }

        const dataArray = JSON.parse(chartData);
        const data = google.visualization.arrayToDataTable(dataArray);

        const options = {
            width: chartwidth,
            height: 300,
            theme: 'material',
            legend: { position: 'bottom' },
            chartArea: {
                backgroundColor: '#F4F4F5',
            },
            vAxis: {
                gridlines: { color: 'transparent' },
                textPosition: 'out',
                baselineColor: 'grey',
                format: 'short',
            },
            hAxis: {
                gridlines: { color: 'transparent' },
                textPosition: 'out',
                baselineColor: 'transparent',
            },
            bar: { groupWidth: '25%' },
            backgroundColor: '#F4F4F5',
            colors: ['#E2A947', '#2B254B'],
        };

        var chart = new google.visualization.ColumnChart(element);

        var observer = new MutationObserver(changeChartStyles);
        google.visualization.events.addListener(chart, 'ready', function () {
            changeChartStyles();
            observer.observe(chartDiv, {
                childList: true,
                subtree: true,
            });
        });

        chart.draw(data, options);
    }
}
