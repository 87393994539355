import copyToClipboard from '../../../helpers/copyToClipboard';

const newsDetails: HTMLDivElement = document.querySelector('.news-details__wrapper');

if (newsDetails) {
    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }
}
