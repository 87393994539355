import axios from 'axios';
import insertPageView from '../../../helpers/InsertPageView';

const modals: NodeListOf<HTMLDivElement> = document.querySelectorAll('.modal');
const html = document.querySelector('html') as HTMLHtmlElement;

if (modals.length > 0) {
    document.addEventListener('DOMContentLoaded', () => {
        modals?.forEach((modalContainer, index) => {
            const modalDialog = modalContainer.querySelector<HTMLDialogElement>('dialog');
            if (!modalDialog) return;

            const modalId = `modal-${index}`;
            const openButtonId = `open-button-${index}`;

            modalDialog.id = modalId;
            const openButton = modalContainer.querySelector<HTMLImageElement>('.modal__open');
            if (openButton) {
                openButton.id = openButtonId;
            }

            const closeButton = modalDialog.querySelector<HTMLButtonElement>('.modal__close');
            const pageId = parseInt(modalContainer.getAttribute('data-page-id'));

            document.getElementById(openButtonId)?.addEventListener('click', (event) => {
                modalDialog.showModal();
                if (pageId > 0) {
                    insertPageView(pageId);
                }
                html.style.overflow = 'hidden';
            });

            closeButton?.addEventListener('click', (event) => {
                modalDialog.close();
                html.style.overflow = '';
            });

            modalDialog.addEventListener('click', (event) => {
                if (event.target === modalDialog) {
                    modalDialog.close();
                    html.style.overflow = '';
                }
            });

            window.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    html.style.overflow = '';
                }
            });
        });
    });
}
