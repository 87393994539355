const strategicGoals: NodeListOf<HTMLDivElement> = document.querySelectorAll('.strategic-goals');

strategicGoals.forEach(strategicGoal => {
  if (strategicGoal) {
    const tabs: NodeListOf<HTMLButtonElement> = strategicGoal.querySelectorAll('.strategic-goals__tab');
    const grids: NodeListOf<HTMLDivElement> = strategicGoal.querySelectorAll('.strategic-goals__grid');
    const images: NodeListOf<HTMLImageElement> = strategicGoal.querySelectorAll('.strategic-goals__image');

    let activeTab: number = 0;

    const resetAll = () => {
      tabs.forEach((tab) => tab.classList.remove('strategic-goals__tab--active'));
      grids.forEach((grid) => grid.classList.add('hidden'));
      images.forEach((image) => image.classList.add('hidden'));
    };

    tabs.forEach((tab, index: number) => {
      tab.addEventListener('click', () => {
        activeTab = index;
        resetAll()
        tabs[activeTab].classList.add('strategic-goals__tab--active');
        grids[activeTab].classList.remove('hidden');
        images[activeTab].classList.remove('hidden');
      });
    })
  }
})


