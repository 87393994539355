import { updatePaginationButtons } from '../pagination/pagination';
import { SearchCard, FetchSearchResultsPayload, FetchSearchResultsResponse } from './searchResults.types';
import axios from 'axios';
import copyToClipboard from '../../../helpers/copyToClipboard';

const searchList: HTMLDivElement = document.querySelector('.search-results');
const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

if (searchList) {
    const Grid = searchList.querySelector('.search-results__grid') as HTMLDivElement;
    const searchFilters = searchList.querySelector('.search-results__filters') as HTMLDivElement;
    const searchListingSearchInput = searchList.querySelector('.search-results__search-input') as HTMLInputElement;
    const radios: NodeListOf<HTMLInputElement> = searchList.querySelectorAll('.sort-filter__input');
    const checkboxes: NodeListOf<HTMLInputElement> = searchList.querySelectorAll('.search-results__category-input');
    const nextPageButton = searchList.querySelector('.pagination__page-next') as HTMLButtonElement;
    const prevPageButton = searchList.querySelector('.pagination__page-prev') as HTMLButtonElement;
    const paginationDropdown = searchList.querySelector('.pagination__dropdown') as HTMLSelectElement;
    const paginationLast = searchList.querySelector('.pagination__page-last') as HTMLSelectElement;
    const paginationFirst = searchList.querySelector('.pagination__page-first') as HTMLSelectElement;
    const pagination = searchList.querySelector('.pagination') as HTMLDivElement;
    const totalSearchResults = searchList.querySelector('.search-results__number') as HTMLSpanElement;
    const currentCulture: string = document.documentElement.getAttribute('culture');

    const queryParam = getParameterByName('q');
    let searchCards: SearchCard[] = [];
    let keyword = queryParam;
    searchListingSearchInput.value = queryParam;
    const types: string[] = [];
    let sortBy = 1;
    let pageNumber = 1;
    let pageSize = +paginationDropdown.value;
    let totalPages = 0;
    let totalCount: number = 0;
    let hasNextPage = false;
    let hasPreviousPage = false;
    let buttonsToShow: number = 3;
    let isLoading: boolean = false;
    let clickedNumber = null;

    const fetchSearchResults = async (page = null) => {
        clickedNumber = page;
        pagination.classList.add('hidden');

        if (clickedNumber) {
            pageNumber = page;
        }

        const payload: FetchSearchResultsPayload = {
            keyword,
            types,
            sortBy,
            pageNumber,
            pageSize,
            currentCulture,
        };

        isLoading = true;

        disableInputs();

        try {
            let response;
            if (
                (window.location.hostname === 'localhost' && window.location.port === '2000') ||
                window.location.hostname === 'mawhiba-fractal.netlify.app'
            ) {
                response = await axios.post<FetchSearchResultsResponse>(
                    'https://app-prd-umbracoportal-uaenorth-mawhiba-development.azurewebsites.net/api/search',
                    payload,
                );
            } else {
                response = await axios.post<FetchSearchResultsResponse>(`/api/search`, payload);
            }

            searchCards = response.data.items;

            if (searchCards.length > 0) {
                pagination.classList.remove('hidden');
            }

            totalCount = response.data.totalCount;
            totalPages = response.data.totalPages;
            hasNextPage = response.data.hasNextPage;
            hasPreviousPage = response.data.hasPreviousPage;

            totalSearchResults.innerHTML = totalCount.toString();
        } catch (error) {
            console.error(error);
            enableInputs();
        } finally {
            updatePaginationButtons(
                totalPages,
                hasPreviousPage,
                hasNextPage,
                pageNumber,
                pageSize,
                totalCount,
                buttonsToShow,
                searchCards,
                fetchSearchResults,
            );
            isLoading = false;
            updateCards();
            enableInputs();
        }
    };

    const updateCards = () => {
        Grid.innerHTML = '';

        searchCards.forEach((card: SearchCard) => {
            const cardElement = document.createElement('a');
            cardElement.setAttribute('href', card.link);
            cardElement.className = 'search-results__card';

            cardElement.innerHTML = `
            ${card.tag !== null ? ` <p class='search-results__card-tag'>${card.tag}</p>` : ''}
            <h3 class='search-results__card-title'>${card.title}</h3>
            <div class='search-results__card-image-container'>
                <img src='${card.image}' alt='image' class='search-results__card-image' />
            </div>
            <p class='search-results__card-text'>
                ${card.description !== null ? card.description : ''}
            </p>
            <div class='search-results__card-bottom flex flex--justify-between flex--align-center'>
                <div class="search-results__link flex flex--align-center flex--justify-center">
                    ${card.linkTitle}
                    <img src="/assets/img/img/arrow-top-left-dark.svg" alt="arrow icon"
                        class="search-results__link-icon">
                </div>
                <div class='search-results__card-buttons flex flex--align-center'>
                    <button
                        class="button button--round-small search-results__button-share search-results__button flex flex--justify-center flex--align-center">
                        <img src="/assets/img/img/share-icon.svg" alt="share icon" class="button__image">
                    </button>
                </div>
            </div>
            `;

            Grid.appendChild(cardElement);
        });
    };

    const disableInputs = () => {
        pagination.classList.add('disabled');
        searchFilters.classList.add('disabled');
    };

    const enableInputs = () => {
        pagination.classList.remove('disabled');
        searchFilters.classList.remove('disabled');
    };

    // Listeners
    paginationDropdown.addEventListener('change', () => {
        pageSize = +paginationDropdown.value;
        pageNumber = 1;
        fetchSearchResults();
    });

    nextPageButton.addEventListener('click', () => {
        pageNumber++;
        fetchSearchResults();
    });

    prevPageButton.addEventListener('click', () => {
        pageNumber--;
        fetchSearchResults();
    });

    paginationFirst.addEventListener('click', () => {
        pageNumber = 1;
        fetchSearchResults();
    });

    paginationLast.addEventListener('click', () => {
        pageNumber = totalPages;
        fetchSearchResults();
    });

    let timeout = null;
    searchListingSearchInput.addEventListener('input', (e) => {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            keyword = searchListingSearchInput.value.trim();
            pageNumber = 1;
            fetchSearchResults();
        }, 1000);
    });

    radios.forEach((radio) => {
        // 1 = Latest
        // 2 = Olders
        // 3 = A-Z
        // 4 = Z-A

        radio.addEventListener('change', () => {
            const checkedRadio = searchList.querySelector('input[name="sort-filter"]:checked') as HTMLInputElement;
            const checkedRadioValue = checkedRadio.value;
            sortBy = +checkedRadioValue;
            fetchSearchResults();
        });
    });

    checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
            if (checkbox.checked) {
                types.push(checkbox.value);
            } else {
                const index = types.indexOf(checkbox.value);
                if (index > -1) {
                    types.splice(index, 1);
                }
            }

            fetchSearchResults();
        });
    });

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');
    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    // if (checkboxes.length > 0) {
    //     types.push(checkboxes[0].value);
    //     fetchSearchResults();
    // }

    fetchSearchResults();
}
