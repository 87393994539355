import saveOrRemoveFavourite from '../../../helpers/favourites';
import { IsFavourited } from '../../../helpers/isFavourite';
import copyToClipboard from '../../../helpers/copyToClipboard';
import addToCalendar from '../../../helpers/addToCalendar';

const eventsDetails: HTMLDivElement = document.querySelector('.event-details');

if (eventsDetails) {
    const favButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.favouriteButton');

    if (favButtons && favButtons.length > 0) {
        favButtons.forEach((button) => {
            const dataIdAttr: string | null = button.getAttribute('data-id');
            const type: string | null = button.getAttribute('data-type');
            const favouritedIds: number[] = JSON.parse(button.getAttribute('data-ids'));
            if (dataIdAttr !== null && favouritedIds != null) {
                const dataId: number = parseInt(dataIdAttr);

                if (IsFavourited(dataId, favouritedIds)) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }

                button.addEventListener('click', (e) => {
                    console.log('small button clicked - events details');
                    saveOrRemoveFavourite(type, dataId, button);
                });
            }
        });
    }

    const shareButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.shareButton');

    if (shareButtons && shareButtons.length > 0) {
        shareButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                copyToClipboard(button);
            });
        });
    }

    const calButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.calButton');

    if (calButtons && calButtons.length > 0) {
        calButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                addToCalendar(button);
            });
        });
    }
}
